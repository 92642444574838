import React, { Component } from 'react'
import {connect} from "react-redux"
import {Helmet} from 'react-helmet'
import {addContact} from '../actions/contacts'
import isEmpty from '../validation/isEmpty'
import isEmail from '../validation/isEmpty'
import TextAreaGroup from './common/textAreaGroup'
import TextFieldGroup from './common/textFieldGroup'

class ContactUs extends Component {
    state = {
        email: '',
        name: '',
        text: '',
        errors: {}
    }

    onChange = e => this.setState({[e.target.name]: e.target.value})

    validateForm = () => {
        if(!isEmpty(this.state.email)) {
            if(!isEmail(this.state.email)) {
                if(!isEmpty(this.state.name)) {
                    if(!isEmpty(this.state.text)) {
                        return true
                    } else {
                        this.setState({errors: {
                            text: "Моля, добавете текст на съобщението"
                        }})
                    }
                } else {
                    this.setState({errors: {
                        name: "Моля, добавете име"
                    }})
                }
            } else {
                this.setState({errors: {
                    email: "Моля, добавете валиден е-мейл"
                }}) 
            }
        } else {
            this.setState({errors: {
                email: "Моля, добавете е-мейл"
            }})
        }
    }

    onSubmit = (e) => {
        e.preventDefault()
        if(this.validateForm()) {
            const newContact = {
                email: this.state.email,
                name: this.state.name,
                text: this.state.text
            }
            this.props.addContact(newContact, this.props.history)
        } 
    }

  render() {
    return (
      <div className="row mt-4">
      <Helmet>
      <title>БГШефс Контакти</title>
      </Helmet>
        <div className="col-10 m-auto">
        <h3 className="display-4 text-center">Свържете се с нас</h3>
        <hr></hr>
        </div>
        <div className="col-9 m-auto">
            <small className="d-block pb-3">* = задължителни полета</small>
            <form onSubmit={this.onSubmit}>             
            <TextFieldGroup
                name="email"
                placeholder={this.state.email}
                value={this.state.email}
                error={this.state.errors.email}
                info="* е-мейл"
                type="text"
                onChange={this.onChange}                        
                />
            <TextFieldGroup
                name="name"
                placeholder={this.state.name}
                value={this.state.name}
                error={this.state.errors.name}
                info="* име"
                type="text"
                onChange={this.onChange}                        
            />
            <TextAreaGroup
                name="text"
                placeholder={this.state.text}
                value={this.state.text}
                error={this.state.errors.text}
                info="* съобщение"
                onChange={this.onChange}                        
            />     
            <input 
            type="submit" 
            value="Изпрати" 
            className="btn btn-danger btn-block mt-4"
            />                      
        </form>
        </div>
      </div>
    )
  }
}

export default connect(null, {addContact})(ContactUs)
