const initialState = {
    gig: {},
    gigs: [],    
    cuisines: null,
    loading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case "GIG_LOADING":
        return {
            ...state,
            loading: true
        }
        case "ADD_GIG":
        return {
            ...state,
            gigs: [action.payload, ...state.gigs]         
        }  
        case "GET_GIG":
        return {
            ...state,
            gig: action.payload,
            loading: false     
        }        
        case "GET_GIGS":
        return {
            ...state,
            gigs: action.payload,
            loading: false
        }        
        case "DELETE_GIG":
        return {
            ...state,
            gigs: state.gigs.filter(gig => gig._id !== action.payload)
        }
        case 'GET_CUISINES':
        return {
            ...state,
            cuisines: action.payload,
            loading: false
        }
        default:
        return state
    }
}