import React, { Component } from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import StarRatingComponent from 'react-star-rating-component';
import {getSingleClientOrder} from '../actions/orders'
import {createReview} from '../actions/reviews'
import Spinner from './common/Spinner'
import isEmpty from '../validation/isEmpty'
import TextAreaGroup from './common/textAreaGroup'
import TextFieldGroup from './common/textFieldGroup'

class CreateReview extends Component {

    state={
        rating: 0,
        title: '',
        description: '',
        errors: {}
    }

    componentDidMount(){
        if(this.props.match.params.id){
            this.props.getSingleClientOrder(this.props.match.params.id)
        }    
    }

    onStarClick(nextValue, prevValue, name) {
        this.setState({rating: nextValue});
      }

    onChange = e => this.setState({[e.target.name]: e.target.value})

    validateForm = () => {
        if (!isEmpty(this.state.title)) {
            if(!isEmpty(this.state.description)) {
                return true
            } else {
                this.setState({errors: {description: "Моля, добавете коментар"}})  
            }
        } else {
            this.setState({errors: {title: "Моля, добавете заглавие"}})
        }
    }

    onSubmit = (e) => {
        e.preventDefault()  
        if(this.validateForm())  {
            const review = {
                title: this.state.title,
                description: this.state.description,
                rating: this.state.rating
            }
            const chefId = this.props.order.order.seller._id            
            this.props.createReview(review, chefId, this.props.history)
        } else {
            return this.state.errors
        }        
    }

    render() {
    const {order, loading} = this.props.order

    

    let content
      if(isEmpty(order) && loading) {
        content=(<div className="col-10 m-auto"><Spinner/></div>)
      } else if(!isEmpty(order)) {
        if(order.status === "Доставена") {
            content = (<div className="row">
            <Link className="btn btn-danger ml-2" to='/поръчки'>Назад към поръчки</Link>
            <div className="col-12">
            <h6 className="display-4 text-center mt-4">Вашето ревю за шеф {order.seller.user.name}</h6>
            <hr></hr>
            </div>
                
                <div className="col-10 m-auto">
                <form onSubmit={this.onSubmit}>   
                <p className="lead text-center font-weight-bold">Вашият рейтинг:</p>
                <div className="lead text-center">
                <StarRatingComponent 
                name="rating" 
                starColor="#dc3545"
                editing={true}
                onStarClick={this.onStarClick.bind(this)}
                renderStarIcon={() => <span><i className="fas fa-star"></i></span>}
                starCount={5}
                value={this.state.rating}
                />
                </div>
                <p className="lead text-center mt-2 font-weight-bold">Вашето заглавие:</p>
                <TextFieldGroup
                name="title"
                placeholder={this.state.title}
                value={this.state.title}
                error={this.state.errors.title}
                info="Заглавие"
                type="text"
                onChange={this.onChange}                        
                />
                <p className="lead text-center font-weight-bold">Вашият коментар:</p>
                <TextAreaGroup 
                name="description" 
                placeholder="Кратка информация"
                value={this.state.description} 
                error={this.state.errors.description}
                info="Добавете информация относно представянето на готвача"     
                onChange={this.onChange} 
                />
                <input 
                type="submit" 
                value="Запази" 
                className="btn btn-danger btn-block mt-4"
                /> 
                </form>
                </div>
            </div>)
        } else {
            content = (<div className="row">
                <h6 className="display-4 text-center">За да напишете ревю, поръчката Ви трябва да е със статус доставена.</h6>
            </div>)
        }
      }
    

    return (
      <div className="mt-4">
      <Helmet>
      <title>Напиши Ревю на Шеф БГЩефс</title>
      </Helmet>
        {content}
      </div>
    )
  }
}

const mapStateToProps = state => {
    return {
        order: state.order
    }
}

export default connect(mapStateToProps, 
    {getSingleClientOrder, createReview})(CreateReview)
