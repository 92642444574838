import React, { Component } from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import {getMyProfile, deleteAccount} from '../actions/profile'
import Spinner from './common/Spinner'
import StarRatingComponent from 'react-star-rating-component'
import moment from 'moment'
import isEmpty from '../validation/isEmpty'
import {TinyPagination} from 'react-pagination-custom'
import chefhat from '../images/chefhat.jpg'

 class MyChefProfile extends Component {
  constructor(props){
    super(props);
    this.state = {
      activePage: 1,
      itemsPerPage: 5};
    this.changePage = this.changePage.bind(this);
    this.renderBtnNumber = this.renderBtnNumber.bind(this);
  }
  
  componentDidMount() {
    if(this.props.auth.user) {
      this.props.getMyProfile(this.props.auth.user._id)
    }    
}

  
  
changePage = (id) => {   
  this.setState({activePage: id})      
}

buttonPageClick(id){
  let {activePage} = this.state
  switch (id) {
    case '<<':
      this.changePage(activePage - 1);
      break;
    case '>>':
      this.changePage(activePage + 1);
      break;
    default:
      this.changePage(id);
      break;
  }
}

renderBtnNumber(id){
  let {activePage} = this.state
  return(
    <button
      onClick = {this.buttonPageClick.bind(this, id)}
      key = {id}
      className={`page ${activePage === id? 'selected-page' : ''}`}
      style={{backgroundColor: "white"}}
    >
      {id}
    </button>
  )
}

  render() {
    const {user} = this.props.auth
    const {profile, loading} = this.props.profile
    const indexOfLast = this.state.activePage * this.state.itemsPerPage;
    const indexOfFirst = indexOfLast - this.state.itemsPerPage;    

    let content
    if(!profile || loading) {
      content = <Spinner />
    } else {
      if(!isEmpty(profile)) {
        content = (
          <div className="mt-4">
            <div className="row mt-2">
              <div className="col-md-3 col-6">
                {!isEmpty(profile.imageUrl) 
                ? <Link to={`/шеф-профил/${profile._id}`}><img 
                src={profile.imageUrl} 
                alt="chefimage" 
                className="rounded-circle" 
                title="Публичен профил" 
                style={{width: "100px"}}            
                /></Link> 
                : <Link to={'/промени-шеф-профил'}>
                <img 
                src={chefhat} 
                alt="chefimage" 
                className="rounded-circle" 
                title="Добавете снимка за по-добро представяне" 
                style={{width: "100px"}}            
                />
                </Link>}
              </div>
              <div className="col-6 col-md-7">
                <p className="lead text-muted">Добре дошли {user.name}!</p>
              </div>            
            </div>
            <div className="row mt-5 text-center">
              <div className="col-md-3">
              <Link to="/моите-оферти" className="btn btn-danger mb-2 mr-md-5">
              Моите оферти
              </Link>  
              </div> 
              <div className="col-md-3">
              <Link to={`/моите-поръчки/${profile._id}`} className="btn btn-danger mb-2 mr-md-5">
              Моите поръчки
              </Link>  
              </div>     
              <div className="col-md-3">
              <Link to="/промени-шеф-профил" className="btn btn-danger mr-md-5 mb-2">
              Моя профил
              </Link>
              </div>   
              <div className="col-md-3">
              <Link to="/моя-график" className="btn btn-danger mb-2">
              Моя график
              </Link>
              </div>              
            </div>
            <p className="lead mt-2"></p>
            <hr></hr>
            <div className="row mt-5">
              <div className="col-md-6 mb-5">
                <Link to="/създай-оферта" 
                className="btn btn-outline-danger btn-block">
                Създайте оферта
                </Link>
              </div>
              <div className="col-md-6 mb-5">
                <Link to="/създай-график" 
                className="btn btn-outline-danger btn-block">
                Създайте работен график
                </Link>
              </div>
            </div>
            <hr></hr>
            <div className="offset-md-4 col-md-7">          
                <p className="lead text-muted">Моите ревюта</p>
            </div>
                <hr></hr> 
                {isEmpty(profile.reviews) && (<div className="row mt-2">
                <p className="ml-5 text-muted">Все още няма ревюта.</p></div>)}
            {!isEmpty(profile.reviews) && (<div>
                {profile.reviews.reverse()                  
                  .slice(indexOfFirst, indexOfLast)
                  .map(review => <div key={review._id} className="row m-auto">
                <div className="col-6 col-md-4">
                {!isEmpty(review.owner) && <p className="lead mb-0">
                  {review.owner.name}</p>} 
                {!isEmpty(review.owner) && <img className="img rounded-circle" 
                style={{width: '4rem'}} src={review.owner.image} alt="clientimage"/>} 
                <p className="mt-2 mb-0">Рейтинг:</p>              
                  <StarRatingComponent 
                    name="rating" 
                    starColor="#dc3545"
                    editing={false}
                    renderStarIcon={() => <span><i className="fas fa-star"></i></span>}
                    starCount={5}
                    value={review.rating}/>
                              
                </div>
                <div className="col-6 col-md-8">
                  <h6 className="font-weight-bold mt-2 mb-3">{review.title}</h6>
                  <p>{review.description}</p>
                  <p className="mt-0">Дата:
                    <small className="font-weight-bold">{'  ' + moment(review.created).format("D/M/YYYY, h:mm")}</small>
                  </p>
                </div>
                <div className="col-12">
                <hr></hr>
                </div>
              </div>)}              
            </div>)}                   
                  {!isEmpty(profile.reviews) && <div className="pagination justify-content-center mt-2">
                  <TinyPagination
                  total = {profile.reviews.length}
                  selectedPageId = {this.state.activePage}
                  itemPerPage = {this.state.itemsPerPage}
                  renderBtnNumber = {this.renderBtnNumber}
                  maxBtnNumbers = {5}
                  preKey = '<<'
                  nextKey = '>>'        
                  />
                  </div>}      
            <div className="row mb-5 mt-5">
              <button 
              onClick={() => this.props.deleteAccount(profile._id, this.props.history)}
              className="btn btn-danger offset-4"
              >Изтрий моя профил
              </button>
            </div>            
          </div>
        )
        } else {          
          //User is logged in but has no profile
          content = (
            <div className="container">
              <p className="lead text-muted">Добре дошли {user.name}!</p>
              <p>Вие все още не сте създали Вашият профил на професионален готвач.</p>
              <Link to="/стани-шеф" className="btn btn-lg btn-danger">
              Създайте профил
              </Link>
            </div>
          ) 
                   
      }     
  }   
    return (
      <div>
      <Helmet>
      <title>Моя Шеф Профил БГШефс</title>
      </Helmet>
        {content}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    profile: state.profile,
    auth: state.auth,
    errors: state.errors
  }  
}

export default connect(mapStateToProps, {getMyProfile, deleteAccount})(MyChefProfile)
