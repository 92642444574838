import axios from 'axios'
import { setErrors } from './auth'
import {addReview} from './profile'

export const createReview = (review, chefId, history) => async dispatch => {
    const fullReview = {...review, chef: chefId}      
    await axios.post('/api/reviews/', fullReview)
    .then(res => {        
        const savedReview = {
            chefId,
            reviewId: res.data._id
        }
        dispatch(addReview(savedReview, history))        
    })
    .catch(err => dispatch(setErrors(err.message)))
}

export const getReviews = (id, page) => async dispatch => {
    await axios.get(`/api/reviews/?owner=${id}&page=${page}`)
    .then(res => dispatch({type: "GET_REVIEWS", payload: res.data}))
    .catch(err => dispatch(setErrors(err.message)))
}

// updating admin rating
export const updateAdminRating = (ratingInfo, id) => async dispatch => {    
    await axios.post('/api/profiles/rating', ratingInfo)
    .then(res => dispatch(getReviews(id, 0)))
    .catch(err => dispatch(setErrors(err.message)))
}

export const deleteReview = (id, chef) => async dispatch => {
    await axios.delete(`/api/reviews/${id}`)
    .then(res => {
    const ratings = res.data.reviews.map(review => review.rating)
    const avgNumber = ratings.reduce((previous, current) => current += previous) / ratings.length
    const ratingInfo = {
        avgNumber: avgNumber, chefId: chef
    }
    dispatch(updateAdminRating(ratingInfo, id)) 
    })
    .catch(err => dispatch(setErrors(err.message)))
}