import axios from 'axios'
import {setErrors, updateUser, logoutUser} from './auth'


//Profile Loading
export const setProfileLoading = () => ({
    type: "PROFILE_LOADING"
})

//fetching all profiles with search
export const getProfiles = (word, page) => async dispatch => {       
    await axios.get(`/api/profiles/?city=${word}&page=${page}`)
    .then(res => {        
        dispatch({type: 'GET_PROFILES', payload: res.data})        
    })        
    .catch(err => console.log(err))
}

//set Search Item
export const searchItem = (word) => {
        return (dispatch) => {  
        dispatch({type: "SET_SEARCH_ITEM", payload: word})      
        // dispatch(getProfiles(encodeURIComponent(word), 0))        
    }    
}

//get single chef's profile per profile._id
export const fetchChefProfile = (id) => async dispatch => {
    dispatch(setProfileLoading())
    await axios.get(`/api/profiles/chef/${id}`)
    .then(res => dispatch({
        type: "GET_PROFILE",
        payload: res.data
    }))    
    .catch(err => dispatch(setErrors(err.message)))
}

//save the data to profile and dispatch to redux store
export const addProfile = (profile, history) => async dispatch => {    
    await axios.post('/api/profiles', profile)
    .then(res => dispatch({
        type: "GET_PROFILE",
        payload: res.data
    }))
    .then(() => dispatch(updateUser({isChef: true})))
    .then(history.push('/моят-шеф-профил'))
    .catch(err => dispatch(setErrors(err.message)))     
}

//save the profile image in Azure db and return the url
export const addFile = (file, profile, history) => async dispatch => {  
    const form = new FormData()
    form.append('image', file)  
    await axios.post('/api/images', form)
    .then(res => {        
        const newProfile = {...profile, imageUrl: res.data}
        dispatch(addProfile(newProfile, history)) 
    })
    .catch(err => dispatch(setErrors(err.message)))     
}

//edit my chef profile
export const editProfile = (profile, history) => async dispatch => {
    await axios.post('/api/profiles/edit', profile)
    .then(res => dispatch({
        type: "GET_PROFILE",
        payload: res.data
    }))
    .then(history.push('/моят-шеф-профил'))
    .catch(err => dispatch(setErrors(err.message)))   
}

//edit profile image in Azure db and return the url
//save the profile image in Azure db and return the url
export const editFile = (file, profile, history) => async dispatch => {  
    const form = new FormData()
    form.append('image', file)  
    await axios.post('/api/images', form)
    .then(res => {        
        const newProfile = {...profile, imageUrl: res.data}
        dispatch(editProfile(newProfile, history)) 
    })
    .catch(err => dispatch(setErrors(err.message)))     
}

//fetch my chef profile
export const getMyProfile = (id) => async dispatch => {
    dispatch({type: "PROFILE_LOADING"})
    await axios.get(`/api/profiles/user/${id}`)
    .then(res => dispatch({
        type: "GET_PROFILE",
        payload: res.data
    }))
    .catch(err => dispatch(setErrors(err.message)))
}

//adding schedule to the profile
export const addSchedule = (schedule, history) => async dispatch => {
    await axios.post('/api/profiles/schedule', schedule)
    .then(res => dispatch({type: "GET_PROFILE",
        payload: res.data}))
    .then(() => history.push('/моя-график'))
    .catch(err => dispatch(setErrors(err.message)))
}

// updating the average rating
export const updateRating = (ratingInfo, history) => async dispatch => {    
    await axios.post('/api/profiles/rating', ratingInfo)
    .then(res => history.push('/поръчки'))
    .catch(err => dispatch(setErrors(err.message)))
}

//adding review to the profile
export const addReview = (review, history) => async dispatch => {    
    await axios.post('/api/profiles/reviews', review)
    .then(res => {
        const ratings = res.data.reviews.map(review => review.rating)
        const avgNumber = ratings.reduce((previous, current) => current += previous) / ratings.length
        const ratingInfo = {
            avgNumber: avgNumber, chefId: review.chefId
        }        
        dispatch(updateRating(ratingInfo, history))        
    })   
    .catch(err => dispatch(setErrors(err.message)))
}

//deleting schedule to the profile
export const deleteSchedule = (id) => async dispatch => {
    await axios.delete(`/api/profiles/schedule/${id}`)
    .then(res => dispatch({type: "GET_PROFILE",
        payload: res.data}))    
    .catch(err => dispatch(setErrors(err.message)))
}

//Clear the profile
export const clearProfile = () => ({
    type: "CLEAR_CURRENT_PROFILE"
})

//Delete account and profile
export const deleteAccount = (id, history) => {
    return(dispatch) => {
        if(window.confirm("Сигурен ли сте, че искате да изтриете профила си?")) {
            axios.delete(`/api/profiles/${id}`)
            .then(res => {
                history.push('/')    
                dispatch(logoutUser())          
            })                        
            .catch(err => {
                dispatch(setErrors(err.message))
            })
        }
    }
}

//Delete the profile by admin with the user._id
export const deleteChefProfile = (id, word, page) => {
    return (dispatch) => {
        axios.delete(`/api/profiles/admin/${id}`)
        .then(res => dispatch(getProfiles(word, page)))
        .catch(err => {
            dispatch(setErrors(err.message))
        })
    }
}


//fetching cities
export const getCities = () => {
    return (dispatch) => {
        axios.get('/api/cities')
        .then(res => dispatch({
            type: 'GET_CITIES',
            payload: res.data
        }))
        .catch(err => dispatch({
            type: 'GET_CITIES',
            payload: null
        }))
    }    
}

//fetching categories
export const getCategories = () => {
    return (dispatch) => {
        axios.get('/api/categories')
        .then(res => dispatch({
            type: 'GET_CATEGORIES',
            payload: res.data
        }))
        .catch(err => dispatch({
            type: 'GET_CATEGORIES',
            payload: null
        }))
    }    
}

//fetching cuisines
export const getCuisines = () => {
    return (dispatch) => {
        axios.get('/api/cuisines')
        .then(res => dispatch({
            type: 'GET_CUISINES',
            payload: res.data
        }))
        .catch(err => dispatch({
            type: 'GET_CUISINES',
            payload: null
        }))
    }    
}