import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import StarRatingComponent from 'react-star-rating-component'
import numeral from 'numeral'
import {addFavorite, removeFavorite, getFavorites} from '../actions/favorites'
import isEmpty from '../validation/isEmpty'
import chefhat from '../images/chefhat.jpg'

 class ChefOverview extends Component { 
    
    componentDidMount() {
        this.props.getFavorites()
     }
    
    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }


    reducer = (accumulator, currentValue) => accumulator + currentValue;
      
    render () {
        const {profile} = this.props 
        const {favorites} = this.props.favorites        
       
        let favoriteChef = null
        if(!isEmpty(favorites)) {
            favoriteChef =  favorites.filter(favorite => favorite._id === profile._id)
        }
        
        let avgRating = 0 
    
        if(profile && profile.reviews.length > 0) {
            const ratingArray = profile.reviews.map(review => review.rating)
            avgRating = ratingArray.reduce(this.reducer) / profile.reviews.length
        }          
        
      return (
          <div id="overview">           
            <div className="row mt-3" >
              <div className="col-4">
            <img src={profile.imageUrl ? profile.imageUrl : chefhat} 
                className="img rounded-circle" 
                style={{width: "200px"}} 
                alt="chefimage"/>
          </div>
          <div className="col-7 ml-5">
                <h6 id="chefName" className="display-4">{profile.user? profile.user.name : null}</h6>
                <StarRatingComponent 
                    name="rating" 
                    starColor="#dc3545"
                    editing={false}
                    renderStarIcon={() => <span><i className="fas fa-star"></i></span>}
                    starCount={5}
                    value={avgRating}                                     
                    />
                <p><span className="text-muted">({profile.reviews ? profile.reviews.length : 0})</span></p>
                {<p>{this.capitalizeFirstLetter(profile.city)}<span className="text-muted">/ {this.capitalizeFirstLetter(profile.region)}</span></p> }               
                <h6 className="font-weight-bold text-danger">
                    {numeral(profile.hourlyRate).format('0.00')} лв/час
                </h6> 
                {profile.phone && <p><i className="fas fa-mobile-alt"></i> {profile.phone}</p>}
                <div className="offset-2 offset-md-3 row">                    
                    <Link to={`/оферти/${profile.user._id}`}className="col-5 col-md-3 mr-2 mr-md-5 btn btn-danger btn-block" title="Поръчай"><i className="fas fa-shopping-cart fa-2x"></i></Link>
                   {isEmpty(favoriteChef) ? <button onClick={() => this.props.addFavorite({chefId: profile._id})} className="col-5 col-md-3 mr-2 btn" title="Любими">
                    <i className="fas fa-heart fa-2x"></i>
                    </button> 
                    : <button onClick={() => this.props.removeFavorite(profile._id)} className="col-5 col-md-3 mr-2 btn btn-danger" title="Любими">
                    <i className="fas fa-heart fa-2x"></i>
                    </button> }                                    
                </div>
            </div>
          </div>
          <hr></hr>
            <div className="row">        
                <h6 className="ml-5 lead">Категория: {profile.categories ? profile.categories.join('/ ') : null}</h6>
                
            </div> 
            <div className="row mt-2">
            <h6 className="ml-5 lead">Кратко представяне: </h6>            
            </div>
            <div className="row">
                <p className="lead ml-5 mr-5 text-muted">{profile.bio}</p>
            </div>                     
        </div>
      )
    }
    
}

const mapStateToProps = state => {
    return {
        favorites: state.favorites
    }
}

export default connect(mapStateToProps, 
    {getFavorites, addFavorite, removeFavorite})(ChefOverview)
