import React, { Component } from 'react'
import { connect } from 'react-redux'
import {fetchOffers} from '../actions/gigs'
import {Helmet} from 'react-helmet'
import isEmpty from '../validation/isEmpty'
import Spinner from './common/Spinner'
import OffersGroup from './common/offersGroup'
import MakeOrder from './common/MakeOrder'
import Pagination from './common/pagination'
import {addFood, removeFood} from '../actions/orders'

class OffersPage extends Component {

    state={
        activePage: 1,
        itemsPerPage: 5              
    }
       
    componentDidMount() {
        if(this.props.match.params.id) {            
            this.props.fetchOffers(this.props.match.params.id)                 
        }        
    }   

    handlePageChange = (e) => {             
        this.setState({activePage: Number(e.target.id)});
        }
    
    addGig=(gig) => { 
    const newGig =  {
            gigId: gig._id,
            gigTitle: gig.title,
            gigPrice: gig.price            
        } 
    this.props.addFood(newGig)
    }

    removeGig=(title) => {
        this.props.removeFood(title)
    }

    render() {      
      const {gigs, loading} = this.props.gigs
      
      const indexOfLastGig = this.state.activePage * this.state.itemsPerPage;
        const indexOfFirstGig = indexOfLastGig - this.state.itemsPerPage;
        const currentGigs = gigs.slice(indexOfFirstGig, indexOfLastGig);             

      let content
      if(isEmpty(gigs) && loading) {
        content=<Spinner/>
      } else if(!isEmpty(gigs)) {          
          content = (<div className="row">
            <div className="col-md-7 mt-5">
            <hr></hr>
              {currentGigs.map((gig, index) => <OffersGroup 
                gig={gig} 
                key={gig._id} 
                index={index} 
                addGig={(gig) => this.addGig(gig)}
                removeGig={(gig) => this.removeGig(gig)}/>)}            
            
            <Pagination 
                onClick={this.handlePageChange} 
                itemsPerPage={this.state.itemsPerPage} 
                itemsNumber={gigs.length}
            />
            </div>
            <div className="col-md-4 mt-3 ml-3">
            <h6 className="lead ml-3 mt-2">Вашите поръчки</h6>
            <hr></hr>
            
                <MakeOrder 
                foods={this.state.foods}  
                history={this.props.history}           
                id={gigs[0].owner._id}/>
            </div>
        </div>)                               
      } else if(isEmpty(gigs) && !loading) {
          content=(<h6 className="text-danger text-center lead">Този Шеф все още не е публикувал свои оферти.</h6>)
      }

    return (
      <div className="mt-4">    
      <Helmet>
      <title>Офертите на Шефа БГШефс</title>
      </Helmet>  
      {!isEmpty(gigs) &&<h6 className="text-center display-4 mt-3" style={{fontWeight: "bold"}}>
      Офертите на Шеф {gigs[0].owner.name}</h6>}           
          {content}         
      </div>
    )
  }
}

const mapStateToProps = state => {
    return {        
        gigs: state.gigs        
    }
}

export default connect(mapStateToProps, 
    {fetchOffers, addFood, removeFood})(OffersPage)
