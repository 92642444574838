import React from 'react'
import {Link} from 'react-router-dom'
import Numeral from 'numeral'

export default function ProfileRow({profiles, onDelete, word, page}) {
  return (
    profiles.map(profile => (<div className="row mr-2" key={profile._id} style={{border: "1px solid black"}}>
    <div className="col-2 d-none d-xl-block">    
    <span>{profile._id}</span>   
    </div>   
    <div className="col-1 ml-0 ml-md-3 text-center">
    <span>{Numeral(profile.avgRating).format('0.0')}</span>
    </div>
    <div className="col-3 col-lg-1 text-center">
    <Link to={`/шеф-профил/${profile._id}`} style={{color: 'black'}}>
    <span>{profile.user.name}</span></Link>
    </div>
    <div className="col-2 d-none d-xl-block mr-md-2">
    <span>{profile.user._id}</span>
    </div>
    <div className="col-4 col-lg-1 ml-1 mr-1 text-center">
    <span>{profile.user.email}</span>
    </div>
    <div className="col-1 d-none d-lg-block text-center">
    <span>{profile.offers.length}</span>
    </div>
    <div className="col-1 d-none d-lg-block text-center">
    <span>{profile.reviews.length}</span>
    </div>
    <div className="col-1 d-none d-lg-block text-center">
    <span>{profile.schedule.length}</span>
    </div>
    <div className="col-lg-1 col-3 ml-2 ml-md-0">
    <button className="btn btn-danger" 
      onClick={() => onDelete(profile.user._id, word, page)}
      >X</button>
    </div>
    </div>
  ))
  )
}
