import React, { Component } from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import {getMyGigs, deleteGig} from '../actions/gigs'
import Spinner from './common/Spinner'
import GigsGroup from './common/gigsGroup'
import isEmpty from '../validation/isEmpty'
import Pagination from './common/pagination'

class MyGigs extends Component {
    state = {
        activePage: 1,
        itemsPerPage: 5       
    }  

    componentDidMount() {
        this.props.getMyGigs()
    }

    handlePageChange = (e) => {             
        this.setState({activePage: Number(e.target.id)});
        }

  render() {
    const {gigs, loading} = this.props.gigs  
    const indexOfLastGig = this.state.activePage * this.state.itemsPerPage;
    const indexOfFirstGig = indexOfLastGig - this.state.itemsPerPage;
    const currentGigs = gigs.slice(indexOfFirstGig, indexOfLastGig);

    return (
      <div className="mt-4">
      <Helmet>
      <title>Моите Оферти БГШефс</title>
      </Helmet>
      {loading && <Spinner/>} 
      {!loading &&<Link to="/моят-шеф-профил" className="btn btn-danger">Назад към профил</Link>}
        <div className="row text-center">
                                  
            <div className="col mb-2">
            
                {!loading && (<h4 className="display-4">Моите оферти</h4>)}
                {isEmpty(gigs) && !loading && (<div><p>Вие все още нямате създадени оферти.</p>
                    <Link to="/добави-оферта" 
                      className="btn btn-outline-danger btn-block">
                      Създайте оферта
                    </Link></div>)} 
            </div>
        </div>
                {!isEmpty(gigs) && (<div>                    
                <div className="row">
                    <div className="offset-7 col-2 d-none d-md-block">
                        <p>
                            <small className="text-muted">Количество</small>
                        </p>
                    </div>
                    <div className="col-2 d-none d-md-block">
                        <p>
                            <small className="text-muted">Цена</small>
                        </p>
                    </div> 
                    <div className="col-1 d-none d-md-block">
                        <p>
                            <small className="text-muted">Изтрий</small>
                        </p>
                    </div>                
                </div>                       
                    <hr className="mt-0"></hr>
                <div>
                {gigs.length > 0 && currentGigs.map((gig, index) => <GigsGroup key={gig._id} index={index} gig={gig} onDeleteClick={() => this.props.deleteGig(gig._id)}/>)}                
                
                </div>
                {!isEmpty(gigs) && <Pagination 
                    onClick={this.handlePageChange} 
                    itemsPerPage={this.state.itemsPerPage}
                    itemsNumber={gigs.length}/>}
                {gigs.length > 0 && (<div className="offset-6 offset-md-8 col-2 mr-2">
                <Link to="създай-оферта" className="btn btn-danger">Създай Оферта</Link>
                </div>)}
                </div>)}            
                
      </div>
    )
    }
}


const mapStateToProps = state => {
    return {
        gigs: state.gigs                
    }
}

export default connect(mapStateToProps, {getMyGigs, deleteGig})(MyGigs)
