import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import {fetchSinglePost, removePost} from '../actions/posts'
import Spinner from './common/Spinner'
import moment from 'moment'
import isEmpty from '../validation/isEmpty'

class SinglePost extends Component {

    componentDidMount() {
        if(this.props.match.params.id){
        this.props.fetchSinglePost(this.props.match.params.id)
        }
    }

  render() {
      const {post, loading} = this.props.posts
      const {user} = this.props.auth
      
      let isOwner = false
      if(!isEmpty(post) && user._id === post.owner._id) {
          isOwner = true
      }

      let content = null
      if(isEmpty(post) && loading) {
        content = <div className="col-10 m-auto"><Spinner/>
        </div>
    } else if(!isEmpty(post) && !loading) {
        content = <div className="col-10 m-auto">
        <h3 className="display-4 text-center mt-3">{post.title}</h3> 
        <hr></hr>
            <div className="row">
                <div className="col-4">
                {!isEmpty(post.owner.image) && <img src={post.owner.image} 
                className="img rounded-circle" alt="userimage" style={{width: '5rem'}} />}
                </div>
                <div className="col-6">
                <h5 className="dispay-4 font-weight-bold">{post.owner.name}</h5>
                <p>Дата: {moment(post.created).format('D/M H:MM')}</p>
                </div>
                
                <div className="col-12 m-auto">
                <hr></hr>
                <p className="mt-3">{post.text}</p>
                </div>
            </div>
            {isOwner && <div className="mt-5 offset-md-7 col-12 offset-3">
                <button className="btn btn-danger" 
                onClick={() => this.props.removePost(post._id, this.props.history)}> Изтрий
                </button>
                </div>}
        </div>
    } else if(isEmpty(post) && !isEmpty(this.props.errors)) {
        content = <div className="col-12 m-auto">
        <Link to="/обяви" className="btn btn-danger mt-3 mb-3">Назад към обяви</Link>
        <h3 className="display-4 text-center mt-3">Обявата е изтекла</h3>            
        </div>
    }


    return (
      <div className="row mt-4">
      <Helmet>
      <title>БГШефс Обяви</title>
      </Helmet>
        {content}
      </div>
    )
  }
}

const mapStateToProps = state => {
    return {
        posts: state.posts,
        auth: state.auth,
        errors: state.errors
    }
}

export default connect(mapStateToProps, 
    {fetchSinglePost, removePost})(SinglePost)
