import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import numeral from 'numeral'
import moment from 'moment'
import StarRatingComponent from 'react-star-rating-component'
import {addMessage, getOrderMessages} from '../actions/messages'
import {getSingleClientOrder} from '../actions/orders'
import isEmpty from '../validation/isEmpty'
import Spinner from './common/Spinner'
import TextAreaGroup from './common/textAreaGroup'

class SendMessage extends Component {
    state = {
        text: '',
        errors: {}
    } 
 

  componentDidMount() {
      if(this.props.match.params.id) {
        this.props.getSingleClientOrder(this.props.match.params.id)
        this.props.getOrderMessages(this.props.match.params.id)
      }
  }  

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
    }

    onChange = e => this.setState({[e.target.name]: e.target.value}) 

    validateForm = () => {
        if(!isEmpty(this.state.text)) {
            return true
        } else {
            this.setState({errors: {text: "Не може да изпращате празно съобщение."}})
        }
    }

    onSubmit = e => {
        e.preventDefault()
        if(this.validateForm()) {    
            let recipient = null
            if(this.props.auth.user._id !== this.props.order.order.client._id)  {
                recipient = this.props.order.order.client._id
            }  else {
                recipient = this.props.order.order.seller.user._id
            }     
            const message = {
                sender: this.props.auth.user._id,
                text: this.state.text,
                order: this.props.order.order._id,
                recipient
            }
            this.props.addMessage(message)
            this.setState({text: ''})
        } else {
            return this.state.errors
        }
        
    }
    
    reducer = (accumulator, currentValue) => accumulator + currentValue;

  render() {
    const {messages} = this.props.messages
    const {order, loading} = this.props.order
    
    let person = null
    if(!isEmpty(order)) {
        if(order.client._id !== this.props.auth.user._id) {
            person = order.client
        }
    }  
    
    let content
    if(isEmpty(order) && loading) {
        content= (<div className="col-10 m-auto"><Spinner/></div>)
      } else if(!isEmpty(order)) {
        content=(<div className="col-md-4 col-5 border-right border-left">
            <h6 className="mt-3 text-center font-weight-bold">Поръчка</h6>
            <hr></hr>
            {isEmpty(person) && !isEmpty(order.seller.user) &&<div className="row text-center mt-3">
                <div className="col-12 text-center"> 
                <img src={order.seller.imageUrl} className="rounded-circle"
                alt="userimage" style={{width: '3rem'}}/>                
                <h6 className="mt-2 lead text-center font-weight-bold" >
                <Link to={`/шеф-профил/${order.seller._id}`} style={{color: "black"}}>{order.seller.user.name}
                </Link>
                </h6>
                <p className="mt-2 text-center font-weight-bold lead">
                {this.capitalizeFirstLetter(order.seller.city)}
                </p>
                </div>
                
                <div className="col-12 mt-2 text-center">
                <StarRatingComponent 
                    name="rating" 
                    starColor="#dc3545"
                    editing={false}
                    renderStarIcon={() => <span><i className="fas fa-star"></i></span>}
                    starCount={5}
                    value={order.seller.avgRating}                                     
                    />
                <p>({order.seller.reviews.length})</p>
                </div>
                {!isEmpty(order.seller.phone) && <div className="col-12 mt-2 text-center">
                <p>Телефон:</p>
                <p> {order.seller.phone}</p>
                <hr></hr>
                <div className="col-12 mt-2 text-center">
                    <p className="mt-2 text-center font-weight-bold">
                    Стойност: {numeral(order.amount).format('0.00')} лв
                    </p>
                    <p className="mt-2 text-center">
                    Дата: {moment(order.created).format('D/M/YYYY')} 
                    </p>
                </div>
                </div>}
            </div>}  
            {!isEmpty(person) &&<div className="row text-center mt-3">
                <div className="col-12 text-center"> 
                {!isEmpty(person.image) && <img src={person.image} className="rounded-circle"
                alt="userimage" style={{widht: '3rem'}}/>}
                <h6 className="mt-2 text-center font-weight-bold">{person.name}</h6>                
                </div>              
                <div className="col-12 mt-2 text-center">
                    <p className="mt-2 text-center font-weight-bold">
                    Стойност: {numeral(order.amount).format('0.00')} лв
                    </p>
                    <p className="mt-2 text-center">
                    Дата: {moment(order.created).format('D/M/YYYY')} 
                    </p>
                </div>
            </div>}               
        </div>)
    }

    return (
      <div className="row mt-5">
      <Helmet>
      <title>БГШефс Съобщение</title>
      </Helmet>
        <div className="col-12 mt-3 m-auto" style={{border: "1px solid #ddd"}}>
            <div className="row">
            {content}
            <div className="col-7 col-md-8">
            <h6 className="mt-3 text-center font-weight-bold">Съобщения</h6>
            <hr></hr>
                {!isEmpty(messages) && messages.map(message => <div key={message._id} className="row">
                    <div className="col-2">
                    {!isEmpty(message.sender.image) && <img src={message.sender.image}
                        className="img rounded-circle" style={{width: '2rem'}}
                    alt="senderimage"/>}                    
                    </div>
                    <div className="col-10">
                    <p className="font-weight-bold">{message.sender.name}
                    <small className="ml-2">{moment(message.created).format('D/M H:MM')}</small>
                    </p>
                    <p>{message.text}</p>
                    </div>
                </div>)}
                <div className="row mt-3">
                <div className="col-10 m-auto">
                <form onSubmit={this.onSubmit}>   
                    <TextAreaGroup
                        name="text"
                        placeholder={this.state.text}
                        value={this.state.text}
                        error={this.state.errors.text}
                        onChange={this.onChange}                        
                    /> 
                    <div className="offset-5 offset-md-8 mt-0">
                    <input type="submit" value="Изпрати" 
                    className="btn btn-danger mb-3 mt-0"/>
                    </div>
                </form>
                </div>
                </div>
            </div>
            </div>
        </div>        
      </div>
    )
  }
}

const mapStateToProps = state => {
    return {    
        messages: state.messages,
        order: state.order,
        auth: state.auth,
    }
}

export default connect(mapStateToProps, 
    {addMessage, getOrderMessages, getSingleClientOrder})(SendMessage)
