import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import { connect } from 'react-redux'
import {getMyProfile, editFile, editProfile} from '../actions/profile'
import isEmpty from '../validation/isEmpty'
import TextFieldGroup from './common/textFieldGroup'
import TextAreaGroup from './common/textAreaGroup'
import InputGroup from './common/inputGroup'
import Spinner from './common/Spinner'

class EditChefProfile extends Component {
    state = {
    id: '',
    city: '',
    region: '',
    experience: 0,
    categories: '',    
    file: null,
    imageUrl: '', 
    imageSrc: '',   
    bio: '',
    phone: '',
    hourlyRate: 0,
    cuisines: '',
    loading: false,
    errors: {}
    }
    
    componentDidMount() {
        if(this.props.auth.user)
        this.props.getMyProfile(this.props.auth.user._id)        
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.errors) {
            this.setState({errors: nextProps.errors})
        }

        if(nextProps.profile.profile) {
            const profile = nextProps.profile.profile
            const cuisinesCSV = profile.cuisines.join(', ')
            const categoriesCSV = profile.categories.join(', ')
            const city = profile.city.charAt(0).toUpperCase() + profile.city.slice(1)

            profile._id = !isEmpty(profile._id) ? profile._id : ''
            profile.city = !isEmpty(profile.city) ? city : ''
            profile.region = !isEmpty(profile.region) ? profile.region : ''
            profile.imageUrl = !isEmpty(profile.imageUrl) ? profile.imageUrl : ''
            profile.experience = !isEmpty(profile.experience) ? profile.experience : ''
            profile.bio = !isEmpty(profile.bio) ? profile.bio : ''
            profile.phone = !isEmpty(profile.phone) ? profile.phone : ''
            profile.hourlyRate = !isEmpty(profile.hourlyRate) ? profile.hourlyRate : ''
        
            this.setState({
                id: profile._id,
                city: profile.city,
                region: profile.region,
                experience: profile.experience,
                categories: categoriesCSV,                
                imageUrl: profile.imageUrl,
                bio: profile.bio,
                phone: profile.phone,
                hourlyRate: profile.hourlyRate,
                cuisines: cuisinesCSV,
            })
        }
    }

    onChange = e => this.setState({[e.target.name]: e.target.value})
  
    onFileChange = (e) => {
        const files = e.target.files
        if(files && files.length > 0) {         
         this.setState({file: files[0]})
        }
    }        

    validateForm = () => {
        if (!isEmpty(this.state.city)) {
            if (!isEmpty(this.state.categories)) {
              if (!isEmpty(this.state.cuisines)) {
                if (!isEmpty(this.state.bio)) {
                  if (!isEmpty(this.state.experience)) {
                    return true
                  } else {
                    this.setState({errors: {
                        experience: "Моля добавете Вашият трудов стаж в години."
                    }})
                  }
                } else {
                  this.setState({errors: {
                    bio: "Моля напишете кратко представяне за Вас."
                  }})
                }
              } else {
                this.setState({errors: {
                  cuisines: "Моля изберете поне един вид кухня."
                }})
              }
            } else {
              this.setState({errors: {
                categories: "Моля изберете поне една категория."
              }})
            }
          } else {
            this.setState({errors: {
              city: "Моля изберете град."
            }})
          }    
    }

    onSubmit = async(e) => {
        e.preventDefault()        
        if(this.validateForm()) {
            this.setState({loading: true})
            const profile = {
            id: this.state.id,
            city: this.state.city.toLowerCase(),
            experience: this.state.experience,
            hourlyRate: this.state.hourlyRate,
            phone: this.state.phone,
            region: this.state.region,
            bio: this.state.bio,
            cuisines: this.state.cuisines
                .split(',')
                .map(cuisine => cuisine.toLowerCase()
                .trim())
                .filter((value, index, array) => array.indexOf(value) === index),
            categories: this.state.categories
                .split(',')
                .map(category => category.toLowerCase()
                .trim())
                .filter((value, index, array) => array.indexOf(value) === index)               
            }
            
            if(this.state.file) {
                const file = this.state.file
                const form = new FormData()
                form.append("file", file)
                form.append('upload_preset', "djqmacgo")
                form.append("tags", "chefs")
                await fetch("https://api.cloudinary.com/v1_1/bgchefs/image/upload", {
                  method: "POST",
                  body: form
                }).then(response => response.json())
                .then(response => {
                  const url = response.url
                  const token = url.split('/')
                  token[0]= "https:"
                  token[6] = "w_320,h_320,c_scale"
                  const newProfile = {...profile, imageUrl: token.join('/')}
                  this.props.editProfile(newProfile, this.props.history)
                })
                .catch(error => console.error('Error:', error))
            } else {
              this.props.editProfile(profile, this.props.history)
            }
          } else {
            return this.state.errors
        }    
    }

  render() {
    const errors = this.state.errors

    return (
    <div className="mt-4">
    <Helmet>
    <title>БГЩефс Промени Шеф Профил</title>
    </Helmet>
        <div className="row">
            <div className="col-md-9 m-auto">
            <Link to="/моят-шеф-профил" className="btn btn-danger">Назад към пофил</Link>
                <h1 className="display-3 text-center">Промени своя профил</h1>
                <small className="d-block pb-3">* = задължителни полета</small>
                
                <form onSubmit={this.onSubmit}>                    
                    <InputGroup
                        name="file"                        
                        placeholder="Вашата снимка" 
                        className="custom-file-input"
                        accept="image/*"
                        icon="fas fa-camera"
                        type="file" 
                        multiple ={false}
                        info="Изберете друга професионална снимка"         
                        onChange={this.onFileChange}                    
                    />
                    
                    <TextFieldGroup
                        name="city"
                        placeholder={this.state.city}
                        value={this.state.city}
                        error={this.state.errors.city}
                        info="* Град"
                        type="text"
                        onChange={this.onChange}                        
                    />
                    <TextFieldGroup
                        name="region"
                        placeholder={this.state.region ? this.state.region : "Район квартал или местност"}
                        value={this.state.region}
                        error={this.state.errors.region}
                        info="Променете или добавете район по желание"
                        type="text"
                        onChange={this.onChange}                        
                    />
                    <TextFieldGroup
                        name="experience"
                        placeholder={this.state.experience}
                        value={this.state.experience}
                        error={this.state.errors.experience}
                        info="* Професионален опит в години"
                        type="number"
                        onChange={this.onChange}                        
                    />
                    <TextFieldGroup
                        name="categories"
                        placeholder={this.state.categories}
                        value={this.state.categories}
                        error={this.state.errors.categories}
                        info="* Моля използвайте запетая, пример: Готварство, Хлебарство"
                        type="text"
                        onChange={this.onChange}                        
                    />
                    <TextAreaGroup
                        name="cuisines"
                        placeholder={this.state.cuisines}
                        value={this.state.cuisines}
                        error={this.state.errors.cuisines}
                        info="* Моля използвайте запетая, пример: Българска, Азиатска, друг вид"
                        onChange={this.onChange}                        
                    />                
                <div className="form-group">
                    <InputGroup 
                    name="hourlyRate"
                    placeholder={this.state.hourlyRate} 
                    value={this.state.hourlyRate} 
                    error={errors.hourlyRate} 
                    icon="fas fa-money-bill-alt"
                    type="number"          
                    onChange={this.onChange}
                    info="Почасово заплащане в лева" />            
                </div>
                    <TextAreaGroup 
                        name="bio" 
                        placeholder={this.state.bio}
                        value={this.state.bio} 
                        error={errors.bio}
                        info="* Кратко представяне"     
                        onChange={this.onChange} 
                    />
                    <div className="form-group">
                    <InputGroup 
                        name="phone"
                        placeholder={this.state.phone} 
                        value={this.state.phone} 
                        error={errors.phone} 
                        icon="fas fa-phone-square"
                        type="text"          
                        onChange={this.onChange}
                        info="Телефонен номер" />              
                    </div>
                     
                    <button type="submit" className="btn btn-danger btn-block mt-4" disabled={this.state.loading}>
                        {this.state.loading && <span><Spinner size="small"/></span>}
                        <span>Промени</span>          
                    </button>                     
                </form>
            </div>
        </div>
    </div>
    )
  }
}

const mapStateToProps = state => {
    return {
        profile: state.profile, 
        auth: state.auth,       
        errors: state.errors
    }
}

export default connect(mapStateToProps, 
{getMyProfile, editFile, editProfile})(EditChefProfile)
