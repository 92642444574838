import React, { Component } from 'react'
import {connect} from 'react-redux'
import {Helmet} from 'react-helmet'
import {updateUser, fetchUser, deleteUser} from '../actions/auth'
import isEmpty from '../validation/isEmpty'
import isEmail from '../validation/isEmail'
import classnames from 'classnames'

 class UserData extends Component {
    state = {
        name: '',
        email: '',
        password: '',  
        _id: '', 
        isChef: false,                     
        errors: {},
        message: null               
    } 
    
    componentDidMount() {
        this.props.fetchUser()
    }
    
    componentWillReceiveProps(nextProps) {
        if(nextProps.errors) {
            this.setState({errors: nextProps.errors})
        }

        if(nextProps.auth.user) {
            const user = nextProps.auth.user           
            
            user._id = !isEmpty(user._id) ? user._id : ''
            user.name = !isEmpty(user.name) ? user.name : ''
            user.email = !isEmpty(user.email) ? user.email : ''
            user.password = '' 
            user.isChef = !isEmpty(user.isChef) ? user.isChef: false           

            this.setState({
                name: user.name,
                _id: user._id,
                email: user.email,
                password: user.password,
                isChef: user.isChef
            })            
        }
    }        
    
    onChange = e => this.setState({[e.target.name]: e.target.value})

    validateForm = () => {
        if(!isEmpty(this.state.name)) {
            if (!isEmpty(this.state.email)) {
                if(!isEmail(this.state.email)) {
                    return true
                } else this.setState({
                    errors: {email: "Моля напишете коректен е-мейл."}
                })
            } else {
                this.setState({
                    errors: {email: "Моля попълнете е-мейл."}
                })  
            }
        } else {
            this.setState({
                errors: {name: "Моля попълнете Вашето име."}
            })
        }
    }

    onSubmit = (e) => {
        e.preventDefault()
        if(this.validateForm()) {
            if(!isEmpty(this.state.password)) {
                const UserData = {
                    name: this.state.name,
                    email: this.state.email.toLowerCase(),
                    _id: this.state._id,
                    password: this.state.password           
                }
                this.props.updateUser(UserData)
            } else {
                const UserData = {
                    name: this.state.name,
                    email: this.state.email.toLowerCase(),
                    _id: this.state._id,
                             
                }
                this.props.updateUser(UserData)
            }
            this.setState({message: "Успешно променихте Вашите потребителски данни!"})
        }        
    }

  render() {      
      const errors = this.state.errors
    
      return (
      <div className="mt-4">
      <Helmet>
      <title>БГШефс моите потребителски данни</title>
      </Helmet>
        <div className="row">
            <div className="col-9 m-auto">
            {this.state.message && (<h5 style={{color: 'red'}}>{this.state.message}</h5>)}
                <form className="form-group" onSubmit={this.onSubmit}>
                <div className="form-group">
                <input 
                    type="text" 
                    className={classnames("form-control form-control-sm", {
                        "is-invalid": errors.name
                    })} 
                    placeholder="Име" 
                    name="name"
                    value={this.state.name}
                    onChange={this.onChange}
                 /> 
                {errors.name && (<div className="invalid-feedback">{errors.name}</div>)}                
                </div>
                <div className="form-group">
                  <input 
                    type="email" 
                    className={classnames("form-control form-control-sm", {
                        "is-invalid": errors.email
                    })} 
                    placeholder="E-мейл" 
                    name="email"
                    value={this.state.email} 
                    onChange={this.onChange}
                  /> 
                  {errors.email && (<div className="invalid-feedback">{errors.email}</div>)}                 
                </div>
                <div className="form-group">
                  <input 
                    type="password"                     
                    className={classnames("form-control form-control-sm", {
                        "is-invalid": errors.password
                    })} 
                    placeholder="Парола" 
                    name="password" 
                    value={this.state.password}
                    onChange={this.onChange}
                    />  
                    {errors.password && (<div className="invalid-feedback">{errors.password}</div>)}                     
                </div>
                <button className="btn btn-danger btn-block mt-4 mb-3">Промени</button>
                </form>
            </div>           
        </div>
        <hr></hr>
        {!this.state.isChef && <div className="row mt-3 mr-auto ml-auto mb-auto text-center">
        <div className="col-3 m-auto">
        <button className="btn btn-danger" onClick={() => this.props.deleteUser(this.state._id)}>Изтрий акаунта</button>
        </div>        
        </div>}
      </div>
    )
  }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        errors: state.errors
    }
}

export default connect (mapStateToProps, 
    {fetchUser, updateUser, deleteUser})(UserData)
