import React from 'react'
import {Link} from 'react-router-dom'

export default () => {
  return (
    <div className="footer text-white p-4 text-center footer-dark bg-dark">
      <div className="row">
        <div className="col-md-6">
        <p className="lead font-weigh-bold text-white final"><u>Полезни връзки</u></p>
        <div>
        <Link className="btn btn-link" to="/контакти" style={{color: 'white'}}>За контакти</Link>
        <p>email: contact@bgchefs.com</p>
        </div>
        <div >
        <Link className="btn btn-link" to="/поверителност" style={{color: 'white'}}>Поверителност</Link>
        </div>
        <div >
        <Link className="btn btn-link" to="/общи-условия" style={{color: 'white'}}>Общи условия</Link>
        </div>  
        </div>
        <div className="col-md-6">
        <div className="mb-3">
        <p className="lead font-weigh-bold text-white mt-3 final"><u>Последвай ни</u></p>
        <a href="https://www.facebook.com/topBGChefs/">
        <span className="btn btn-link" style={{color: 'white'}}>
        <i className="fab fa-facebook fa-2x" aria-hidden="true"></i>        
        </span>
        </a>
        <a href="https://www.instagram.com/">
        <span className="btn btn-link" style={{color: 'white'}}>
        <i className="fab fa-instagram fa-2x" aria-hidden="true"></i>        
        </span>
        </a>
        </div> 
        <p className="final text-center mb-0">2018 &copy; БГ Шефс</p>  
        <p className="final mt-0">Всички права запазени.</p>    
        </div>      
      </div>        
    </div>
  )
}