import React from 'react'
import {Route} from 'react-router-dom'
import Navbar from '../Navbar'

const AdminRoute = ({component: Component, auth, ...rest}) => (
    <div>
    <Navbar/>
    <Route 
      {...rest} 
      render={props => (
          <Component {...props} />
        ) 
      }
    />    
    </div>
)

export default AdminRoute