import React from 'react'
import {Link} from 'react-router-dom'

export default function PostItem({favorites}) {
    return (
        favorites
        .filter(favorite => favorite !== null)
        .map(favorite => <div key={favorite._id}
            className="col-md-4 mt-3 mb-3">
            <div className="card">
              <Link to={`/шеф-профил/${favorite._id}`}>
                <img className="card-img-top" alt="chefimage" src={favorite.imageUrl}/>
              </Link>
              <div className="card-body">
                <h6>{favorite.user.name}</h6>
                <p> {favorite.offers.length} оферти</p>        
              </div>
            </div>
        </div>)
    )
}