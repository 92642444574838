import axios from 'axios'
import {setErrors} from './auth'

//Set Loading State
export const setOrdersLoading = () => ({
    type: "ORDER_LOADING"
})

//clear Orders
export const clearOrders = () => ({
    type: "CLEAR_ORDERS"
})

//add Pre-order item
export const addFood = (gig) =>({
    type: "ADD_FOOD",
    payload: gig
})

//remove Pre-order item
export const removeFood = (gig) => ({
    type: "REMOVE_FOOD",
    payload: gig.title
})

//fetch orders per client_id
export const fetchClientOrders = () => async dispatch => {    
    dispatch(setOrdersLoading())
    await axios.get('/api/orders/')
    .then(res => dispatch({
        type: "GET_ORDERS",
        payload: res.data
        }))    
    .catch(err => dispatch(setErrors(err.message)))
}

//fetch orders per sller_id
export const fetchChefOrders = (sellerId) => async dispatch => {
    dispatch({type: "CLEAR_ORDERS"})
    dispatch(setOrdersLoading())
    await axios.get(`/api/orders/chef/${sellerId}`)
    .then(res => dispatch({
        type: "GET_ORDERS",
        payload: res.data
        }))    
    .catch(err => dispatch(setErrors(err.message)))
}

//save order to database
export const createOrder = (order, history) => async dispatch => {
    await axios.post('/api/orders', order)
    .then(res => {
        dispatch({
        type: "GET_ORDER",
        payload: res.data
        })
        history.push('/поръчки')        
    })    
    .catch(err => dispatch(setErrors(err.message)))
}

//update order 
export const updateOrder = (order) => async dispatch => {
    await axios.post(`/api/orders/${order._id}`, order)
    .then(res => {
        dispatch(fetchChefOrders(res.data.seller))              
    }).catch(err => dispatch(setErrors(err.message)))
}

//fetch single client order per id
export const getSingleClientOrder = (id) => async dispatch => {
    dispatch(setOrdersLoading())
    await axios.get(`/api/orders/client/${id}`)
    .then(res => dispatch({
        type: "GET_ORDER",
        payload: res.data
        }))    
    .catch(err => dispatch(setErrors(err.message)))
}

//delete single client order by id
export const deleteOrder = (id, history) => async dispatch => {
    await axios.delete(`/api/orders/${id}`)
    .then(res => history.push('/поръчки'))
    .catch(err => dispatch(setErrors(err.message)))
}