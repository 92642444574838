import React, { Component } from 'react'
import StarRatingComponent from 'react-star-rating-component'
import moment from 'moment'
import isEmpty from '../validation/isEmpty'
import {TinyPagination} from 'react-pagination-custom'

class ReviewsOverview extends Component {
  constructor(props){
    super(props);
    this.state = {
    isMounted: false,
    activePage: 1,
    itemsPerPage: 3 
    }
    this.changePage = this.changePage.bind(this);
    this.renderBtnNumber = this.renderBtnNumber.bind(this);
  }
   
    componentDidMount() {
        this.setState({isMounted: true})
      }
    
    componentWillUnmount() {
      this.setState({isMounted: false})
      }

      changePage = (id) => {             
        this.setState({activePage: id})         
      }
    
      buttonPageClick(id){
        let {activePage} = this.state
        switch (id) {
          case '<<':
            this.changePage(activePage - 1);
            break;
          case '>>':
            this.changePage(activePage + 1);
            break;
          default:
            this.changePage(id);
            break;
        }
      }
    
      renderBtnNumber(id){
        let {activePage} = this.state
        return(
          <button
            onClick = {this.buttonPageClick.bind(this, id)}
            key = {id}
            className = {`page ${activePage === id? 'selected-page' : ''}`}
            style={{backgroundColor: "white"}}
          >
            {id}
          </button>
        )
      }
    
  render() {
      const {profile} = this.props
      const indexOfLast = this.state.activePage * this.state.itemsPerPage;
    const indexOfFirst = indexOfLast - this.state.itemsPerPage     
      
    return (
      <div id="reviews">
        <div className="row mt-3">
            <h6 className="ml-5 mr-5 lead">Ревюта: </h6>            
        </div>
        <hr></hr>
        {isEmpty(profile.reviews) && (<div className="row mt-2">
            <p className="ml-5 text-muted">Все още няма ревюта.</p></div>)}
        {!isEmpty(profile.reviews) && (<div>
            {profile.reviews.reverse()
              .slice(indexOfFirst, indexOfLast)
              .map(review => <div key={review._id} className="row m-auto">
            <div className="col-6 col-md-4">
            {!isEmpty(review.owner) && <p className="lead mb-0">
              {review.owner.name}</p>} 
            {!isEmpty(review.owner) && <img className="img rounded-circle" 
            style={{width: '4rem'}} src={review.owner.image} alt="clientimage"/>} 
            <p className="mt-2 mb-0">Рейтинг:</p>              
              <StarRatingComponent 
                name="rating" 
                starColor="#dc3545"
                editing={false}
                renderStarIcon={() => <span><i className="fas fa-star"></i></span>}
                starCount={5}
                value={review.rating}/>
                          
            </div>
            <div className="col-6 col-md-8">
              <h6 className="font-weight-bold mt-2 mb-3">{review.title}</h6>
              <p style={{overflowWrap: "break-word"}}>{review.description}</p>
              <p className="mt-0">Дата:
                <small className="font-weight-bold">{'  ' + moment(review.created).format("D/M/YYYY, h:mm")}</small>
              </p>
            </div>
            <div className="col-12">
            <hr></hr>
            </div>
          </div>)}              
        </div>)}
        {!isEmpty(profile.reviews) && <div className="pagination justify-content-center mt-2" style={{color: "black"}}>
        <TinyPagination
          total = {profile.reviews.length}
          selectedPageId = {this.state.activePage}
          itemPerPage = {this.state.itemsPerPage}
          renderBtnNumber = {this.renderBtnNumber}
          maxBtnNumbers = {5}
          preKey = '<<'
          nextKey = '>>'        
        />
          </div>}
      </div>
    )
  }
}

export default ReviewsOverview
