import React from 'react'
import {Link} from 'react-router-dom'
import moment from 'moment'
import isEmpty from '../../validation/isEmpty'

export default function PostItem({posts}) {
  return (
    posts.filter(post => post.owner !== null)
    .map(post => <div className="col-10 m-auto" key={post._id}>  
            <hr></hr>    
        <div className="row">
            <div className="col-3">
                {!isEmpty(post.owner.image) && <img src={post.owner.image} 
                className="img rounded-circle" alt="userimage" style={{width: '3rem'}} />}
            </div>
        <div className="col-7">
            <p className="lead font-weight-bold mb-0">{post.owner.name}</p>
            <p><small>{moment(post.created).format('D/M H:MM')}</small></p>
        </div>
        </div>
        <div className="row">
            <div className="col-3">
        </div>
            <div className="col-7">
            <Link to={`/обяви/${post._id}`}              
                style={{textDecoration: 'none'}}>
            <p className="lead font-weight-bold" 
                style={{color: 'black'}}>
                {post.title}</p>
            </Link>
            </div>
           <div className="col-12 ml-3">
            <Link to={`/обяви/${post._id}`}              
               style={{textDecoration: 'none'}}>
                <p  
                style={{color: 'black'}}>
                {post.text}</p>
            </Link>                        
            </div>
            </div>
        </div>) 
  )
}
