import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import {connect} from 'react-redux'
import {fetchChefProfile} from '../actions/profile'
import ChefOverview from './ChefOverview'
import OffersOverview from './OffersOverview'
import Spinner from './common/Spinner'
import ChefSchedule from './ChefSchedule';
import ReviewsOverview from './ReviewsOverview';
import isEmpty from '../validation/isEmpty'

class ChefProfile extends Component {       

    componentDidMount() {
        if(this.props.match.params.id) {            
        this.props.fetchChefProfile(this.props.match.params.id)
        
        }       
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.profile.profile === null && this.props.profile.loading) {
            this.props.history.push('/')
        }
    }

    
  render() {
      const {profile, loading} = this.props.profile
        let profileContent

    if(profile === null || loading) {
        profileContent = <Spinner/ >        
    } else if(!isEmpty(profile) && !loading) {
        profileContent = (
            <div>
              <nav className="nav col-11 m-auto border mb-5 border-dark" style={{borderRadius: '5px'}}>
              <ul className="nav nav-pills">
                <li className="nav-item col-md-3 col-6">
                    <a className="nav-link btn btn-danger m-2" href="#overview">Преглед</a>
                </li>
                <li className="nav-item col-md-3 col-6">
                    <a className="nav-link btn btn-danger m-2" href="#schedule">График</a>
                </li>
                <li className="nav-item col-md-3 col-6">
                    <a className="nav-link btn btn-danger m-2" href="#offers">Оферти</a>
                </li>
                <li className="nav-item col-md-3 col-6">
                    <a className="nav-link btn btn-danger m-2" href="#reviews">Ревюта</a>
                </li>
              </ul>
              </nav>
              <div style={{height: '20px'}}></div>
              <div className="col-11 m-auto border border-dark">
              <ChefOverview 
                profile={profile}
              />
              </div>
              <div style={{height: '20px'}}></div>
              <div className="col-11 m-auto border border-dark">
              <ChefSchedule
                profile={profile}
              />
              </div>
              <div style={{height: '20px'}}></div>
              <div className="col-11 m-auto border border-dark">
              <OffersOverview
                profile={profile}
              />
              </div>
              <div style={{height: '20px'}}></div>
              <div className="col-11 m-auto border border-dark">
              <ReviewsOverview
                profile={profile}
              />
              </div>
            </div>
            )
    } else if(isEmpty(profile) && !loading) {
        profileContent = <div className="col-12 m-auto">
        <Link to="/" className="btn btn-danger mt-3 mb-3">Назад към начало</Link>
        <h3 className="display-4 text-center mt-3">Профилът не може да бъде намерен.</h3>            
        </div>
    }
    
    
    return (<div className="mt-4">
    <Helmet>
    <title>Шеф Профил БГШефс</title>
    </Helmet>
        {profileContent}
    </div>)
  }
}

const mapStateToProps = state => {
    return {
        profile: state.profile
    }
}

export default connect(mapStateToProps, {fetchChefProfile})(ChefProfile)
