import React, { Component } from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import {addFile, addProfile, getCategories, getCities, getCuisines} from '../actions/profile'
import Select from 'react-select'
import InputGroup from './common/inputGroup'
import TextAreaGroup from './common/textAreaGroup'
import isEmpty from '../validation/isEmpty'
import classnames from 'classnames'
import Spinner from './common/Spinner'

class ChefsForm extends Component {
  state = {
    city: null,
    region: '',
    experience: 0,
    category: null,    
    file: null,
    imageSrc: '',
    bio: '',
    phone: '',
    hourlyRate: 0,
    cuisine: null,
    loading: false,
    errors: {}
  }
  
  componentDidMount() {
    this.props.getCities()
    this.props.getCuisines()
    this.props.getCategories()
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.errors) {
        this.setState({errors: nextProps.errors})
    }
  }

  onChange = e => this.setState({[e.target.name]: e.target.value})

  onFileChange = (e) => {
    const files = e.target.files
    if(files && files.length > 0) {         
     this.setState({file: files[0]})
    }
  } 

  handleCuisine = (cuisine) => {
    this.setState({ cuisine })         
  }

  handleCategory = (category) => {
    this.setState({ category })
  }

  handleCity = (city) => {
    this.setState({ city })
  }
 
  validateForm = () => {
    if (!isEmpty(this.state.city)) {
        if (!isEmpty(this.state.category)) {
          if (!isEmpty(this.state.cuisine)) {
            if (!isEmpty(this.state.bio)) {
              if (!isEmpty(this.state.experience)) {
                return true
              } else {
                this.setState({errors: {
                  experience: "Моля добавете Вашият трудов стаж в години."
                }})
              }
            } else {
              this.setState({errors: {
                bio: "Моля напишете кратко представяне за Вас."
              }})
            }
          } else {
            this.setState({errors: {
              cuisine: "Моля изберете поне един вид кухня."
            }})
          }
        } else {
          this.setState({errors: {
            category: "Моля изберете поне една категория."
          }})
        }
      } else {
        this.setState({errors: {
          city: "Моля изберете град."
        }})
      }    
  }

  onSubmit = async(e) => {
    e.preventDefault()
    if(this.validateForm()) {
      this.setState({loading: true})
      const profile = {
        city: this.state.city[0].value.toLowerCase(),
        experience: this.state.experience,
        hourlyRate: this.state.hourlyRate,
        phone: this.state.phone,
        region: this.state.region,
        bio: this.state.bio,
        cuisines: this.state.cuisine.map(item => item.value.toLowerCase()),
        categories: this.state.category.map(item => item.value.toLowerCase())
      }
      if(this.state.file) {
        const file = this.state.file
        const form = new FormData()
        form.append("file", file)
        form.append('upload_preset', "djqmacgo")
        form.append("tags", "chefs")
        await fetch("https://api.cloudinary.com/v1_1/bgchefs/image/upload", {
          method: "POST",
          body: form
        }).then(response => response.json())
        .then(response => {
          const url = response.url
          const token = url.split('/')
          token[0]= "https:"
          token[6] = "w_320,h_320,c_scale"
          const newProfile = {...profile, imageUrl: token.join('/')}
          this.props.addProfile(newProfile, this.props.history)
        })
        .catch(error => console.error('Error:', error))
      } else {
        this.props.addProfile(profile, this.props.history)
      }
    } else {
      return this.state.errors
    }    
  }

  render() {
    const cities = this.props.profile.cities
    const cuisines = this.props.profile.cuisines
    const categories = this.props.profile.categories
    const errors = this.state.errors
    const {isAuthenticated, user} = this.props.auth

    let categoryOptions = []
    if (categories) {
      categories.forEach(category => {
        categoryOptions.push({
          label: category.name,
          value: category.name
        })  
        return categoryOptions      
      })
    }

    let cuisineOptions = []
    if (cuisines) {
      cuisines.forEach(cuisine => {
        cuisineOptions.push({
          label: cuisine.name,
          value: cuisine.name
        })  
        return cuisineOptions      
      })
    }

    let cityOptions = []
    if (cities) {
      cities.forEach(city => {
        cityOptions.push({
          label: city.name,
          value: city.name
        })  
        return cityOptions      
      })
    }

    let content
    if(isAuthenticated && !user.isChef) {
      content = (
        <div>
        <div className="col-md-8 m-auto">
          <h1 className="display-4 text-center">Създайте своя профил</h1>
          <p className="lead text-center">
          Добавете малко информация за Вас, за да сте по-търсени
          </p>
          <small className="d-block pb-3">* = задължителни полета</small>
          <form onSubmit={this.onSubmit}>          
          <div className="form-group">          
            <InputGroup 
              name="file"
              placeholder="Вашата снимка" 
              className="custom-file-input"
              accept="image/*"
              icon="fas fa-camera"
              type="file"          
              onChange={this.onFileChange}
              info="Изберете Ваша професионална снимка за по-добро представяне"
            />
          </div>
          <div className="form-group"> 
            <div className={classnames("form-control form-control-lg", {
              "is-invalid": errors.city
              })}> 
            <Select 
              placeholder="Град"
              name="city"
              value={this.state.city}
              onChange={this.handleCity}
              options={cityOptions} 
              autoFocuse
              isSearchable
              isMulti                   
              />
              <small className="form-text text-muted">* Изберете Град</small>
              {errors.city && (<div className="invalid-feedback">{errors.city}</div>)}
            </div>
          </div>
          <div className="form-group">
            <InputGroup 
              name="region"
              placeholder="Район квартал или местност" 
              value={this.state.region} 
              error={errors.region} 
              icon="fas fa-map-marker-alt"
              type="text"          
              onChange={this.onChange}
              info="Добавете по желание район, квартал или местност към Вашият град"
            />
          </div>
          <div className="form-group">
            <InputGroup 
              name="experience"
              placeholder="Опит в години" 
              value={this.state.experience} 
              error={errors.experience} 
              icon="fas fa-utensils"
              type="number"                      
              onChange={this.onChange}
              info="* Добавете Вашият трудов опит в години"
            />
          </div>
          <div className="form-group">
            <div className={classnames("form-control form-control-lg", {
              "is-invalid": errors.category
              })}> 
              <Select 
                placeholder="Категория"
                name="category"
                value={this.state.category}
                onChange={this.handleCategory}
                options={categoryOptions} 
                autoFocuse
                isSearchable
                isMulti                       
                />
                <small className="form-text text-muted">* Изберете професонална категория</small>
                {errors.category && (<div className="invalid-feedback">{errors.category}</div>)}
              </div>
            </div>
            <div className="form-group"> 
              <div className={classnames("form-control form-control-lg", {
                "is-invalid": errors.cuisine
                })}> 
                <Select
                  value={this.state.cuisine}
                  onChange={this.handleCuisine}
                  options={cuisineOptions}
                  name="cuisine"
                  autoFocuse
                  isMulti
                  placeholder="Вид кухня"
                  className="form-control form-control-lg"                  
                  isSearchable
                />
                <small className="form-text text-muted">* Изберете видове кухня, които умеете</small>
                {errors.cuisine && (<div className="invalid-feedback">{errors.cuisine}</div>)}
              </div>
            </div>
            <div className="form-group">
            <InputGroup 
              name="hourlyRate"
              placeholder="Часово заплащане в лева" 
              value={this.state.hourlyRate} 
              error={errors.hourlyRate} 
              icon="fas fa-money-bill-alt"
              type="number"          
              onChange={this.onChange}
              info="Добавете по желание часово заплащане в лева" />              
            </div>
            <TextAreaGroup 
                name="bio" 
                placeholder="Кратко представяне"
                value={this.state.bio} 
                error={errors.bio}
                info="* Добавете кратък текст за себе си"     
                onChange={this.onChange} 
            />
            <div className="form-group">
            <InputGroup 
              name="phone"
              placeholder="Телефонен номер" 
              value={this.state.phone} 
              error={errors.phone} 
              icon="fas fa-phone-square"
              type="text"          
              onChange={this.onChange}
              info="Добавете по желание телефонен номер" />              
            </div>  
            <button type="submit" className="btn btn-danger btn-block mt-4" disabled={this.state.loading}>
            {this.state.loading && <span><Spinner size="small"/></span>}
            <span>Създай</span>          
            </button>      
                               
          </form>
        </div>
      </div>
    )
      
    } else if(isAuthenticated && user.isChef) {
      content = (<div className="row">
          <div className="col-12">
            <Link className="mt-2 ml-3 btn btn-danger" to="/">Начало</Link>
          </div>
          <div className="col-12 mt-2">
            <h3 className="text-center display-4 font-weight-bold">Вие вече имате създаден профил</h3>
          </div>
        </div>)

    } else if(!isAuthenticated) {
      content = (
        
          <div className="row">
            <h3>Вие трябва да сте регистриран потребител, за да можете да създадете свой профил на готвач в сайта. 
            Моля, регистрирайте се като използвайте бутона Регистрация или влезте в профила си чрез бутона Вход.
            </h3>
          </div>
       
      )
    }
    return (
      <div className="mt-4">
      <Helmet>
      <title>Създай Шеф Профил в БГШефс</title>
      </Helmet>
      {content}
      </div>
    )      
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    profile: state.profile,
    errors: state.errors
  }
}

const mapDispatchToProps = (dispatch) => ({
  addFile: (profile, file, history) => dispatch(addFile(profile, file, history)),
  addProfile: (profile, history) => dispatch(addProfile(profile, history)),
  getCategories: () => dispatch(getCategories()),
  getCuisines: () => dispatch(getCuisines()),
  getCities: () => dispatch(getCities()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChefsForm)


