import axios from 'axios'
import {setErrors, clearErrors} from './auth'

//Set Loading State
export const setGigsLoading = () => ({
    type: "GIG_LOADING"
})

//fetching all gigs per chef with user.id
export const fetchOffers = (id) => async dispatch => {
    dispatch(setGigsLoading())
    await axios.get(`/api/gigs/chef/${id}`)
    .then(res => dispatch({
        type: "GET_GIGS",
        payload: res.data
    }))    
    .catch(err => dispatch(setErrors(err.message)))
}

//adding a new gig 
export const addGig = (gig, history) => async dispatch => {
    dispatch(clearErrors())
    await axios.post('/api/profiles/mygigs', gig)
    .then(res => {
        const gig = {id: res.data._id}        
        axios.post('/api/profiles/editgigs', gig)
        .then(res => dispatch({type: "GET_PROFILE", payload: res.data}))
        .then(history.push('/моите-оферти'))
    })    
    .catch(err => dispatch(setErrors(err.message)))     
}

//save the gig's image to Azure database
export const addImage = (file, gig, history) => async dispatch => {  
    const form = new FormData()
    form.append('image', file)  
    await axios.post('/api/images', form)
    .then(res => {        
        const newGig = {...gig, imageUrl: res.data}
        dispatch(addGig(newGig, history)) 
    })
    .catch(err => dispatch(setErrors(err.message)))     
}

//get all gigs per user's id
export const getMyGigs = () => async dispatch => {
    dispatch({type: "GIG_LOADING"})
    await axios.get('/api/profiles/mygigs')
    .then(res => dispatch({
        type: "GET_GIGS",
        payload: res.data
    }))
    .catch(err => dispatch(setErrors(err.message)))
}

//update gig
export const editGig = (gig, history) => async dispatch => {
    dispatch(clearErrors())
    await axios.post(`/api/gigs/${gig._id}`, gig)
    .then(res => dispatch({type: "GET_GIG", payload: res.data})) 
    .then(history.push('/моите-оферти'))   
    .catch(err => dispatch(setErrors(err.message)))     
}

//save the gig's image to Azure database
export const editImage = (file, gig, history) => async dispatch => {  
    const form = new FormData()
    form.append('image', file)  
    await axios.post('/api/images', form)
    .then(res => {            
        const newGig = {...gig, imageUrl: res.data}
        dispatch(editGig(newGig, history)) 
    })
    .catch(err => dispatch(setErrors(err.message)))     
}

//get single Gig per id
export const getSingleGig = (id) => async dispatch => {
    await axios.get(`/api/gigs/${id}`)
    .then(res => dispatch({type: "GET_GIG", payload: res.data}))
    .catch(err => dispatch(setErrors(err.message)))
}

//delete single Gig per id
export const deleteGig = (id) => async dispatch => {
    await axios.delete(`/api/gigs/${id}`)
    .then(res => dispatch({type: "DELETE_GIG", payload: id}))
    .catch(err => dispatch(setErrors(err.message)))
}
