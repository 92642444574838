import React, { Component } from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import moment from 'moment'
import numeral from 'numeral'
import {fetchClientOrders, clearOrders} from '../actions/orders'
import Spinner from './common/Spinner'
import isEmpty from '../validation/isEmpty'
import Pagination from './common/pagination'

class ClientOrders extends Component {

  state={
    activePage: 1,
    itemsPerPage: 10              
  }

  componentWillMount() {
    this.props.fetchClientOrders()
  }

  handlePageChange = (e) => {             
    this.setState({activePage: Number(e.target.id)});
    }

  render() {
    const {orders, loading} = this.props.order
    
    let content
      if(isEmpty(orders) && loading) {
        content=(<div className="col-10 m-auto"><Spinner/></div>)
      } else if(!isEmpty(orders)) {
        content=(<div className="col-12 m-auto">
            <h6 className="text-center display-4">Вашите поръчки:</h6> 
            <hr></hr>
            
            {orders.map(order => 
            <div className="row" key={order._id}>
              <div className="col-4 col-md-2 border-right">
              <p className="mb-0">Шеф</p>
              {!isEmpty(order.seller.user) && <Link to={`/шеф-профил/${order.seller._id}`} style={{color: 'black'}}>
                <small className="font-weight-bold" style={{wordBreak:"break-all"}}>
                  {order.seller.user.name}
                </small>
              </Link>}
                <p className="mb-0 mt-2">Доставка</p>
                <p className="mt-0 ">
                  <small className="font-weight-bold" style={{wordBreak:"break-all"}}>
                    {order.delivery}
                  </small>
                </p>
                <p className="mb-0">Статус</p>
                <p className="mt-0">
                    <small className="font-weight-bold">{order.status}</small>
                </p>
                <p className="mb-0">Дата/час</p>
                <p className="mt-0">
                  <small className="font-weight-bold">{moment(order.created).format("D/M/YYYY, h:mm")}</small>
                </p>
                <p className="mb-0">Обща сума</p>
                <p className="mt-0">
                  <small className="font-weight-bold">
                      {numeral(order.amount).format('0.00')} лв
                  </small>
                </p> 
              
              </div>
          
              <div className="col-7 col-md-6 ml-md-5 border-right">
                <div className="row">
                  <h5>Поръчани ястия:</h5>
                </div>
                <div className="row mt-2">
                  <div className="col-4"> 
                                    
                  </div>
                  <div className="col-4">
                    <p>Брой</p>                    
                  </div>
                  <div className="col-4">
                    <p>Цена</p>
                  </div>
                </div>
              {!isEmpty(order.gigs) && order.gigs.map(gig => <div key={gig.gig._id} className="row">
                <div className="col-4">
                  <h6>{gig.gig.title}</h6>
                </div>                              
                <div className="col-4">                                           
                  <h6>{gig.quantity}</h6>
                </div>
                
                <div className="col-4">                                            
                  <h6>{numeral(gig.gig.price).format('0.00')} лв</h6>                       
                </div>          
              
                </div>)}                
              </div>
              <div className="col-md-3 lg-ml-3">
                <h6 className="mb-2">Действия</h6>            
                <Link 
                className="btn btn-danger mb-3 mr-3"
                to={`/поръчки/${order._id}`}
                >Повече детайли</Link>  
                <Link 
                className="btn btn-danger mb-3 mr-3"
                to={`/съобщение/${order._id}`}
                >Съобщение</Link>             
                <Link 
                className="btn btn-danger mb-3"
                to={`/напиши-ревю/${order._id}`}
                >Напиши ревю</Link> 
              </div> 
              <div className="col-12">
                <hr></hr>
              </div>             
              </div>)} 
            <Pagination 
              onClick={this.handlePageChange} 
              itemsPerPage={this.state.itemsPerPage} 
              itemsNumber={orders.length}
            />                   
            </div>)
      } else if(isEmpty(orders) && !loading) {
      content=(<div className="col-10 m-auto">
        <h6 className="text-center lead" style={{fontWeight: "bold"}}>Вие все още нямате поръчки.</h6>
        </div>)
    }

    return (
      <div className="row mt-4">
      <Helmet>
      <title>БГШефс Вашите клиентски поръчки</title>
      </Helmet>
        {content}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    order: state.order
  }
}

export default connect(mapStateToProps, {fetchClientOrders, clearOrders})(ClientOrders)
