import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import {withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import logo from '../images/logo.png'
import {logoutUser, fetchUser} from '../actions/auth'
import {searchItem, getProfiles} from '../actions/profile'
import Registration from './Modals/Registration'
import Login from './Modals/Login'
import isEmpty from '../validation/isEmpty'
import avatar from '../images/avatar.jpg'
import {getMyMessages} from '../actions/messages'

class Navbar extends Component {

    state = {
        toggleRegistration: false,
        toggleLogin: false,
        errors: {}, 
        searchChef: null
    }

    componentDidMount() {
        this.props.fetchUser()
        if(this.props.auth.isAuthenticated) {
        this.props.getMyMessages(this.props.auth.user._id)
        }
        
    }

    componentWillReceiveProps(nextProps, prevState) {
        if (nextProps.errors !== prevState.errors) {
            this.setState({errors: nextProps.errors})
        }
    }

    onChange = (e) => this.setState({[e.target.name]: e.target.value})

    onSearch = (e) => {  
        e.preventDefault()      
        this.props.searchItem(this.state.searchChef)                     
    }

    onLogoutClick = (e) => {
        e.preventDefault()
        this.props.logoutUser()        
    }

    onRegister = () => {
        this.setState({toggleRegistration: true})
    }

    onLogin = () => {
        this.setState({toggleLogin: true})
    }

    clearRegister = () => {
        this.setState({toggleRegistration: false})
    }

    clearLogin = () => {
        this.setState({toggleLogin: false})
    }

  render() {
    const {isAuthenticated, user} = this.props.auth    
    const messages = this.props.messages
    let counter = null
    if(!isEmpty(messages)) {
        counter = messages.messages.filter(message => !message.isRead).length
    }

    const authLinks = (
      <ul className="navbar-nav">  
      <li className="nav-item">      
        <Link className="btn btn-link" to="/обяви" style={{color: 'white'}}>
        <h5>Oбяви</h5>
        </Link>
      </li> 
      <li className="nav-item" title="любими">      
        <Link className="btn btn-link " to="/любими" style={{color: 'white'}}>
        <span>
        <i className="fa fa-heart fa-2x" aria-hidden="true"></i>
        <i className="badge badge-notify"></i>
        </span>              
        </Link>
      </li>     
      <li className="nav-item" title="съобщения">      
        <Link className="btn btn-link " to="/съобщения" style={{color: 'white'}}>
        <span>
        <i className="fa fa-envelope fa-2x" aria-hidden="true"></i>
        <i className="badge badge-notify">{counter}</i>
        </span>              
        </Link>
      </li>            
      <li className="nav-item dropdown mr-5">
        <div className="nav-link dropdown-toggle btn btn-link" 
        id="navbarDropdown" role="button" 
        data-toggle="dropdown" 
        aria-haspopup="true" 
        aria-expanded="false"
        style={{color: 'white'}}
        ><img 
        className="rounded-circle"
        src={!!user.image ? user.image : avatar} 
        alt="avatar"
        title="Този сайт използва снимки от https://gravatar.com/"
        style={{width: "40px"}}        
        />          
        </div>
        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            {user.isChef && <Link className="dropdown-item" to="/моят-шеф-профил">
                <i className="fa fa-user mr-1" aria-hidden="true"></i>
            Профил</Link>}
            <Link className="dropdown-item" to="/моите-данни">
                <i className="fa fa-address-book mr-1" aria-hidden="true"></i>
            Моите данни</Link>
            <Link className="dropdown-item" to="/поръчки">
                <i className="fa fa-shopping-cart mr-1"></i>
            Поръчки</Link> 
            <Link className="dropdown-item" to="/създай-обява">
                <i className="fa fa-comment-alt mr-1"></i>
            Създай обява</Link>  
            <a className="dropdown-item" href="/" onClick={this.onLogoutClick}>
            <i className="fas fa-sign-out-alt mr-1"></i>Изход
            </a>          
        </div>
        </li>        
      </ul>
    )

    const guestLinks = (
        <ul className="navbar-nav">
          <li className="nav-item">
            <button 
                className="btn btn-link" 
                style={{color: 'white'}}
                onClick={this.onRegister}>
                <h5>Регистрация</h5>
            </button>
          </li>
          <li className="nav-item">
            <button 
                className="btn btn-link" 
                style={{color: 'white'}}
                onClick={this.onLogin}>
                <h5>Вход</h5>
            </button>            
          </li>
        </ul>
    )

    let child = null
    if (this.state.toggleRegistration) {
        child = (
            <div className="modal">
            <Registration 
            isOpen={this.state.toggleRegistration}
            onClose={this.clearRegister} />
            </div>)
    }

    if (this.state.toggleLogin) {
         child = (
            <div className="modal"> 
            <Login
                isOpen={this.state.toggleLogin}    
                onClose={this.clearLogin} />
            </div>)
    }

    return ( 
  
        <nav className="navbar navbar-expand-md navbar-dark bg-dark">        
            <Link className="navbar-brand ml-5" to="/">
                <img src={logo} alt="logo" id="logo" className="brand" style={{width: "4rem"}}/> 
                 <h6 className="text-white mt-2">БГ Шефс</h6>          
            </Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>            
            <div className="collapse navbar-collapse" id="navbarSupportedContent">                
                <form onSubmit={this.onSearch} className="input-group searchBar">
                    <input 
                    type="text" 
                    name="searchChef" 
                    className="form-control" 
                    onChange={this.onChange}
                    placeholder="Град" />
                    <button type="submit" className="btn btn-danger">
                    <i className="fa fa-search"></i>
                    </button>
                </form>
                <div className="navbar-nav ml-auto">
                    <ul className="navbar-nav"> 
                    <li className="nav-item">
                        <Link to="/" className="btn btn-link" style={{color: 'white'}}>
                        <h5>Начало</h5>
                        </Link>
                        </li>                       
                        <li className="nav-item">
                        <Link to="/стани-шеф" className="btn btn-link" style={{color: 'white'}}>
                        <h5>Стани Шеф</h5>
                        </Link>
                        </li>
                    </ul>
                    {isAuthenticated ? authLinks : guestLinks}
                </div>
            </div>
            {child}            
        
    </nav> 
      
    )
  }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        errors: state.errors,
        messages: state.messages
    }
}


export default withRouter(connect(mapStateToProps, 
    {logoutUser, fetchUser, getProfiles, searchItem, getMyMessages})(Navbar))
