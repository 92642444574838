import React, { Component } from 'react'
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet'
import {Link} from 'react-router-dom'
import isEmpty from '../validation/isEmpty'
import Spinner from './common/Spinner'
import Pagination from './common/pagination'
import {getFavorites} from '../actions/favorites'
import FavoriteCard from './common/FavoriteCard'

class Favorites extends Component {

  state = {
    activePage: 1,
    itemsPerPage: 6
  }

  componentDidMount() {
    this.props.getFavorites()
  }

  handlePageChange = (e) => {             
    this.setState({activePage: Number(e.target.id)})     
  }

  render() {
    const {favorites, loading} = this.props.favorites
    
    let content = null
    if(isEmpty(favorites) && loading) {
      content = <div className="col-10 m-auto"><Spinner /></div>
    } else if(!isEmpty(favorites) && !loading) {
      content = <div className="col-12 m-auto">
      <h3 className="text-center lead font-weight-bold">Вашият списък с любими готвачи</h3>
      <div className="row">
      <FavoriteCard favorites={favorites} />   
      </div> 
      </div>
    } else if(isEmpty(favorites) && !loading) {
      content = (<div className="col-10 m-auto"><Link className="btn btn-danger" to="/">Начало</Link>
      <h3 className="lead text-center mt-3">Вашият лист с любими шефове е празен</h3>
      </div>)
    }     
    
    return (
      <div className="row mt-4">
      <Helmet>
      <title>Любими Шефове БГШефс</title>
      </Helmet>
        {content}
        <div className="col-12 m-auto">
        {!isEmpty(favorites) && <Pagination 
          onClick={this.handlePageChange} 
          itemsPerPage={this.state.itemsPerPage}
          itemsNumber={favorites.length}/>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        errors: state.errors,
        favorites: state.favorites
    }
}

export default connect(mapStateToProps, {getFavorites})(Favorites)
