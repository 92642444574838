import React from 'react'
import Moment from 'moment'

export default function UserRow({reviews, onDelete}) {
  return (
   reviews.map(review => (<div className="row mr-2" key={review._id} style={{border: "1px solid black"}}>
    <div className="col-2 d-none d-xl-block">    
        <span>{review.chef ? review.chef : null}</span>   
    </div>   
    <div className="col-2 d-none d-md-block text-center">    
        <span>{review.owner.name ? review.owner.name: null}</span>
    </div>
    <div className="col-md-1 col-3 text-center">    
        <span>{review.rating}</span>   
    </div>
    <div className="col-md-1 col-3 text-center">    
        <span>{review.title}</span>   
    </div>
    <div className="col-3 d-none d-md-block text-center">    
        <span>{review.description}</span>   
    </div>
    <div className="col-xl-1 d-none d-xl-block text-center">
        <span>{Moment(review.created).format("DD/MM/YYYY")}</span>
    </div>
    <div className="col-lg-1 col-2 ml-md-0 text-center">
    <button className="btn btn-danger" 
      onClick={() => onDelete(review._id, review.chef)}
      >X</button>
    </div>
    </div>
  ))
  )
}