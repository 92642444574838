import React, { Component } from 'react'
import {connect} from 'react-redux'
import {Helmet} from 'react-helmet'
import third from '../images/third.jpg'
import second from '../images/second.jpg'
import first from '../images/first.jpg'
import Carousel from 'react-image-carousel'
import {getProfiles} from '../actions/profile'
import isEmpty from '../validation/isEmpty'
import ChefCard from './common/ChefCard'
import {TinyPagination} from 'react-pagination-custom'

class Home extends Component {
  constructor(props){
    super(props);
    this.state = {
      activePage: 1,
      itemsPerPage: 6,
      word: "Варна"};
    this.changePage = this.changePage.bind(this);
    this.renderBtnNumber = this.renderBtnNumber.bind(this);
  }
    
  componentDidMount() {      
    if(!isEmpty(this.props.search)) {
    this.props.getProfiles(encodeURIComponent(this.props.search), this.state.activePage - 1)       
    } else {
      this.props.getProfiles(encodeURIComponent(this.state.word), this.state.activePage - 1)       
    }   
  }

  componentWillReceiveProps(nextProps) {
    if(!isEmpty(nextProps.search)) {
      this.props.getProfiles(encodeURIComponent(nextProps.search), 0)
    }
}

  changePage = (id) => {   
    this.setState({activePage: id})
    if(!isEmpty(this.props.search)){
      this.props.getProfiles(encodeURIComponent(this.props.search), id - 1) 
    } else {
      this.props.getProfiles(encodeURIComponent(this.state.word), id - 1) 
    }    
  }

  buttonPageClick(id){
    let {activePage} = this.state
    switch (id) {
      case '<<':
        this.changePage(activePage - 1);
        break;
      case '>>':
        this.changePage(activePage + 1);
        break;
      default:
        this.changePage(id);
        break;
    }
  }

  renderBtnNumber(id){
    let {activePage} = this.state
    return(
      <button
        onClick = {this.buttonPageClick.bind(this, id)}
        key = {id}
        className={`page ${activePage === id? 'selected-page' : ''}`}
        style={{backgroundColor: "white"}}
      >
        {id}
      </button>
    )
  }

  render() {
    const profiles = this.props.profiles  
        
    let images = [
      first,
      second,
      third
    ];
    
    return (
  <div className="mt-0">
  <Helmet>
  <title>БГ Шефс - Избери своя професионален готвач</title>
  </Helmet>
    <div className="my-carousel">
      <Carousel images={images} 
            thumb={false}
            loop={true}
            autoplay={3000}/>
      </div>   
    
    <div className="row m-auto">
      <h1 className="display-4 text-center mt-0 mt-md-2">Запознайте се с нашите майстор-готвачи</h1>
      {!isEmpty(profiles) && profiles.profiles.length > 0
        ? <ChefCard chefs= {profiles.profiles}/> 
        : (<div className="col-9 m-auto">
        <h3 className="text-center display-4 text-danger">Скоро и във Вашия град</h3>
        </div>)}
    </div>
        
      {!isEmpty(profiles) && <div className="pagination justify-content-center mt-2" style={{color: "black"}}>
      <TinyPagination
        total = {profiles.totalProfiles}
        selectedPageId = {this.state.activePage}
        itemPerPage = {this.state.itemsPerPage}
        renderBtnNumber = {this.renderBtnNumber}
        maxBtnNumbers = {5}
        preKey = '<<'
        nextKey = '>>'        
      />
        </div>}
   
    <div className="row mt-5">
      <div className="col-md-3 col-sm-6">
        <div className="card-outline-n">        
          <div className="card-titile text-center">
          <button className="btn-danger rounded-circle" style={{width: '150px', height: '150px'}}>
        <i className="fas fa-globe icon-7x" style={{width: '100px', height: '100px'}}></i>
        </button>
          <h5 className="mt-2" style={{fontWeight: 'bold'}}>НАМЕРИ</h5>
          <div className="card-body text-center">
          <p>Намери професионален готвач близо до теб</p>          
          </div>
          </div>          
        </div>
        </div>
        <div className="col-md-3 col-sm-6">
        <div className="card-outline-n">        
          <div className="card-titile text-center">
          <button className="btn-danger rounded-circle" style={{width: '150px', height: '150px'}}>
        <i className="fas fa-shopping-basket icon-7x" style={{width: '100px', height: '100px'}}></i>
        </button>
          <h5 className="mt-2" style={{fontWeight: 'bold'}}>Поръчай</h5>
          <div className="card-body text-center">
          <p>Поръчай от предложените ястия или наеми готвач почасово</p>          
          </div>
          </div>          
        </div>
        </div>
        <div className="col-md-3 col-sm-6">
        <div className="card-outline-n">        
          <div className="card-titile text-center">
          <button className="btn-danger rounded-circle" style={{width: '150px', height: '150px'}}>
        <i className="fas fa-envelope-square icon-7x" style={{width: '100px', height: '100px'}}></i>
        </button>
          <h5 className="mt-2" style={{fontWeight: 'bold'}}>Свържи се</h5>
          <div className="card-body text-center">
          <p>Напиши съобщение за уточнение</p>          
          </div>
          </div>          
        </div> 
        </div> 
        <div className="col-md-3 col-sm-6">
        <div className="card-outline-n">        
          <div className="card-titile text-center">
          <button className="btn-danger rounded-circle" style={{width: '150px', height: '150px'}}>
        <i className="fas fa-truck icon-7x" style={{width: '100px', height: '100px'}}></i>
        </button>
          <h5 className="mt-2" style={{fontWeight: 'bold'}}>Вземи</h5>
          <div className="card-body text-center">
          <p>Вземи ястията или поискай доставка</p>          
          </div>
          </div>          
        </div> 
      </div>
    </div>
    
    </div>     
   
    )
  }
}

const mapStateToProps = state => {
  return {
    search: state.search,
    profiles: state.profile.profiles
  }
}

export default connect(mapStateToProps, {getProfiles})(Home)
