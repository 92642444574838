import isEmpty from '../validation/isEmpty'

const initialState = {
    isAuthenticated: false,
    user: {},
    users: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case "SET_CURRENT_USER":
        return {
            ...state,
            isAuthenticated: !isEmpty(action.user),
            user: action.user
        }
        case 'GET_USERS':
        return {
            ...state,
            users: action.payload,
            loading: false
        }
        default:
        return state
    }
}