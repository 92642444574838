import React, { Component } from 'react'
import {BrowserRouter as Router, Switch, Redirect} from 'react-router-dom'
import {Provider} from 'react-redux'
import jwt_decode from 'jwt-decode'
import setAuthToken from './utils/setAuthToken'
import configureStore from './store/configureStore'
import './App.css'
import PrivateRoute from './components/routes/PrivateRoute'
import PublicRoute from './components/routes/PublicRoute'
import AdminRoute from './components/routes/AdminRoute'
import Home from './components/Home'
import BecomeChef from './components/BecomeChef'
import ChefsForm from './components/ChefsForm'
import MyChefProfile from './components/MyChefProfile'
import EditChefProfile from './components/EditChefProfile'
import{setCurrentUser, logoutUser} from './actions/auth'
import {clearProfile} from './actions/profile'
import PostGig from './components/PostGig'
import MyGigs from './components/MyGigs'
import EditGig from './components/EditGig'
import PostSchedule from './components/PostSchedule'
import MySchedules from './components/MySchedules'
import UserData from './components/UserData'
import LostPassword from './components/LostPassword'
import ChefProfile from './components/ChefProfile'
import ChefOverview from './components/ChefOverview'
import OffersOverview from './components/OffersOverview';
import ChefSchedule from './components/ChefSchedule';
import ReviewsOverview from './components/ReviewsOverview';
import OffersPage from './components/OffersPage';
import ClientOrders from './components/ClientOrders'
import SingleClientOrder from './components/SingleClientOrder';
import MyGigsOrders from './components/MyGigsOrders';
import CreateReview from './components/CreateReview';
import SendMessage from './components/SendMessage';
import Messages from './components/Messages';
import Favorites from './components/Favorites'
import CreatePost from './components/CreatePost';
import Posts from './components/Posts';
import NotFoundPage from './components/NotFoundPage'
import SinglePost from './components/SinglePost';
import PrivacyPolicy from './components/PrivacyPolicy';
import Terms from './components/Terms';
import ContactUs from './components/ContactUs';
import AdminPanel from './components/AdminPanel';

const store = configureStore()

// check for token
if(localStorage.jwtToken) {
  //set auth token header auth
  setAuthToken(localStorage.jwtToken)
  //decode the token and get the user info and expiration
  const decodedUser = jwt_decode(localStorage.jwtToken)
  const user = decodedUser.user
  //set user and isAuthenticated
  store.dispatch(setCurrentUser(user))  
  //check for expired token
  const currentTime = Date.now() /1000
  if(decodedUser.exp < currentTime) {
    //Logout the user
    store.dispatch(logoutUser())
    //Clear current Profile
    store.dispatch(clearProfile())    
  }
}

class App extends Component {

  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">          
          <Switch>          
            <PublicRoute exact path="/" component={Home} />
            <PublicRoute exact path="/стани-шеф" component={BecomeChef}/>
            <PublicRoute exact path="/шеф-форма" component={ChefsForm}/>
            <PublicRoute exact path="/акаунт" component={LostPassword}/>
            <PublicRoute exact path="/поверителност" component={PrivacyPolicy}/>
            <PublicRoute exact path="/общи-условия" component={Terms}/>
            <PublicRoute exact path="/контакти" component={ContactUs}/>
            <PublicRoute exact path="/шеф-профил/:id" component={ChefProfile}/>
            <PublicRoute exact path="/шеф-профил/:id/преглед" component={ChefOverview}
              onEnter={function(){
                document.getElementById("overview").scrollIntoView();
                }
              }
            />  
            <PublicRoute exact path="/шеф-профил/:id/график" component={ChefSchedule}
              onEnter={function(){
                document.getElementById("schedule").scrollIntoView();
                }
              }
            />  
            <PublicRoute exact path="/шеф-профил/:id/оферти" component={OffersOverview}
              onEnter={function(){
                document.getElementById("offers").scrollIntoView();
                }
              }
            /> 
            <PublicRoute exact path="/шеф-профил/:id/ревюта" component={ReviewsOverview}
              onEnter={function(){
                document.getElementById("reviews").scrollIntoView();
                }
              }
            /> 
            <PublicRoute exact path="/оферти/:id" component={OffersPage}/>                      
              <PrivateRoute exact path="/моят-шеф-профил" component={MyChefProfile}/>
              <PrivateRoute exact path="/промени-шеф-профил" component={EditChefProfile}/>
              <PrivateRoute exact path="/създай-оферта" component={PostGig}/>
              <PrivateRoute exact path="/моите-оферти" component={MyGigs}/>
              <PrivateRoute exact path="/моите-оферти/:id" component={EditGig}/>
              <PrivateRoute exact path="/създай-график" component={PostSchedule}/>
              <PrivateRoute exact path="/моя-график" component={MySchedules}/>
              <PrivateRoute exact path="/моите-поръчки/:id" component={MyGigsOrders}/>
              <PrivateRoute exact path="/моите-данни" component={UserData}/>
              <PrivateRoute exact path="/поръчки" component={ClientOrders}/>
              <PrivateRoute exact path="/поръчки/:id" component={SingleClientOrder}/>
              <PrivateRoute exact path="/напиши-ревю/:id" component={CreateReview}/>
              <PrivateRoute exact path="/съобщение/:id" component={SendMessage}/>
              <PrivateRoute exact path="/съобщения" component={Messages}/>
              <PrivateRoute exact path="/любими" component={Favorites}/>
              <PrivateRoute exact path="/създай-обява" component={CreatePost}/>
              <PrivateRoute exact path="/обяви" component={Posts}/>
              <PrivateRoute exact path="/обяви/:id" component={SinglePost}/>
              <AdminRoute exact path="/admin-panel" component={AdminPanel}/>
              <PublicRoute component={NotFoundPage} />  
              <Redirect to="/" />                
            </Switch>                   
          </div>         
        </Router>
      </Provider>
    )
  }
}

export default App


