import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import Navbar from '../Navbar'
import PrivateFooter from '../PrivateFooter'

const PrivateRoute = ({component: Component, auth, ...rest}) => (
  <div>
  <Navbar />        
    <div className="container"> 
    <Route 
      {...rest} 
      render={props => 
        auth.isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
    </div>
    <PrivateFooter />
  </div>
)

const mapStateToProps = state => {
  return {
    auth: state.auth    
  }
}

export default connect(mapStateToProps)(PrivateRoute)