import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import {editImage, editGig, getSingleGig} from '../actions/gigs'
import isEmpty from '../validation/isEmpty'
import Spinner from './common/Spinner'
import InputGroup from './common/inputGroup'
import TextAreaGroup from './common/textAreaGroup'

 class EditGig extends Component {
    state = {
        _id: '',
        title: '',
        file: null,
        imageUrl: '',
        imageSrc: '',
        description: '',
        quantity: 0,
        price: 0,
        cuisines: null,
        preparation: 0,    
        takeaway: false,
        atHome: false,
        delivery: false,
        beGuest: false, 
        loading: false, 
        errors: {}
    }

    componentDidMount() {
        if(this.props.match.params.id) {
            this.props.getSingleGig(this.props.match.params.id)            
        }        
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.errors) {
            this.setState({errors: nextProps.errors})
        }

        if(nextProps.gigs.gig) {
            const gig = nextProps.gigs.gig
            const cuisinesCSV = gig.cuisines
                                
            this.setState({
                _id:!isEmpty(gig._id) ? gig._id : '',
                title: !isEmpty(gig.title) ? gig.title : '',
                description: !isEmpty(gig.description) ? gig.description : '',
                imageUrl: !isEmpty(gig.imageUrl) ? gig.imageUrl : '',
                price: !isEmpty(gig.price) ? gig.price : '',
                quantity: !isEmpty(gig.quantity) ? gig.quantity : '',
                preparation: !isEmpty(gig.preparation) ? gig.preparation : '',
                takeaway: !isEmpty(gig.takeaway) ? gig.takeaway : '',
                atHome: !isEmpty(gig.atHome) ? gig.atHome : '',
                beGuest: !isEmpty(gig.beGuest) ? gig.beGuest : '',
                delivery: !isEmpty(gig.delivery) ? gig.delivery : '',               
                cuisines: cuisinesCSV,
            })
        }
    }

    onChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name
    
        this.setState({
          [name]: value
        });
        }       
    
    onFileChange = (e) => {
        const files = e.target.files
        if(files && files.length > 0) {         
            this.setState({file: files[0]})
        }
    } 
          
    validateForm = () => {
            if (!isEmpty(this.state.title)) {
                if(!isEmpty(this.state.description)) {
                    if(this.state.quantity !== 0) {
                        if(this.state.price !== 0) {
                            if(this.state.preparation !== 0) {
                                if(!isEmpty(this.state.cuisines)) {
                                    return true
                                } else {
                                    this.setState({errors: {
                                    cuisines: "Моля добавете вид кухня на Вашето ястие!"
                                    }})
                                }
                            } else {
                                this.setState({errors: {
                                preparation: "Моля добавете време за приготвяне на Вашето ястие!"
                                }})
                            }
                        } else {
                            this.setState({errors: {
                            price: "Моля добавете цена на Вашето ястие!"
                            }}) 
                        }
                    } else {
                        this.setState({errors: {
                        quantity: "Моля добавете количество на Вашето ястие!"
                        }})
                    }
                } else {
                    this.setState({errors: {
                    description: "Моля добавете описание на Вашето ястие!"
                    }})
                }
            } else {
                this.setState({errors: {
                title: "Моля добавете заглавие на Вашето ястие!"
                }})
            }    
        }
    

        onSubmit = async(e) => {
            e.preventDefault()
            if(this.validateForm()) { 
                this.setState({loading: true})               
                const gig = {
                _id: this.state._id,
                title: this.state.title,            
                description: this.state.description,
                quantity: this.state.quantity,
                price: this.state.price,
                cuisines: this.state.cuisines,
                preparation: this.state.preparation,    
                takeaway: this.state.takeaway,
                atHome: this.state.atHome,
                delivery: this.state.delivery,
                beGuest: this.state.beGuest,
                imageUrl: this.state.imageUrl
                }            
               
            if(this.state.file) {
                const file = this.state.file
                const form = new FormData()
                form.append("file", file)
                form.append('upload_preset', "djqmacgo")
                form.append("tags", "chefs")
                await fetch("https://api.cloudinary.com/v1_1/bgchefs/image/upload", {
                method: "POST",
                body: form
                }).then(response => response.json())
                .then(response => {
                const url = response.url
                const token = url.split('/')
                token[0]= "https:"
                token[6] = "w_640,h_320,c_scale"
                const newGig = {...gig, imageUrl: token.join('/')}
                this.props.editGig(newGig, this.props.history)
                })
            .catch(error => console.error('Error:', error))
              } else {
                this.props.editGig(gig, this.props.history)
              } 
            } else {
              return this.state.errors
        }    
    }


  render() {
    const {gig, loading} = this.props.gigs
    const errors = this.state.errors

    let info
    if(loading && !gig) {
        info = (<Spinner />)
    } else if (!loading && isEmpty(gig)) {
        info = (<h3 className="text-center">Офертата не може да бъде намерена. Моля опитайте отново.</h3>)
    } 

    return (
    <div className="mt-4">
        <Helmet>
        <title>БГШефс промени оферта</title>
        </Helmet>
        <div className="row">
            <div className="col-md-9 m-auto">
                <Link to="/моите-оферти" className="btn btn-danger">Назад към моите оферти</Link>
                {info}                    
            </div>                
        </div>
        {!isEmpty(gig) && (
            <div className="mb-3">
                <div className="row">
                    <div className="col-md-9 m-auto">
                        <h3 className="display-3 text-center">Промени оферта</h3>                            
                    </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-5 mb-3">
                        <div className="card card-outline-dark" style={{width: '18rem'}}>
                                {!isEmpty(gig.imageUrl) && <img src={gig.imageUrl} alt="foodimage" className="card-img-top"/>}
                            
                            <div className="card-header">
                                <p className="lead font-weight-bold">{gig.title}</p>
                            </div>
                            <div className="card-body">
                            <p>Вид кухня: {gig.cuisines.length > 1 ? gig.cuisines.join(', ') : gig.cuisines}</p>
                            <p>Описание: {gig.description}</p>
                            <p>Време за подготовка в минути: {gig.preparation}</p>
                            <p>Количество: {gig.quantity}</p>
                            <p>Цена в лева: {gig.price}</p>
                            <p>Условия на доставка:</p>
                            <small className="mr-2 mb-2">{gig.takeaway && "Клиентът взема поръчката"}</small>
                            <small className="mr-2 mb-2">{gig.beGuest && "Сготви у дома на клиента"}</small>
                            <small className="mr-2 mb-2">{gig.atHome && "Покани клиента на гости"}</small>
                            <small className="mr-2 mb-2">{gig.delivery && "Занеси до дома на клиента"}</small>
                            </div>
                        </div>
                       </div> 
                     <div className="col-md-6 ml-md-3">
            <form onSubmit={this.onSubmit}> 
                     
                <div className="form-group">
                <InputGroup 
                    name="file"
                    placeholder="Снимка на ястието"                     
                    accept="image/*"
                    icon="fas fa-camera"
                    error={errors.file} 
                    type="file"          
                    onChange={this.onFileChange}
                    info="* Изберете снимка. Снимката е задължителен атрибут на всяко ястие."
                    />
                </div>
                <div className="form-group">
                <InputGroup 
                    name="title"
                    placeholder="Заглавие" 
                    value={this.state.title}
                    error={errors.title} 
                    icon="fab fa-nutritionix"
                    type="text"          
                    onChange={this.onChange}
                    info="* Заглавие на ястието."
                    />
                </div>   
                <TextAreaGroup 
                    name="description" 
                    placeholder="Описание на ястието"
                    value={this.state.description} 
                    error={errors.description}
                    info="* Добавете кратък текст за ястието"     
                    onChange={this.onChange} 
                />
                <div className="form-group">
                    <InputGroup 
                    name="quantity"
                    placeholder="Количество" 
                    value={this.state.quantity} 
                    error={errors.quantity} 
                    icon="fas fa-weight"
                    type="number"          
                    onChange={this.onChange}
                    info="* Количество на 1 порция в грама" />              
                </div>
                <div className="form-group">
                    <InputGroup 
                    name="price"
                    placeholder="Цена" 
                    value={this.state.price} 
                    error={errors.price} 
                    icon="fas fa-dollar-sign"
                    type="number"          
                    onChange={this.onChange}
                    info="* Цена за 1 порция в лева" />              
                </div>
                    <TextAreaGroup                        
                      value={this.state.cuisines}
                      onChange={this.onChange}                      
                      name="cuisines"
                      placeholder="Вид кухня"  
                      error={errors.cuisines}
                    info="* Моля, използвайте запетая"
                    />                    
                  <div className="form-group mt-3">
                    <InputGroup 
                    name="preparation"
                    placeholder="Време за приготвяне" 
                    value={this.state.preparation} 
                    error={errors.preparation} 
                    icon="fas fa-clock"
                    type="number"          
                    onChange={this.onChange}
                    info="* Време за приготвяне в минути" />              
                </div>
                <div>
                    <p className="lead text-center">Маркирай условия на доставка</p>
                </div>
                <div className="form-check form-check-inline mt-2">
                    <input 
                        className="form-check-input" 
                        type="checkbox" 
                        name="delivery"                          
                        value={!this.state.delivery}
                        onChange={this.onChange}
                    />
                    <label className="form-check-label">Занеси до дома на клиента</label>
                </div>
                <div className="form-check form-check-inline mt-2">
                    <input 
                    className="form-check-input" 
                    type="checkbox" 
                    name="beGuest" 
                    value={!this.state.beGuest}
                    onChange={this.onChange}/>
                    <label className="form-check-label">Сготви у дома на клиента</label>
                </div>
                <div className="form-check form-check-inline mt-2">
                    <input 
                        className="form-check-input" 
                        type="checkbox" 
                        name="takeaway"                          
                        value={!this.state.takeaway}
                        onChange={this.onChange}
                    />
                    <label className="form-check-label">Клиентът взема поръчката</label>
                </div>
                <div className="form-check form-check-inline mt-2">
                    <input 
                    className="form-check-input" 
                    type="checkbox" 
                    name="atHome" 
                    value={!this.state.atHome}
                    onChange={this.onChange}/>
                    <label className="form-check-label">Покани клиента на гости</label>
                </div>
                    <button type="submit" className="btn btn-danger btn-block mt-4" disabled={this.state.loading}>
                        {this.state.loading && <span><Spinner size="small"/></span>}
                        <span>Промени</span>          
                     </button>  
                </form>
                     </div>  
                    </div>
                </div>
            )}
      </div>
    )
  }
}

const mapStateToProps = state => {
    return {
        gigs: state.gigs,
        errors: state.errors,
        profile: state.profile
    }
}

export default connect(mapStateToProps, 
    {editImage, editGig, getSingleGig})(EditGig)
