import React, { Component } from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import numeral from 'numeral'
import  moment from 'moment'
import Select from 'react-select'
import Spinner from './common/Spinner'
import isEmpty from '../validation/isEmpty'
import {TinyPagination} from 'react-pagination-custom'
import {fetchChefOrders, updateOrder} from '../actions/orders'

class MyGigsOrders extends Component {

  constructor(props){
    super(props);
    this.state = {
      activePage: 1,
        itemsPerPage: 5,
        status: '',
        error: null  };
    this.changePage = this.changePage.bind(this);
    this.renderBtnNumber = this.renderBtnNumber.bind(this);
  }

    componentDidMount() {
        if(this.props.match.params.id) {
        this.props.fetchChefOrders(this.props.match.params.id)
        }
    }

    handleStatus = (status) => {
        this.setState({ status: status.value })         
    }

    onSubmit = (order) => {
        if (!isEmpty(this.state.status)) {
            order.status = this.state.status
            this.props.updateOrder(order)
        }         
    }

    changePage = (id) => {   
      this.setState({activePage: id})      
    }
    
    buttonPageClick(id){
      let {activePage} = this.state
      switch (id) {
        case '<<':
          this.changePage(activePage - 1);
          break;
        case '>>':
          this.changePage(activePage + 1);
          break;
        default:
          this.changePage(id);
          break;
      }
    }
    
    renderBtnNumber(id){
      let {activePage} = this.state
      return(
        <button
          onClick = {this.buttonPageClick.bind(this, id)}
          key = {id}
          className={`page ${activePage === id? 'selected-page' : ''}`}
          style={{backgroundColor: "white"}}
        >
          {id}
        </button>
      )
    }

  render() {
    const {orders, loading} = this.props.order
    const statusOptions= [
        {label: "Потвърди", value: "Потвърдена"},
        {label: "Откажи", value: "Отказана"}, 
        {label: "Достави", value: "Доставена"}      
    ]
    
    let content
      if(isEmpty(orders) && loading) {
        content=(<div className="col-10 m-auto"><Spinner/></div>)
      } else if(!isEmpty(orders)) {
        content=(<div className="col-12 m-auto">
        <Link to='/моят-шеф-профил' className="btn btn-danger">Назад към профил</Link>
            <h6 className="text-center display-4 mt-3">Вашите поръчки от клиенти:</h6> 
            <hr></hr>
            {orders.map(order => 
            <div className="row" key={order._id}>
                <div className="col-4 col-md-2 border-right">
                  <p className="mb-0">Клиент</p>
                  <p className="mt-0">
                    <small className="font-weight-bold" style={{wordBreak:"break-all"}}>
                      {order.client.name}
                    </small>
                    <img className="d-none d-md-block rounded-circle" 
                    style={{width: '50px'}}
                    alt="avatar"
                    src={order.client.image}/>
                  </p>
                    <p className="mb-0">Доставка</p>
                    <p className="mt-0">
                      <small className="font-weight-bold" style={{wordBreak:"break-all"}}>
                        {order.delivery}
                      </small>
                    </p>
                    <p className="mb-0">Статус</p>
                    <p className="mt-0">
                        <small className="font-weight-bold">{order.status}</small>
                    </p>
                    <p className="mb-0">Дата/час</p>
                    <p className="mt-0">
                      <small className="font-weight-bold">{moment(order.created).format("D/M/YYYY, h:mm")}</small>
                    </p>
                    <p className="mb-0">Обща сума</p>
                    <p className="mt-0">
                      <small className="font-weight-bold">
                          {numeral(order.amount).format('0.00')} лв
                      </small>
                    </p>                   
                  </div>
              
                  <div className="col-7 col-md-6 ml-md-5 border-right">
                    <div className="row">
                      <h5>Поръчани ястия:</h5>
                    </div>
                    <div className="row mt-2">
                      <div className="col-4"> 
                                        
                      </div>
                      <div className="col-4">
                        <p>Брой</p>                    
                      </div>
                      <div className="col-4">
                        <p>Цена</p>
                      </div>
                    </div>
                  {!isEmpty(order.gigs) && order.gigs.map(gig => <div key={gig._id} className="row">
                    <div className="col-4">
                      <h6>{gig.gig.title}</h6>
                    </div>                              
                    <div className="col-4">                                           
                      <h6>{gig.quantity}</h6>
                    </div>
                    
                    <div className="col-4">                                            
                      <h6>{numeral(gig.gig.price).format('0.00')} лв</h6>                       
                    </div>          
                  
                    </div>)}                
                  </div>
                  <div className="col-md-3 lg-ml-3">  
                  
                  <p className="mb-0 mt-3">Време за приготвяне</p>
                              
                    {order.gigs.map(gig => <p className="font-weight-bold mt-3"
                      key={gig._id}>{gig.gig.preparation} минути</p>)}                    
                  
                  <p className="mb-0">Ден и час на доставка</p>
                  <p className="mt-0">
                    <small className="font-weight-bold">
                      {order.day} / {order.hour} ч
                    </small>
                  </p>
                  <p className="mb-1">Промени статус</p>
                  <Select
                    value={this.state.status}
                    onChange={this.handleStatus}
                    options={statusOptions}
                    name="day"
                    autoFocuse                    
                    placeholder={!isEmpty(this.state.status) ? this.state.status : 'статус'}
                    className="form-control"                      
                />
                <button className="btn btn-danger btn-block mt-3" onClick={() => this.onSubmit(order)}>
                Запази статус
                </button>
                  </div> 
                  <div className="col-12">
                    <hr></hr>
                  </div>             
                  </div>)} 
                  <div className="pagination justify-content-center mt-2">
                  <TinyPagination
                  total = {orders.length}
                  selectedPageId = {this.state.activePage}
                  itemPerPage = {this.state.itemsPerPage}
                  renderBtnNumber = {this.renderBtnNumber}
                  maxBtnNumbers = {5}
                  preKey = '<<'
                  nextKey = '>>'        
                  />
                  </div>                  
                </div>)
        } else if(isEmpty(orders) && !loading) {
            content=(<div>
                <Link to='/моят-шеф-профил' className="btn btn-danger">Назад към профил</Link>
                <h6 className="text-center lead" style={{fontWeight: "bold"}}>
                Вие все още нямате поръчки.</h6>
                </div>)
        }   


    return (
    <div className="row mt-4">
    <Helmet>
    <title>Моите Поръчки БГШефс</title>
    </Helmet>
        {content}
    </div>
    )
  }
}

const mapStateToProps = state => {
    return {
        order: state.order
    }   
}

export default connect(mapStateToProps, {fetchChefOrders, updateOrder})(MyGigsOrders)
