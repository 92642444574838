import React from 'react'
import Moment from 'moment'

export default function UserRow({users, onDelete, page}) {
  return (
   users.map(user => (<div className="row mr-2" key={user._id} style={{border: "1px solid black"}}>
    <div className="col-2 d-none d-xl-block">    
    <span>{user._id}</span>   
    </div>   
    <div className="col-3 text-center">    
    <span>{user.name}</span>
    </div>
    <div className="col-4 col-md-3 ml-1 mr-1 text-center">
    <span>{user.email}</span>
    </div>
    <div className="col-1 d-none d-md-block text-center">    
    <span>{user.isChef ? "yes" : "no"}</span>   
    </div>
    <div className="col-2 col-xl-1 d-none d-md-block text-center">
    <span>{Moment(user.joinedAt).format('DD/MM/YYYY; H:M')}</span>
    </div>
    <div className="col-lg-1 col-2 ml-md-0 text-center">
    <button className="btn btn-danger" 
      onClick={() => onDelete(user._id, page)}
      >X</button>
    </div>
    </div>
  ))
  )
}