import React from 'react'
import {Link} from 'react-router-dom'
import numeral from 'numeral'

export default function GigsGroup({gig, index, onDeleteClick}) {
  return (
        <div key={index}>
            <div className="row" >
                <div className="col-3 col-md-2">
                    <Link to={`/моите-оферти/${gig._id}`}>
                        <img src={gig.imageUrl} 
                        className="img-fluid img-thumbnail" 
                        alt="food"
                        />
                    </Link>
                </div>
                    <div className="col-3 col-md-5">
                      <div className="row">
                      <div className="col-md-6">
                      <h5 className="lead">
                        {gig.title}
                        </h5>
                      </div>                                        
                        
                        <div className="col-md-2 mr-1">
                        <Link 
                        to={`/моите-оферти/${gig._id}`}
                        className="btn btn-outline-danger mt-2"
                        >Промени
                      </Link> 
                        </div>
                      </div>
                      
                                                                                                 
                    </div>
                    <div className="col-2">
                      <h6 className="text-muted">
                        {gig.quantity} грама
                      </h6>
                    </div> 
                    <div className="col-2">
                      <h6 className="font-weight-bold text-danger">
                        {numeral(gig.price).format('0.00')} лв
                      </h6>                      
                    </div> 
                    <div className="col-2 col-md-1 mr-1 mr-md-0">
                        <button 
                        onClick={onDeleteClick}
                        className="btn btn-danger"
                        >X
                      </button> 
                    </div>                                                    
                </div>                
            <hr></hr> 
            </div>
  )
}
