import React from 'react'
import {Link} from 'react-router-dom'

export default function NotFoundPage() {
  return (
    <div className="row">
    <div className="col-10 m-auto text-center">
    <h3>Грешка 404!
    Страницата не може да бъде намерена.  </h3>
    <Link className="text-danger lead font-weight-bold" to="/">Начало</Link>
    </div>
    </div>
  )
}
