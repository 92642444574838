import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import chef from '../images/chef.jpg'
import steps from '../images/steps.png'
import control from '../images/control.jpg'

class BecomeChef extends Component {
  render () {
      return (  
        <div className="mt-4">   
        <Helmet>
        <title>Стани Шеф в БГШефс</title>        
        </Helmet> 
        <div className="row">
          
          <div className="col-10 mb-3">
          <h2 className="text-center font-weight-bold mt-3 mb-3">Стани Шеф в БГ Шефс</h2>
            <hr></hr>  
          <h3 className="mt-2">Топ предложение за Вас - професионални готвачи.</h3>
          </div>
        </div>
        <div className="row">        
          <div className="col-md-3">
            <img className="img" src={chef} alt="cook" style={{width: '320px'}}/>
          </div>
          <div className="col-md-2">
          </div>
          <div className="col-md-6 ml-md-5">
          <ul className="list-unstyled">
            <li className="mt-2 mt-md-0">
            <i className="fas fa-check-circle"></i>
            <span className="ml-2">Готвенето на вкусни ястия е Вашата страст и стихия.</span>
            </li>
            <li className="mt-3">
            <i className="fas fa-check-circle"></i>
            <span className="ml-2">Вашата креативност е претворена не само в ястието, но и в неговата визия.</span>
            </li>
            <li className="mt-3">
            <i className="fas fa-check-circle"></i>
            <span className="ml-2">Вие се наслаждавате в процеса на селектиране на продуктите и създаване на менюта.</span>
            </li>
            <li className="mt-3">
            <i className="fas fa-check-circle"></i>
            <span className="ml-2">Вие сте овладели науката на готвенето до най-дребния детейл.</span>
            </li>
            <li className="mt-3">
            <i className="fas fa-check-circle"></i>
            <span className="ml-2">За Вас качеството на готовия продукт е от първостепенно значение.</span>
            </li>
            </ul>
            <div className="mt-3">
            <Link to="/шеф-форма" className="btn btn-danger btn-block">
              <h6 className="font-weight-bold">Превърнете страстта си в бизнес</h6>
            </Link>            
            </div>                    
          </div>                 
        </div>
        
        <div className="row mt-5">
          <div className="col-12 text-center">
          <h5 className="font-weight-bold">Регистрирайте се като потребител, за да създадете своя професионален профил на готвач</h5>
          </div>
          <div className="row mt-5">
            <div className="col-md-8">
              <ol>
              <li>
                <p className="ml-2">
                Регистрирайте се в сайта с Вашите данни.
                </p>
              </li>
              <li>
                <p className="ml-2">
                Създайте своя профил.
                </p>
              </li>
              <li>
                <p className="ml-2">
                Добавете Вашите ястия, менюта и работни оферти.
                </p>
              </li>
              <li>
                <p className="ml-2">
                Разгледайте работните оферти, публикувани от клиенти.
                </p>
              </li>
              <li>
                <p className="ml-2">
                Комуникирайте с Вашите потенциални и настоящи клиенти.
                </p>
              </li>
              </ol>
            </div>
            <div className="col-md-4">
              <img className="img ml-3 ml-md-0" src={steps} alt="steps" style={{width: '320px'}}/>
            </div>
          </div>
        </div>
        <div className="row mt-5">
            <div className="col-md-3">
              <img className="img" src={control} alt="steps" style={{width: '320px', maxHeight: '300px'}}/>
            </div>
            <div className="col-md-2">
            </div>
            <div className="col-md-6 ml-md-5">
            <h5 className="lead mt-2 mt-md-0">Пълен контрол над Вашите оферти</h5>
            <ul className="list-unstyled">
            <li className="mt-3">
            <i className="fas fa-arrow-circle-right"></i>
              <span className="ml-2">
              Вие определяте цената!
              </span>
            </li>
            <li className="mt-3">
            <i className="fas fa-arrow-circle-right"></i>
              <span className="ml-2">
              Вие определяте Вашият работен график!
              </span>
            </li>
            <li className="mt-3">
            <i className="fas fa-arrow-circle-right"></i>
              <span className="ml-2">
              Вие определяте начин на доставка и работните условия!
              </span>
            </li>
            <li className="mt-3">
            <i className="fas fa-arrow-circle-right"></i>
              <span className="ml-2">
              Поръчките се потвърждават след одобрение от Ваша страна!
              </span>
            </li>
            <li className="mt-3">
            <i className="fas fa-arrow-circle-right"></i>
              <span className="ml-2">
              Можете да добявате/изтривате/редактирате Вашите оферти и да променяте Вашата локация!
              </span>
            </li>
            </ul>
          </div>
        </div>        
        </div>)   
    }    
}


export default BecomeChef
