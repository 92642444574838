import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Helmet} from 'react-helmet'
import {Link} from 'react-router-dom'
import {addGig, addImage} from '../actions/gigs'
import {getCuisines} from '../actions/profile'
import Select from 'react-select'
import InputGroup from './common/inputGroup'
import TextAreaGroup from './common/textAreaGroup'
import isEmpty from '../validation/isEmpty'
import classnames from 'classnames'
import Spinner from './common/Spinner'

class PostGig extends Component {

    state = {
        title: '',
        file: null,
        imageSrc: '',
        description: '',
        quantity: 0,
        price: 0,
        cuisines: null,
        preparation: 0,    
        takeaway: false,
        atHome: false,
        delivery: false,
        beGuest: false, 
        loading: false, 
        errors: {}
    }

    componentDidMount() {
    this.props.getCuisines()
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.errors) {
            this.setState({errors: nextProps.errors})
        }
    }
    
    onChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name

    this.setState({
      [name]: value
    });
    }       
   
    onFileChange = (e) => {
        const files = e.target.files
        if(files && files.length > 0) {         
         this.setState({file: files[0]})
        }
    }  

    handleCuisine = (cuisines) => {
        this.setState({ cuisines })         
    }

    validateForm = () => {
        if (!isEmpty(this.state.title)) {
            if(!isEmpty(this.state.description)) {
                if(this.state.quantity !== 0) {
                    if(this.state.price !== 0) {
                        if(this.state.preparation !== 0) {
                            if(!isEmpty(this.state.cuisines)) {
                                return true
                            } else {
                                this.setState({errors: {
                                cuisines: "Моля добавете вид кухня на Вашето ястие!"
                                }})
                            }
                        } else {
                            this.setState({errors: {
                            preparation: "Моля добавете време за приготвяне на Вашето ястие!"
                            }})
                        }
                    } else {
                        this.setState({errors: {
                        price: "Моля добавете цена на Вашето ястие!"
                        }}) 
                    }
                } else {
                    this.setState({errors: {
                    quantity: "Моля добавете количество на Вашето ястие!"
                    }})
                }
            } else {
                this.setState({errors: {
                description: "Моля добавете описание на Вашето ястие!"
                }})
            }
        } else {
            this.setState({errors: {
            title: "Моля добавете заглавие на Вашето ястие!"
            }})
        }    
    }

    onSubmit = async(e) => {
        e.preventDefault()
        
        if(this.validateForm()) {
            this.setState({loading: true})
            const gig = {
            title: this.state.title,            
            description: this.state.description,
            quantity: this.state.quantity,
            price: this.state.price,
            cuisines: this.state.cuisines.map(item => item.value.toLowerCase()),
            preparation: this.state.preparation,    
            takeaway: this.state.takeaway,
            atHome: this.state.atHome,
            delivery: this.state.delivery,
            beGuest: this.state.beGuest,
            }            
           
        if(this.state.file) {
            const file = this.state.file
            const form = new FormData()
            form.append("file", file)
            form.append('upload_preset', "djqmacgo")
            form.append("tags", "chefs")
            await fetch("https://api.cloudinary.com/v1_1/bgchefs/image/upload", {
            method: "POST",
            body: form
            }).then(response => response.json())
            .then(response => {
            const url = response.url
            const token = url.split('/')
            token[0]= "https:"
            token[6] = "w_640,h_320,c_scale"
            const newGig = {...gig, imageUrl: token.join('/')}
            this.props.addGig(newGig, this.props.history)
            })
        .catch(error => console.error('Error:', error))
          } else {
            this.props.addGig(gig, this.props.history)
          } 
        } else {
          return this.state.errors
    }    
    }

    render() {
        const cuisines = this.props.profile.cuisines        
        const errors = this.state.errors

        let cuisineOptions = []
        if (cuisines) {
        cuisines.forEach(cuisine => {
            cuisineOptions.push({
            label: cuisine.name,
            value: cuisine.name
            })  
            return cuisineOptions      
        })
        }

        return (
        <div className="mt-4">
        <Helmet>
        <title>БГШефс създай оферта</title>
        </Helmet>
        <Link to="/моят-шеф-профил" className="btn btn-danger">Назад към профил</Link>
            <div className="row text-center">            
                <h3 className="display-3 offset-md-3">Създайте оферта</h3>
            </div>
            <small className="d-block pb-3">* = задължителни полета</small>
            <form onSubmit={this.onSubmit}>     
                <div className="form-group">
                <InputGroup 
                    name="file"
                    placeholder="Снимка на ястието"                     
                    accept="image/*"
                    icon="fas fa-camera"
                    error={errors.file} 
                    type="file"          
                    onChange={this.onFileChange}
                    info="* Изберете снимка. Снимката е задължителен атрибут на всяко ястие."
                    />
                </div>
                <div className="form-group">
                <InputGroup 
                    name="title"
                    placeholder="Заглавие" 
                    value={this.state.title}
                    error={errors.title} 
                    icon="fab fa-nutritionix"
                    type="text"          
                    onChange={this.onChange}
                    info="* Заглавие на ястието."
                    />
                </div>   
                <TextAreaGroup 
                name="description" 
                placeholder="Описание на ястието"
                value={this.state.description} 
                error={errors.description}
                info="* Добавете кратък текст за ястието"     
                onChange={this.onChange} 
                />
                <div className="form-group">
                    <InputGroup 
                    name="quantity"
                    placeholder="Количество" 
                    value={this.state.quantity} 
                    error={errors.quantity} 
                    icon="fas fa-weight"
                    type="number"          
                    onChange={this.onChange}
                    info="* Количество на 1 порция в грама" />              
                </div>
                <div className="form-group">
                    <InputGroup 
                    name="price"
                    placeholder="Цена" 
                    value={this.state.price} 
                    error={errors.price} 
                    icon="fas fa-dollar-sign"
                    type="number"          
                    onChange={this.onChange}
                    info="* Цена за 1 порция в лева" />              
                </div>
                <div className={classnames("form-control form-control-lg", {
                    "is-invalid": errors.cuisines
                    })}> 
                    <Select
                      value={this.state.cuisines}
                      onChange={this.handleCuisine}
                      options={cuisineOptions}
                      name="cuisines"
                      autoFocuse
                      isMulti
                      placeholder="Вид кухня"
                      className="form-control form-control-lg"                  
                      isSearchable
                    />
                    <small className="form-text text-muted">* Изберете вид кухня, към която спада ястието</small>
                    {errors.cuisines && (<div className="invalid-feedback">{errors.cuisines}</div>)}
                  </div>
                  <div className="form-group mt-3">
                    <InputGroup 
                    name="preparation"
                    placeholder="Време за приготвяне" 
                    value={this.state.preparation} 
                    error={errors.preparation} 
                    icon="fas fa-clock"
                    type="number"          
                    onChange={this.onChange}
                    info="* Време за приготвяне в минути" />              
                </div>
                <div>
                    <p className="lead text-center">Маркирай условия на доставка</p>
                </div>
                <div className="form-check form-check-inline mt-2">
                    <input 
                        className="form-check-input" 
                        type="checkbox" 
                        name="delivery"                          
                        value={!this.state.delivery}
                        onChange={this.onChange}
                    />
                    <label className="form-check-label">Занеси до дома на клиента</label>
                </div>
                <div className="form-check form-check-inline mt-2">
                    <input 
                    className="form-check-input" 
                    type="checkbox" 
                    name="beGuest" 
                    value={!this.state.beGuest}
                    onChange={this.onChange}/>
                    <label className="form-check-label">Сготви у дома на клиента</label>
                </div>
                <div className="form-check form-check-inline mt-2">
                    <input 
                        className="form-check-input" 
                        type="checkbox" 
                        name="takeaway"                          
                        value={!this.state.takeaway}
                        onChange={this.onChange}
                    />
                    <label className="form-check-label">Клиентът взема поръчката</label>
                </div>
                <div className="form-check form-check-inline mt-2">
                    <input 
                    className="form-check-input" 
                    type="checkbox" 
                    name="atHome" 
                    value={!this.state.atHome}
                    onChange={this.onChange}/>
                    <label className="form-check-label">Покани клиента на гости</label>
                </div>
                <button type="submit" className="btn btn-danger btn-block mt-4" disabled={this.state.loading}>
                {this.state.loading && <span><Spinner size="small"/></span>}
                <span>Създай</span>          
                </button>   
            </form>
        </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        profile: state.profile,
        errors: state.errors
    }
}

export default connect(mapStateToProps, 
    {addGig, addImage, getCuisines})(PostGig)
