import React, { Component } from 'react'
import Modal from 'react-modal'
import{Link} from 'react-router-dom'
import CSSTransition from 'react-transition-group/CSSTransition'
import {connect} from 'react-redux'
import {loginUser} from '../../actions/auth'
import isEmail from '../../validation/isEmail'
import isEmpty from '../../validation/isEmpty'
import classnames from 'classnames'

class Login extends Component {  
    state = {
        email: '',
        password: '',        
        errors: {}               
    }      
    
    onChange = e => this.setState({[e.target.name]: e.target.value})
    onClick = () => {
        this.props.onClose()
    }

    validateUser = () => {
        if (!isEmpty(this.state.email)) {
            if (!isEmail(this.state.email)) {
                if (!isEmpty(this.state.password)) {
                    return true
                } else {
                    this.setState({errors: {
                        password: "Моля напишете Вашата парола."
                    }}) 
                }
            } else {
                this.setState({errors: {
                    email: "Моля напишете коректен имейл."
                }})
            }
        } else {
            this.setState({errors: {
                email: "Моля напишете Вашият имейл."
            }})
        }
    }

    onSubmit = (e) => {
        e.preventDefault()   
        if (this.validateUser())  {
            const user = {
                email: this.state.email.toLowerCase(),
                password: this.state.password            
            }
            this.props.loginUser(user)
            this.props.onClose()
        }  else {
            return this.state.errors
        }                         
    }  
    
 
  render() {
    const errors = this.state.errors    
    
    return (
        <CSSTransition
        mountOnEnter
        unmountOnExit
        in={this.props.isOpen}
        timeout={400}
        classNames={{
            enter: '',
            enterActive: 'modalOpen',
            exit: '',
            exitActive: 'modalClosed'
        }}
        >       
        <Modal
        isOpen={!!this.props.isOpen}
        loginUser={this.props.loginUser}
        onRequesClose={this.props.onClose} 
        closeTimeoutMS={200}
        ariaHideApp={false}     
        >          
        <div className="container-fluid">
            <div className="row">
                <div className="col d-flex">
                    <h5 className="modal-title mb-2">Вход</h5> 
                    <button id="closeBtn" 
                        className="btn btn-danger ml-auto"
                        onClick={this.props.onClose}>
                    X</button>
                </div>                    
                </div>
                <form noValidate onSubmit={this.onSubmit}>
                <div className="form-group">
                  <input 
                    type="email" 
                    className={classnames("form-control form-control-sm", {
                        "is-invalid": errors.email
                    })} 
                    placeholder="E-мейл" 
                    name="email"
                    value={this.state.email} 
                    onChange={this.onChange}
                  /> 
                  {errors.email && (<div className="invalid-feedback">{errors.email}</div>)}                 
                </div>
                <div className="form-group">
                  <input 
                    type="password"                     
                    className={classnames("form-control form-control-sm", {
                        "is-invalid": errors.password
                    })} 
                    placeholder="Парола" 
                    name="password" 
                    value={this.state.password}
                    onChange={this.onChange}
                    />  
                    {errors.password && (<div className="invalid-feedback">{errors.password}</div>)}                     
                </div>
                <button className="btn btn-info btn-block mt-4 mb-3">Вход</button>
                </form>
                <Link className="mt-2 offset-4 text-muted" onClick={this.onClick} to="/акаунт">Забравена парола</Link>
            </div> 
            <p className="text-center mt-2">или вход със социален профил</p>            
            <div className="modal-footer">
            <a href="/auth/google" 
            className="btn btn-block" 
            style={{background: "#DD4B39", color: 'white'}}            
            ><i className="fab fa-google-plus-g mr-auto"></i>
                <span className="ml-5">Влез с Google</span>
            </a>
            </div>                                 
                    
      </Modal>  
      </CSSTransition>    
    )
  }
}

const mapDispatchToProps = (dispatch) => ({   
    loginUser: (user) => dispatch(loginUser(user))    
})

export default connect(null, mapDispatchToProps)(Login)
