import React from 'react'
import classnames from 'classnames'

const InputGroup = ({
    name, 
    placeholder, 
    value, 
    error, 
    icon,
    type, 
    info,         
    onChange
    }) => {  
  
    return (
        <div>
        <div className="input-group mb-1"> 
            <div className="input-group-prepend">
                <span className="input-group-text">
                    <i className={icon} />
                </span>
            </div>           
            <input                 
                className={classnames("form-control form-control-lg", {
                "is-invalid": error
                })}  
                placeholder={placeholder} 
                name={name} 
                value={value} 
                onChange={onChange}
                type={type}                
            />  
                    
            {error && (<div className="invalid-feedback">{error}</div>)}
        </div>
        {info && <small className="form-text mt-0 text-muted">{info}</small>}  
        </div>
  )
}

export default InputGroup