import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import {connect} from 'react-redux'
import {getMyProfile, deleteSchedule} from '../actions/profile'
import Spinner from './common/Spinner'


class MySchedules extends Component {

    componentDidMount(){
        if(this.props.auth.user) {
            this.props.getMyProfile(this.props.auth.user._id)
        }    
    }

    onDeleteClick = (id) => {
    this.props.deleteSchedule(id)
    }

  render() {
   
    const {profile, loading} = this.props.profile
    
    let content
    if(!profile || loading) {
      content = <Spinner />
    } else {
      if(Object.keys(profile).length > 0) {
          content = profile.schedule.map(schedule => (
              <div key={schedule._id}>
            <div className="row" >
                <div className="col-4">
                    <p className="text-center">
                    <small className="md-lead">{schedule.day.join(', ')}</small></p>
                </div>
                <div className="col-2 col-md-1">
                    <p>{schedule.from}</p>
                </div>
                <div className="col-2 d-none d-md-block">
                    <p>
                    <small className="md-lead">{schedule.from_am ? "преди обяд" : "следобед"}</small>
                    </p>
                </div>
                <div className="col-2 col-md-1">
                    <p>{schedule.to}</p>
                </div>
                <div className="col-2 d-none d-md-block">
                    <p>
                    <small className="md-lead">{schedule.to_am ? "преди обяд" : "следобед"}</small>
                    </p>
                </div>
                <div className="col-1">
                <button 
                onClick={this.onDeleteClick.bind(this, schedule._id)}
                 className="btn btn-danger">
                X
                </button>
                </div>            
            </div>
            <hr></hr>
            </div>))        
        }
    }
    return (
    <div className="mt-4">
    <Helmet>
    <title>Моят Шеф График БГШефс</title>
    </Helmet>
        <Link to='/моят-шеф-профил' className="btn btn-danger">Назад към профил</Link>
        <div className="row mb-3">
            <h3 className="display-3 offset-md-3">Моят график</h3>
        </div>
        <hr></hr>
       <div className="row">
            <div className="col-4">
                <p className='text-center'>
                    <small className="md-lead">Ден от седмицата</small>
                </p>
            </div>
            <div className="col-2 col-md-1">
                <p>
                    <small className="md-lead">От</small>
                </p>
            </div>
            <div className="col-2 d-none d-md-block">
                <p>
                    <small>Преди/Следобяд</small>
                </p>
            </div>
            <div className="col-2 col-md-1">
                <p>
                    <small className="md-lead">До</small>
                </p>
            </div>
            <div className="col-2 d-none d-md-block">
                <p>
                    <small>Преди/Следобяд</small>
                </p>
            </div>
            <div className="col-1">
                <p>
                    <small className="md-lead">Изтрий</small>
                </p>
            </div>            
        </div>
        <hr></hr>
        {content}
        <div className="row mt-5">
        <Link to='/създай-график' className=" offset-4 btn btn-danger">Създай нов</Link>
        </div>
    </div>
    )
  }
}

const mapStateToProps = state => {
    return {
        profile: state.profile,
        auth: state.auth      
    }
}

export default connect(mapStateToProps, {getMyProfile, deleteSchedule})(MySchedules)

