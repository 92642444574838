import axios from 'axios'
import {setErrors} from './auth'

//create new post
export const addPost = (post, history) => async dispatch => {
    await axios.post('/api/posts', post)
    .then(res => history.push('/обяви'))
    .catch(err => dispatch(setErrors(err.message)))
}

//get all posts per city
export const getPosts = (word, page) => async dispatch => {
    await axios.get(`/api/posts/?city=${word}&page=${page}`)
    .then(res => dispatch({type: "GET_POSTS", payload: res.data}))
    .catch(err => dispatch(setErrors(err.message)))
}

//fetch single post
export const fetchSinglePost = (id) => async dispatch => {
    await axios.get(`/api/posts/${id}`)
    .then(res => dispatch({type: "ADD_POST", payload: res.data}))
    .catch(err => dispatch(setErrors(err.message)))
}

//delete single post
export const removePost = (id, history) => async dispatch => {
    await axios.delete(`/api/posts/${id}`)
    .then(res => history.push('/'))
    .catch(err => dispatch(setErrors(err.message)))
}