import React, { Component } from 'react'
import isEmpty from '../validation/isEmpty'

class ChefSchedule extends Component {
    
  render() {
    const {profile} = this.props 
    return (
      <div id="schedule">
        <div className="row mt-3 mb-3">
                <h6 className="ml-5 lead">Приема поръчки: </h6>            
            </div>            
            {isEmpty(profile.schedule) && (<div className="row">
                <p className="ml-5 text-muted">Графикът не е наличен.</p></div>)}
            {!isEmpty(profile.schedule) && (<div>
                <div className="row m-auto">
                <div className="col-4">
                    <p className='text-center'>
                        <small className="md-lead">Ден от седмицата</small>
                    </p>
                </div>
                <div className="col-2 col-md-1">
                    <p>
                        <small className="md-lead">От</small>
                    </p>
                </div>
                <div className="col-2 d-none d-md-block">
                    <p>
                        <small>Преди/Следобяд</small>
                    </p>
                </div>
                <div className="col-2 col-md-1">
                    <p>
                        <small className="md-lead">До</small>
                    </p>
                </div>
                <div className="col-2 d-none d-md-block">
                    <p>
                        <small>Преди/Следобяд</small>
                    </p>
                </div>
                </div>                
                <hr></hr>
            {profile.schedule.map(schedule => (
                <div key={schedule._id}>
              <div className="row m-auto" >
                  <div className="col-4">
                      <p className="text-center">
                      <small className="md-lead">{schedule.day.join(', ')}</small></p>
                  </div>
                  <div className="col-2 col-md-1">
                      <p>{schedule.from}</p>
                  </div>
                  <div className="col-2 d-none d-md-block">
                      <p>
                      <small className="md-lead">{schedule.from_am ? "преди обяд" : "следобед"}</small>
                      </p>
                  </div>
                  <div className="col-2 col-md-1">
                      <p>{schedule.to}</p>
                  </div>
                  <div className="col-2 d-none d-md-block">
                      <p>
                      <small className="md-lead">{schedule.to_am ? "преди обяд" : "следобед"}</small>
                      </p>
                  </div>                             
                </div>
                <hr></hr>
              </div>))}
             </div>)} 
      </div>
    )
  }
}

export default ChefSchedule
