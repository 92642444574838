import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import { connect } from 'react-redux';
import {TinyPagination} from 'react-pagination-custom'
import {getAllUsers, deleteUserAccount} from '../actions/auth'
import {getProfiles, deleteChefProfile} from '../actions/profile'
import {getReviews, deleteReview} from '../actions/reviews'
import {getPosts, removePost} from '../actions/posts'
import isEmpty from '../validation/isEmpty'
import ProfileRow from './common/ProfileRow'
import UserRow from './common/UserRow'
import ReviewRow from './common/ReviewRow'
import PostRow from './common/PostRow'

class AdminPanel extends Component {
    constructor(props){
        super(props);
        this.state = {
          activePage: 1,
          itemsPerPage: 6,
          word: "София",
        userId: null};
        this.changeProfilePage = this.changeProfilePage.bind(this);
        this.renderProfileNumber = this.renderProfileNumber.bind(this);
        this.changeUserPage = this.changeUserPage.bind(this);
        this.renderUserNumber = this.renderUserNumber.bind(this);
        this.changeReviewPage = this.changeReviewPage.bind(this);
        this.renderReviewNumber = this.renderReviewNumber.bind(this);
        this.changePostPage = this.changePostPage.bind(this);
        this.renderPostNumber = this.renderPostNumber.bind(this);
    }

    componentWillReceiveProps(nextProps, prevState) {
        if(!isEmpty(nextProps.search) && nextProps !== prevState.word) {
            this.setState({word: nextProps.search})
        }
    }

    onChange = (e) => this.setState({[e.target.name]: e.target.value})

    onUserSearch = (e) => {  
        e.preventDefault()   
        if(!isEmpty(this.state.userId))  {
        this.props.getReviews(this.state.userId, 0)
        }  else {
        window.alert("Provide userId first")
        }                 
    }

    onGetReviews = () => {
        if(!isEmpty(this.state.userId)) {
            this.props.getReviews(this.state.userId, 0)
        }
    }

    changeProfilePage = (id) => {   
        this.setState({activePage: id})
        this.props.getProfiles(encodeURIComponent(this.state.word), id - 1)           
    }
    
      profilePageClick(id){
        let {activePage} = this.state
        switch (id) {
          case '<<':
            this.changeProfilePage(activePage - 1);
            break;
          case '>>':
            this.changeProfilePage(activePage + 1);
            break;
          default:
            this.changeProfilePage(id);
            break;
        }
      }
    
      renderProfileNumber(id){
        let {activePage} = this.state
        return(
          <button
            onClick = {this.profilePageClick.bind(this, id)}
            key = {id}
            className={`page ${activePage === id? 'selected-page' : ''}`}
            style={{backgroundColor: "white"}}
          >
            {id}
          </button>
        )
      }
    
      changeUserPage = (id) => {   
        this.setState({activePage: id})
        this.props.getAllUsers(id - 1)           
    }
    
      userPageClick(id){
        let {activePage} = this.state
        switch (id) {
          case '<<':
            this.changeUserPage(activePage - 1);
            break;
          case '>>':
            this.changeUserPage(activePage + 1);
            break;
          default:
            this.changeUserPage(id);
            break;
        }
      }
    
      renderUserNumber(id){
        let {activePage} = this.state
        return(
          <button
            onClick = {this.userPageClick.bind(this, id)}
            key = {id}
            className={`page ${activePage === id? 'selected-page' : ''}`}
            style={{backgroundColor: "white"}}
          >
            {id}
          </button>
        )
      }

      changeReviewPage = (id) => {   
        this.setState({activePage: id})
        this.props.getReviews(this.state.userId, id-1)                  
    }
    
      reviewPageClick(id){
        let {activePage} = this.state
        switch (id) {
          case '<<':
            this.changeReviewPage(activePage - 1);
            break;
          case '>>':
            this.changeReviewPage(activePage + 1);
            break;
          default:
            this.changeReviewPage(id);
            break;
        }
      }
    
      renderReviewNumber(id){
        let {activePage} = this.state
        return(
          <button
            onClick = {this.reviewPageClick.bind(this, id)}
            key = {id}
            className={`page ${activePage === id? 'selected-page' : ''}`}
            style={{backgroundColor: "white"}}
          >
            {id}
          </button>
        )
      }

      changePostPage = (id) => {   
        this.setState({activePage: id})
        this.props.getPosts(encodeURIComponent(this.state.word), id - 1)                  
    }
    
      postPageClick(id){
        let {activePage} = this.state
        switch (id) {
          case '<<':
            this.changePostPage(activePage - 1);
            break;
          case '>>':
            this.changePostPage(activePage + 1);
            break;
          default:
            this.changePostPage(id);
            break;
        }
      }
    
      renderPostNumber(id){
        let {activePage} = this.state
        return(
          <button
            onClick = {this.postPageClick.bind(this, id)}
            key = {id}
            className={`page ${activePage === id? 'selected-page' : ''}`}
            style={{backgroundColor: "white"}}
          >
            {id}
          </button>
        )
      }


    
  render() {
    const {user, users} = this.props.auth
    const profiles = this.props.profiles 
    const {reviews} = this.props.reviews  
    const {posts} = this.props.posts
   
    
    let content
    if (user.isAdmin) {
        content=(<div className="col-12 mt-auto mb-auto ml-3 mr-3">
        <div className="row">
            <div className="col-2 m-auto">
                <nav className="nav flex-column">
                    <h3 className="nav-link ">
                        <button className="btn btn-danger"
                        onClick={() => this.props.getAllUsers(0)}>                            
                        Потребители</button>
                    </h3>
                    <h3 className="nav-link ">
                        <button 
                        className="btn btn-danger" 
                        onClick={() => this.props.getProfiles(encodeURIComponent(this.state.word), 0)}>
                            Профили</button>
                    </h3>
                    <h3 className="nav-link ">
                        <button className="btn btn-danger"
                        onClick={() => this.onGetReviews()}>
                            Ревюта</button>
                    </h3>
                    <h3 className="nav-link ">
                        <button className="btn btn-danger"
                        onClick={() => this.props.getPosts(encodeURIComponent(this.state.word), 0)}>
                            Обяви</button>
                    </h3>
                </nav>
            </div>
            <div className="col-10 m-auto">
                <h1 className="text-center">Welcome Admin!</h1>
                <hr></hr>
                <form onSubmit={this.onUserSearch} className="input-group offset-2 w-50">
                    <input 
                    type="text" 
                    name="userId" 
                    className="form-control" 
                    onChange={this.onChange}
                    placeholder="userId" />
                    <button type="submit" className="btn btn-danger">
                    <i className="fa fa-search"></i>
                    </button>
                </form>
            </div>
            </div>  
            {!isEmpty(users) && users.users.length > 0 && (<div className="mt-auto mb-auto ml-3 mr-3">
            <h6 className="text-center">Number of total users: <strong className="lead">{users.totalUsers}</strong></h6>
            <div className="row mr-2" style={{border: "1px solid black"}}>
            <div className="col-2 d-none d-xl-block">    
            <span>User_id</span>   
            </div>   
            <div className="col-3 text-center">    
            <span>Name</span>
            </div>
            <div className="col-4 col-md-3 ml-1 mr-1 text-center">
            <span>Email</span>
            </div>
            <div className="col-1 d-none d-md-block text-center">    
            <span>isChef</span>   
            </div>
            <div className="col-2 col-xl-1 d-none d-md-block text-center">
            <span>Joined at</span>
            </div>
            <div className="col-lg-1 col-2 ml-md-0 text-center">
            <button className="btn btn-danger"               
              >Del</button>
            </div>
            
            </div>
                <UserRow users={users.users} 
                page={this.state.activePage}
                onDelete={(id, page) => this.props.deleteUserAccount(id, page)}/>
            <div className="pagination justify-content-center mt-2 mb-2" style={{color: "black"}}>
            <TinyPagination
                total = {users.totalUsers}
                selectedPageId = {this.state.activePage}
                itemPerPage = {20}
                renderBtnNumber = {this.renderUserNumber}
                maxBtnNumbers = {5}
                preKey = '<<'
                nextKey = '>>'        
            />
            </div>
            </div>)}
            {!isEmpty(profiles) && profiles.profiles.length > 0 && (<div className="mt-auto mb-auto ml-3 mr-3">
            <h6 className="text-center">Number of total profiles for this city: <strong className="lead">{profiles.totalProfiles}</strong></h6>
            <div className="row mr-2" style={{border: "1px solid black"}}>
            <div className="col-2 d-none d-xl-block">
            <span>Profile_id</span>
            </div>
            <div className="col-1 ml-0 ml-md-3 text-center">
            <span>avgRating</span>
            </div>
            <div className="col-3 col-lg-1 text-center">
            <span>UserName</span>
            </div>
            <div className="col-2 d-none d-xl-block mr-md-2">
            <span>User_id</span>
            </div>
            <div className="col-4 col-lg-1 ml-1 mr-1 text-center">
            <span>Email</span>
            </div>
            <div className="col-1 d-none d-lg-block text-center">
            <span># Offers</span>
            </div>
            <div className="col-1 d-none d-lg-block text-center">
            <span># Reviews</span>
            </div>
            <div className="col-1 d-none d-lg-block text-center">
            <span># Schedules</span>
            </div>
            <div className="col-lg-1 col-3 ml-2 ml-md-0">
            <button className="btn btn-danger">Del</button>
            </div>
            </div>
                <ProfileRow profiles={profiles.profiles} 
                word={this.state.word}
                page={this.state.activePage}
                onDelete={(id, word, page) => this.props.deleteChefProfile(id, word, page)}/>
            <div className="pagination justify-content-center mt-2 mb-2" style={{color: "black"}}>
            <TinyPagination
                total = {profiles.totalProfiles}
                selectedPageId = {this.state.activePage}
                itemPerPage = {this.state.itemsPerPage}
                renderBtnNumber = {this.renderProfileNumber}
                maxBtnNumbers = {5}
                preKey = '<<'
                nextKey = '>>'        
            />
            </div>
        </div>)} 
        {!isEmpty(reviews) && reviews.reviews.length > 0 && (<div className="mt-auto mb-auto ml-3 mr-3">
            <h6 className="text-center">Number of total reviews: <strong className="lead">{reviews.totalReviews}</strong></h6>
            <div className="row mr-2" style={{border: "1px solid black"}}>
            <div className="col-2 d-none d-xl-block">    
            <span>Chef_id</span>   
            </div>   
            <div className="col-2 d-none d-md-block text-center">    
            <span>Name</span>
            </div>
            <div className="col-md-1 col-3 text-center">    
            <span>Rating</span>   
            </div>
            <div className="col-md-1 col-3 text-center">    
            <span>Title</span>   
            </div>
            <div className="col-3 d-none d-md-block text-center">    
            <span>Description</span>   
            </div>
            <div className="col-xl-1 d-none d-xl-block text-center">
            <span>Created</span>
            </div>
            <div className="col-1 ml-md-0 text-center">
            <button className="btn btn-danger"               
              >Del</button>
            </div>
            
            </div>
                <ReviewRow reviews={reviews.reviews} 
                page={this.state.activePage}
                onDelete={(id, chef) => this.props.deleteReview(id, chef)}/>
            <div className="pagination justify-content-center mt-2 mb-2" style={{color: "black"}}>
            <TinyPagination
                total = {reviews.totalReviews}
                selectedPageId = {this.state.activePage}
                itemPerPage = {20}
                renderBtnNumber = {this.renderReviewNumber}
                maxBtnNumbers = {5}
                preKey = '<<'
                nextKey = '>>'        
            />
            </div>
            </div>)}  
            {!isEmpty(posts) && posts.posts.length > 0 && (<div className="mt-auto mb-auto ml-3 mr-3">
            <h6 className="text-center">Number of total posts: 
            <strong className="lead">{posts.totalPosts}</strong> for {this.state.word}</h6>
            <div className="row mr-2" style={{border: "1px solid black"}}>
            <div className="col-2 d-none d-xl-block">    
            <span>Email</span>   
            </div>   
            <div className="col-2 d-none d-md-block text-center">    
            <span>Name</span>
            </div>            
            <div className="col-md-1 col-3 text-center">    
            <span>Title</span>   
            </div>
            <div className="col-3 d-none d-md-block text-center">    
            <span>Text</span>   
            </div>
            <div className="col-xl-1 d-none d-xl-block text-center">
            <span>Created</span>
            </div>
            <div className="col-1 ml-md-0 text-center">
            <button className="btn btn-danger"               
              >Del</button>
            </div>
            
            </div>
                <PostRow posts={posts.posts}
                page={this.state.activePage}
                history={this.props.history}
                onDelete={(id, history) => this.props.removePost(id, history)}/>
            <div className="pagination justify-content-center mt-2 mb-2" style={{color: "black"}}>
            <TinyPagination
                total = {posts.totalPosts}
                selectedPageId = {this.state.activePage}
                itemPerPage = {20}
                renderBtnNumber = {this.renderPostNumber}
                maxBtnNumbers = {5}
                preKey = '<<'
                nextKey = '>>'        
            />
            </div>
            </div>)}                      
        </div>)
    } else {
        content=(<div className="col-10 text-center m-auto">
        <h3>Вие нямате администраторски права. Моля, върнете се към <br></br>
        <Link className="text-danger lead font-weight-bold" to="/">Начало</Link></h3>
        </div>
        )
    }

    return (
      <div className="row mt-3">
        {content}
      </div>
    )
  }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        search: state.search,
        profiles: state.profile.profiles,
        posts: state.posts,
        reviews: state.reviews
    }
}

export default connect(mapStateToProps, 
    {getProfiles, deleteChefProfile, getAllUsers, deleteUserAccount,
    getReviews, deleteReview, getPosts, removePost})(AdminPanel)
