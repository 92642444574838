const initialState = {
    post: null,
    posts: [],    
    loading: false
  }
  
  export default (state = initialState, action) => {
      switch(action.type) {
        case "POSTS_LOADING": {
          return{
          ...state,
          loading: true
          }
        } 
        case "ADD_POST": {
          return {
            ...state,
            post: action.payload,
            loading: false
          }
        } 
        case "REMOVE_POSTS": {
          return {
            ...state,
            posts: state.posts.filter(post => post._id !== action.payload),
            loading: false
          }
        }        
        case "GET_POSTS": {
          return {
            ...state,
            posts: action.payload,
            loading: false
          }
        }
        default: {
          return state;
        }
      }
    }