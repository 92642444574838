import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Helmet} from 'react-helmet'
import {Link} from 'react-router-dom'
import moment from 'moment'
import numeral from 'numeral'
import {TinyPagination} from 'react-pagination-custom'
import Spinner from './common/Spinner'
import {getMyMessages, updateMessage} from '../actions/messages'
import isEmpty from '../validation/isEmpty'

 class Messages extends Component {
    constructor(props){
        super(props);
        this.state = {
          activePage: 1,
          itemsPerPage: 10};
        this.changePage = this.changePage.bind(this);
        this.renderBtnNumber = this.renderBtnNumber.bind(this);
      }
    componentDidMount() {
        if(this.props.auth.user._id) {
            this.props.getMyMessages(this.props.auth.user._id)
        }        
    }

    onChange = (id) => {
        this.props.updateMessage(id)
    }

    changePage = (id) => {   
        this.setState({activePage: id})
    }
    
    buttonPageClick(id){
        let {activePage} = this.state
        switch (id) {
          case '<<':
            this.changePage(activePage - 1);
            break;
          case '>>':
            this.changePage(activePage + 1);
            break;
          default:
            this.changePage(id);
            break;
        }
      }
    
      renderBtnNumber(id){
        let {activePage} = this.state
        return(
          <button
            onClick = {this.buttonPageClick.bind(this, id)}
            key = {id}
            className={`page ${activePage === id? 'selected-page' : ''}`}
            style={{backgroundColor: "white"}}
          >
            {id}
          </button>
        )
      }

  render() {
    const {messages, loading} = this.props.messages
    
    return (
      <div className="row mt-4">
      <Helmet>
      <title>БГШефс Съобщения</title>
      </Helmet>
        {!isEmpty(messages) && loading && <div className="col-10 m-auto">
            <Spinner />
        </div>}
        {!isEmpty(messages) && !loading && <div className="col-12 m-auto">
            <hr></hr>
            {messages
                .filter(message => message.sender !== null)
                .reverse()
                .map(message => (
                <div key={message._id} className="row">
                {!isEmpty(message.sender.image) && <div className="col-2">
                    <Link to={`/съобщение/${message.order._id}`}>
                    <img src={message.sender.image} className="img rounded-circle"
                style={{width:' 3rem'}} alt="userimage" />
                    </Link>                
                </div>}
                <div className="col-md-3 d-none d-md-block">
                <p className="lead mb-0">{message.sender.name}</p>    
                <p>Дата {moment(message.created).format('D/M H:MM')}</p>            
                </div>
                <div className="col-md-5 d-none d-md-block">
                {!message.isRead ? <Link to={`/съобщение/${message.order._id}`}                 
                onClick={() => this.onChange(message._id)}
                className="font-weight-bold"
                style={{textDecoration: 'none'}}>
                <p  style={{color: 'black'}}>
                {message.text}</p>
                </Link> 
                : <Link to={`/съобщение/${message.order._id}`}              
                style={{textDecoration: 'none'}}>
                <p  style={{color: 'black'}}>
                {message.text}</p>
                </Link>}
                </div>
                <div className="d-none d-md-block col-md-2">
                <p className="mb-0">Поръчка: {message.order.status}</p>
                <p>Сума: {numeral(message.order.amount).format('0.00')} лв</p>
                </div>
                <div className="d-md-none d-block col-7">
                <p>{message.sender.name}</p> 
                {!message.isRead ? <Link to={`/съобщение/${message.order._id}`}                 
                onClick={() => this.onChange(message._id)}
                className="font-weight-bold"
                style={{textDecoration: 'none'}}>
                <p  style={{color: 'black'}}>
                {message.text}</p>
                </Link> 
                : <Link to={`/съобщение/${message.order._id}`}              
                style={{textDecoration: 'none'}}>
                <p  style={{color: 'black'}}>
                {message.text}</p>
                </Link>}
                </div>
                <div className="d-md-none d-block col-3">
                <p>Дата {moment(message.created).format('D/M H:MM')}</p>
                </div>
                <div className="col-12">
                <hr></hr>
                </div>                
            </div>
            ))}
            <div className="pagination justify-content-center mt-2">
                <TinyPagination
                total = {messages.length}
                selectedPageId = {this.state.activePage}
                itemPerPage = {this.state.itemsPerPage}
                renderBtnNumber = {this.renderBtnNumber}
                maxBtnNumbers = {5}
                preKey = '<<'
                nextKey = '>>'        
                />
                </div>
        </div>}
        {isEmpty(messages) && !loading && <div className="col-10 mt-5 mb-5 m-auto">
        <h3 className="text-center lead">Вие все още нямате съобщения. Необходимо е да направите поръчка, за да можете да пращате и получавате съобщения.</h3>    
        </div>}
      </div>
    )
  }
}

const mapStateToProps = state => {
    return {
        messages: state.messages,
        auth: state.auth
    }
}

export default connect(mapStateToProps, {getMyMessages, updateMessage})(Messages)
