const initialState = {
    order: {},
    foods: [],
    orders: [],    
    loading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case "ORDER_LOADING":
        return {
            ...state,
            loading: true
        }
        case "ADD_ORDER":
        return {
            ...state,
            orders: [action.payload, 
                ...state.orders]         
        }
        case "ADD_FOOD":        
        return {
            ...state,
            foods: [action.payload, ...state.foods]   
        } 
        case "REMOVE_FOOD":
        let item = state.foods.findIndex(food => food.gigTitle === action.payload)      
        return {
            ...state,   
            foods: state.foods.filter((food, index) => index !== item)      
        } 
        case "CLEAR_FOODS":
        return {
            ...state,   
            foods: []      
        }   
        case "GET_ORDER":
        return {
            ...state,
            order: action.payload,
            loading: false     
        }        
        case "GET_ORDERS":
        return {
            ...state,
            orders: action.payload,
            loading: false
        } 
        case "CLEAR_ORDERS":
        return {
            ...state,   
            orders: []      
        }               
        default:
        return state
    }
}