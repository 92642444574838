import axios from 'axios'
import setAuthToken from '../utils/setAuthToken'
import jwt_decode from 'jwt-decode'
import Cookies from 'js-cookie'

//dispatching errors
export const setErrors = (errors) => ({
    type: "GET_ERRORS",
    errors
})

//clearing errors
export const clearErrors = () => ({
    type: 'CLEAR_ERRORS'
})

//set logged in user
export const setCurrentUser = (user) => ({
    type: "SET_CURRENT_USER",
    user
})

//Social Registered user
export const fetchUser = () => {
    return (dispatch) => {
        const token = Cookies.get('auth')         
        if(token) {
            localStorage.setItem('jwtToken', token) 
            setAuthToken(token)
            const decodedUser = jwt_decode(token)
            const user = decodedUser.user
            dispatch(setCurrentUser(user))
            dispatch(clearErrors())
            Cookies.remove('auth');
        } else if (localStorage.jwtToken) {
            const decodedUser = jwt_decode(localStorage.jwtToken)
            const user = decodedUser.user
            dispatch(setCurrentUser(user))
        } else {
            dispatch(setCurrentUser({}))
        }
    }
}

//Register User
export const registerUser = (userData) => {
    return (dispatch) => {
        dispatch(clearErrors())
        axios.post('/api/users/register', userData)
            .then(res => {
                //Save to localStorage
                const {token} = res.data
                //Set the token to localStorage
                localStorage.setItem('jwtToken', token)
                //Set token to Auth Header
                setAuthToken(token)
                //Decode token to get user data
                const decodedUser = jwt_decode(token)
                const user = decodedUser.user
                //set current user
                dispatch(setCurrentUser(user))
            }).catch(err => {                
            dispatch(setErrors(err.message))
        })             
    }    
}

//Login - Get User Token
export const loginUser = (userData) => {
    return (dispatch) => {
        dispatch(clearErrors())
        axios.post('/api/users/login', userData)
            .then(res => {
                //Save to localStorage
                const {token} = res.data
                //Set the token to localStorage
                localStorage.setItem('jwtToken', token)
                //Set token to Auth Header
                setAuthToken(token)
                //Decode token to get user data
                const decodedUser = jwt_decode(token)
                const user = decodedUser.user
                //set current user
                dispatch(setCurrentUser(user))                
            }).catch(err => {
            dispatch(setErrors(err.message))
        })
    }
}

//Update User info
export const updateUser = (userData) => {
    return (dispatch) => {
        axios.post('/api/users/update', userData)
        .then(res => {
            //Save to localStorage
            const {token} = res.data
            //Set the token to localStorage
            localStorage.setItem('jwtToken', token)
            //Set token to Auth Header
            setAuthToken(token)
            //Decode token to get user data
            const decodedUser = jwt_decode(token)
            const user = decodedUser.user
            //set current user
            dispatch(setCurrentUser(user))                
        }).catch(err => {
            dispatch(setErrors(err.message))
        })
    }
}

//change Password
export const changePassword = (userData, history) => {
    return (dispatch) => {
        axios.post('/api/users/data', userData)
        .then(res => {
            const {token} = res.data
            //Set the token to localStorage
            localStorage.setItem('jwtToken', token)
            //Set token to Auth Header
            setAuthToken(token)
            //Decode token to get user data
            const decodedUser = jwt_decode(token)
            const user = decodedUser.user
            //set current user
            dispatch(setCurrentUser(user))
            dispatch(clearErrors())
            history.push('/')
        }).catch(err => {
            dispatch(setErrors(err.message))
        })
    }
}

//Log user out
export const logoutUser = () => {
    return (dispatch) => {
        //Clear the token in the local storage
        localStorage.removeItem('jwtToken')
        //Remove and header for future requests
        setAuthToken(false)
        //set current user to {} which will set isAuthenticated to fasle
        dispatch(setCurrentUser({}))
    }
}

//Get all users only admin route
export const getAllUsers = (page) => {
    return (dispatch) => {
        axios.get(`/api/users/?page=${page}`)
        .then(res => dispatch({type: "GET_USERS", payload: res.data}))
        .catch(err => dispatch(setErrors(err.message)))
    }
}

//delete user account
export const deleteUser = (id) => {
    return (dispatch) => {
        if(window.confirm("Сигурен ли сте, че искате да изтриете профила си?")) {
            axios.delete(`/api/users/${id}`)
            .then(dispatch(logoutUser()))
            .catch(err => {
                dispatch(setErrors(err.message))
            })
        }
    }
}

//Delete the user by admin with the user._id
export const deleteUserAccount = (id, page) => {
    return (dispatch) => {
        axios.delete(`/api/users/${id}`)
        .then(res => dispatch(getAllUsers(page)))
        .catch(err => {
            dispatch(setErrors(err.message))
        })
    }
}