import axios from 'axios'
import {setErrors} from './auth'
import isEmpty from '../validation/isEmpty'

//get the full list of favorites for the user
export const getFavorites = () => async dispatch => {
    dispatch({type: "FAVORITES_LOADING"})
    await axios.get('/api/favorites')
    .then(res => {
        if(!isEmpty(res.data.favorites)) {
        dispatch({type: "GET_FAVORITES", payload: res.data.favorites})
        } else {
        dispatch({type: "REMOVE_LOADING"})        
        }
    })       
    .catch(err => setErrors(err.message))
}

export const addFavorite = (chefId) => async dispatch => {
    await axios.post('/api/favorites', chefId)    
    .then(res => dispatch({type: "GET_FAVORITES", payload: res.data.favorites}))
    .catch(err => setErrors(err.message))
}

export const removeFavorite = (chefId) => async dispatch => {
    await axios.delete(`/api/favorites/${chefId}`)    
    .then(res => dispatch({type: "REMOVE_FAVORITE", payload: chefId}))
    .catch(err => dispatch(setErrors(err.message)))
}