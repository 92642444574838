import React from 'react'
import Moment from 'moment'

export default function UserRow({posts, onDelete, history}) {
  return (
   posts.map(post => (<div className="row mr-2" key={post._id} style={{border: "1px solid black"}}>
    <div className="col-2 d-none d-xl-block">    
        <span>{post.owner ? post.owner.email : null}</span>   
    </div>   
    <div className="col-2 d-none d-md-block text-center">    
        <span>{post.owner ? post.owner.name: null}</span>
    </div>
    <div className="col-md-1 col-3 text-center">    
        <span>{post.title}</span>   
    </div>
    <div className="col-3 d-none d-md-block text-center">    
        <span>{post.text}</span>   
    </div>
    <div className="col-xl-1 d-none d-xl-block text-center">
        <span>{Moment(post.created).format("DD/MM/YYYY")}</span>
    </div>
    <div className="col-lg-1 col-2 ml-md-0 text-center">
    <button className="btn btn-danger" 
      onClick={() => onDelete(post._id, history)}
      >X</button>
    </div>
    </div>
  ))
  )
}