import React from 'react'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./common/carousel.css"
import isEmpty from '../validation/isEmpty'

const OffersOverview = ({profile}) => {
  return (
    <div id="offers">
      <div className="row mt-3">        
          <p className="ml-5 mr-5 lead">Кухня: {profile.cuisines ? profile.cuisines.join('/ ') : null}</p>
      </div>                 
      <div className="m-auto">
      {!isEmpty(profile.offers) && (<Carousel>
          {profile.offers.map(offer => (
              <div key={offer._id}>
                <img src={offer.imageUrl} alt="foodimages"/>
                <p className="legend">{offer.title}</p>
              </div>
          ))}             
          </Carousel>)}            
      </div>          
    </div>
  )
}


export default OffersOverview
