import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Helmet} from 'react-helmet'
import {changePassword} from '../actions/auth'
import isEmpty from '../validation/isEmpty'
import isEmail from '../validation/isEmail'
import classnames from 'classnames'

class LostPassword extends Component {
    state = {
        email: '',
        password: '',        
        errors: {},
        message: null
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.errors) {
            this.setState({message: "Потребителят не може да бъде намерен. Моля, опитайте отново."})
        }
    }

    onChange = e => this.setState({[e.target.name]: e.target.value})
    
    validateForm = () => {
        if(!isEmpty(this.state.email) || !isEmail(this.state.email)) {
            if(!isEmpty(this.state.password)) {
                return true
            } else {
                this.setState({
                   errors: {password: "Моля, напишете парола."}
                })  
            }
        } else {
            this.setState({
                errors: {email: "Моля, напишете е-мейлът, с който сте регистриран."}
            })
        }
    }
    
    onSubmit = (e) => {
        e.preventDefault()
        if(this.validateForm()) {
            const userData = {
                email: this.state.email.toLowerCase(),
                password: this.state.password
            }
            console.log(userData)
            this.props.changePassword(userData, this.props.history)
        } else {
            return this.state.errors
        }
    }

    render() {
      const errors = this.state.errors

    return (
        <div className="mt-4">
        <Helmet>
        <title>БГШефс моя акаунт</title>
        </Helmet>
            <div className="row">
                <div className="col-md-9 m-auto">
                {this.state.message && (<h5 style={{color: 'red'}}>{this.state.message}</h5>)}
                <form className="form-group" onSubmit={this.onSubmit}>                
                <div className="form-group">
                  <input 
                    type="email" 
                    className={classnames("form-control form-control-sm", {
                        "is-invalid": errors.email
                    })} 
                    placeholder="E-мейл" 
                    name="email"
                    value={this.state.email} 
                    onChange={this.onChange}
                  /> 
                  {errors.email && (<div className="invalid-feedback">{errors.email}</div>)}                 
                </div>
                <div className="form-group">
                  <input 
                    type="password"                     
                    className={classnames("form-control form-control-sm", {
                        "is-invalid": errors.password
                    })} 
                    placeholder="Парола" 
                    name="password" 
                    value={this.state.password}
                    onChange={this.onChange}
                    />  
                    {errors.password && (<div className="invalid-feedback">{errors.password}</div>)}                     
                </div>
                <button className="btn btn-danger btn-block mt-4 mb-3">Промени</button>
                </form>
                </div>
            </div>
        </div>
    )
  }
}

export default connect(null, {changePassword})(LostPassword)
