import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import numeral from 'numeral'
import {getMyProfile} from '../../actions/profile'
import isEmpty from '../../validation/isEmpty'
import InputGroup from './inputGroup'
import {createOrder} from '../../actions/orders'

class MakeOrder extends Component {
    state= {        
        day: '',
        hour: '',       
        delivery: '',
        schedule: null
    }

    componentDidMount() {        
        this.props.getMyProfile(this.props.id)
    }
    
    onChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name
    
        this.setState({
          [name]: value
        });
      }

      handleDay = (day) => {
        this.setState({ day: day.value, 
        schedule: this.getHour(this.props.profile.profile.schedule, day.value)})         
      }

      handleDelivery = (delivery) => {
        this.setState({ delivery: delivery.value})       
      }
  
    
    sumTotal = (foods, prop) => {
        return foods.reduce( (a, b) => {
            return a + b[prop]
        }, 0)
    }

    getHour = (schedule, name) => {
        return schedule.filter(function(item){
            return item
                   .day
                   .some(function(d){ return d === name})
          })        
    }   

    onSubmit=(e) => {
        e.preventDefault()        
        const newGigs = this.props.order.foods
        .filter((food, index, self) => index === self.findIndex((f) => f.gigId === food.gigId))
        .map((f) => ({gig: f.gigId, quantity: this.props.order.foods.filter(food => food.gigId === f.gigId).length}))        
        const amount = this.sumTotal(this.props.order.foods, 'gigPrice')
        const seller = this.props.profile.profile._id        
        const newOrder = {
            gigs: newGigs,
            amount,
            seller,
            status: "нова",
            day: this.state.day,
            hour: this.state.hour,  
            delivery: this.state.delivery          
        }
        this.props.createOrder(newOrder, this.props.history)
    }

  render() {
    const {profile} = this.props.profile
    const {foods} = this.props.order   
    
    const weekOptions= [
            {label: "Понеделник", value: "Понеделник"},
            {label: "Вторник", value: "Вторник"},
            {label: "Сряда", value: "Сряда"},
            {label: "Четвъртък", value: "Четвъртък"},
            {label: "Петък", value: "Петък"},
            {label: "Събота", value: "Събота"},
            {label: "Неделя", value: "Неделя"},
        ]
    
        const deliveryOptions= [
            {label: "В моя дом", value: "В моя дом"},
            {label: "Вземи сам", value: "Вземи сам"},
            {label: "Доставка от готвача", value: "Доставка от готвача"},
            {label: "В дома на готвача", value: "В дома на готвача"},            
        ]

      let graphic     
      if(!isEmpty(profile) && !isEmpty(profile.schedule)) {
        graphic=(<div className="row mt-3 ml-3">        
        <div className="col-12">
            <Select
            value={this.state.day}
            onChange={this.handleDay}
            options={weekOptions}
            name="day"
            autoFocuse            
            placeholder={!isEmpty(this.state.day) ? this.state.day : "ден от седмицата"}
            className="form-control"            
            />
        </div>
        <div className="col-12 mt-3">
        <InputGroup 
            name="hour"
            placeholder={numeral(this.state.hour).format('00:00:00')} 
            value={this.state.hour}              
            icon="fas fa-clock"
            type="text"                      
            onChange={this.onChange}
            info = {!isEmpty(this.state.schedule) ? `Изберете час между ${this.state.schedule[0].from} и ${this.state.schedule[0].to}` : `Изберете ден от седмицата`}
        />
        </div>
        </div>)
      }

    return (
    <div>      
        {graphic}  
        <div className="col-12">
        <hr></hr>
        </div>          
        {!isEmpty(foods) &&<div className="row ml-3">
            
            <div className="col-5">
                <span>Ястие</span>
            </div>            
            <div className="col-3">
                <span>Брой</span>
            </div>
            <div className="col-4">
                <span>Цена</span>
            </div>
            
            <div className="col-12">
            <hr></hr> 
            </div>                      
        </div>} 
         
        {!isEmpty(foods) && foods
            .filter((food, index, self) => index === self.findIndex((f) => f.gigTitle === food.gigTitle))
            .map(f => 
            <div className="row mt-3 ml-3" key={f.gigId}>
            <div className="col-5">
                <span>{f.gigTitle}</span>
            </div>
            <div className="col-3">            
                <span>{foods.filter(food => food.gigTitle === f.gigTitle).length} бр.</span>
            </div>
            <div className="col-4">
                <span>{numeral(f.gigPrice).format('0.00')} лв</span> 
            </div>            
        </div>)}
        {!isEmpty(foods) && <div className="row mt-3 ml-3">
            <div className="col-12">
                <hr></hr> 
            </div>              
            <div className="col-6">
                <span>
                Обща сума</span>
            </div>
            <div className="col-1"></div>
            <div className="col-4">
                <span>
                {numeral(this.sumTotal(foods, 'gigPrice')).format('0.00')} лв
                </span>
            </div>         
        </div> } 
        {!isEmpty(foods) && <div className="row ml-3 mt-3">
            <div className="col-12">
                <Select
                value={this.state.delivery}
                onChange={this.handleDelivery}
                options={deliveryOptions}
                name="delivery"
                autoFocuse            
                placeholder={!isEmpty(this.state.delivery) ? this.state.delivery : "избери доставка"}
                className="form-control"            
                />
            </div>
            </div>}       
        {!isEmpty(foods) && <div className="row mt-3 ml-3">
             <div className="col-12">
            <button className="btn btn-block btn-danger"
            type="submit"
            onClick={this.onSubmit}>
                Поръчай
            </button> 
            </div>     
        </div> }
    </div>
    )
  }
}

const mapStateToProps = state => {
    return {
        profile: state.profile,
        auth: state.auth,
        order: state.order
    }
}

export default connect(mapStateToProps, {getMyProfile, createOrder})(MakeOrder)
