import React, { Component } from 'react'
import {connect} from 'react-redux'
import {getPosts} from '../actions/posts'
import {Helmet} from 'react-helmet'
import isEmpty from '../validation/isEmpty'
import {TinyPagination} from 'react-pagination-custom'
import Spinner from './common/Spinner'
import PostItem from './common/PostItem'

 class Posts extends Component {
  constructor(props){
    super(props);
    this.state = {
      activePage: 1,
      itemsPerPage: 10,
      word: "Варна"};
    this.changePage = this.changePage.bind(this);
    this.renderBtnNumber = this.renderBtnNumber.bind(this);
  }
     
    componentDidMount() {      
        if(!isEmpty(this.props.search)) {
        this.props.getPosts(encodeURIComponent(this.props.search), this.state.activePage - 1)       
        } else {
          this.props.getPosts(encodeURIComponent(this.state.word), this.state.activePage - 1)       
        }   
      }

    componentWillReceiveProps(nextProps) {
        if(!isEmpty(nextProps.search)) {
          this.props.getPosts(encodeURIComponent(nextProps.search), this.state.activePage - 1)
        }
    }
    
    changePage = (id) => {   
        this.setState({activePage: id})
        if(!isEmpty(this.props.search)){
          this.props.getPosts(encodeURIComponent(this.props.search), id - 1) 
        } else {
          this.props.getPosts(encodeURIComponent(this.state.word), id - 1) 
        }    
      }
    
      buttonPageClick(id){
        let {activePage} = this.state
        switch (id) {
          case '<<':
            this.changePage(activePage - 1);
            break;
          case '>>':
            this.changePage(activePage + 1);
            break;
          default:
            this.changePage(id);
            break;
        }
      }
    
      renderBtnNumber(id){
        let {activePage} = this.state
        return(
          <button
            onClick = {this.buttonPageClick.bind(this, id)}
            key = {id}
            className={`page ${activePage === id? 'selected-page' : ''}`}
            style={{backgroundColor: "white"}}
          >
            {id}
          </button>
        )
      }
    
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

  render() {
      const {posts, loading} = this.props.posts
          
      let content = null
    if(isEmpty(posts) && loading) {
        content = <div className="col-10 m-auto"><Spinner/>
        </div>
    } else if(!isEmpty(posts) && !loading) {
        content = <div className="col-12 m-auto">
        {!isEmpty(posts.posts) && posts.posts.length > 0
            ? <div>
            <h3 className="display-4 text-center">Обяви за град {this.capitalizeFirstLetter(posts.posts[0].city)}</h3>
            <div className="row mt-3">
             <PostItem posts={posts.posts} />                               
            </div>
            </div>
            : <div className="col-12 m-auto">
            <h3 className="display-4 text-center">Няма намерени обяви за Вашият град</h3>
            </div>}
            <hr className="mb-3"></hr>
            <div className="pagination justify-content-center mt-2">
            <TinyPagination
            total = {posts.posts.length}
            selectedPageId = {this.state.activePage}
            itemPerPage = {this.state.itemsPerPage}
            renderBtnNumber = {this.renderBtnNumber}
            maxBtnNumbers = {5}
            preKey = '<<'
            nextKey = '>>'        
            />
            </div>
        </div>

    } 
    
    return (
      <div className="row mt-4">
      <Helmet>
      <title>Обяви БГШефс</title>
      </Helmet>
        {content}        
      </div>
    )
  }
}

const mapStateToProps = state => {
    return {
        search: state.search,
        posts: state.posts
    }
}

export default connect(mapStateToProps, {getPosts})(Posts)
