import axios from 'axios'
import {setErrors} from './auth'

//send new message
export const addMessage = (content) => async dispatch => {
    await axios.post('/api/messages/', content)
    .then(res => dispatch({type: "ADD_MESSAGE", payload: res.data}))
    .catch(err => dispatch(setErrors(err.message)))
}

//update read status to message
export const updateMessage = (id) => async dispatch => {
    dispatch({type: "CLEAR_MESSAGES"})
    await axios.post(`/api/messages/${id}`)
    .then(res => dispatch({type: "ADD_MESSAGE", payload: res.data}))
    .catch(err => dispatch(setErrors(err.message)))
}

//get all messages per order._id
export const getOrderMessages = (id) => async dispatch => {
    await axios.get(`/api/messages/orders/${id}`)
    .then(res => dispatch({type: "GET_MESSAGES", payload: res.data}))
    .catch(err => dispatch(setErrors(err.message)))
}

//get all messages per sender._id
export const getMyMessages = (id) => async dispatch => {
    dispatch({type: "MESSAGE_LOADING"})
    await axios.get(`/api/messages/recipient/${id}`)
    .then(res => dispatch({type: "GET_MESSAGES", payload: res.data}))
    .catch(err => dispatch(setErrors(err.message)))
}
