import React from 'react'
import numeral from 'numeral'
import isEmpty from '../../validation/isEmpty'

export default function offersGroup({gig, index, addGig, removeGig}) {
  return (
    <div className="row" key={index}>                
        <div className="col-4 col-md-5 mt-2">
            {!isEmpty(gig.imageUrl) && <img src={gig.imageUrl} alt="foodimage" className="img-thumbnail" />}
        </div>
        <div className="col-8 col-md-7 mt-2">
            <h5 className="display-md-4 font-weight-bold">{gig.title}</h5>
            <p>Вид кухня: {gig.cuisines.length > 1 ? gig.cuisines.join(', ') : gig.cuisines}<br></br>
                    Описание: {gig.description}<br></br>
                    Време за подготовка в минути: {gig.preparation}<br></br>
                    Количество: {gig.quantity} грама<br></br>
                    Условия на доставка:
                        {gig.takeaway && <li><small className="mr-2 mb-2">Клиентът взема поръчката</small></li> }
                        {gig.beGuest && <li><small className="mr-2 mb-2">Сготви у дома на клиента</small></li>}
                        {gig.atHome && <li><small className="mr-2 mb-2">Покани клиента на гости</small></li>}
                        {gig.delivery && <li><small className="mr-2 mb-2">Занеси до дома на клиента</small> </li>}
            </p>                     
                    
            <div className="row offset-md-2">
                <div className="col-6">
                    <span>Цена в лева: {numeral(gig.price).format('0.00')}</span> 
                </div>
                <div className="col-1">
                    <button
                        onClick={() => addGig(gig)}
                        className="btn rounded-circle btn-danger"
                        style={{fontWeight: "bold"}}
                        ><i className="fas fa-plus"></i>
                    </button>
                </div>
                <div className="col-1 ml-3">
                    <button
                        onClick={() => removeGig(gig)}
                        className="btn rounded-circle btn-danger"
                        style={{fontWeight: "bold"}}
                        ><i className="fas fa-minus"></i>
                    </button>
                </div>
            </div>
        </div> 
        <div className="col-12">
        <hr></hr>
        </div>                   
    </div>                   
                    
  )
}
