import React, { Component } from 'react'
import { connect } from 'react-redux';
import {Helmet} from 'react-helmet'
import isEmpty from '../validation/isEmpty'
import {addPost} from '../actions/posts'
import {getCities} from '../actions/profile'
import TextAreaGroup from './common/textAreaGroup'
import TextFieldGroup from './common/textFieldGroup'
import Select from 'react-select'
import classnames from 'classnames'

 class CreatePost extends Component {

    state = {
        city: null,
        owner: '',
        title: '',
        text: '',
        errors: {}
    }

    componentDidMount() {
        this.props.getCities()        
      }

      onChange = e => this.setState({[e.target.name]: e.target.value})

      handleCity = (city) => {
        this.setState({ city })
      }

      validateForm = () => {
          if (!isEmpty(this.state.title)) {
            if(!isEmpty(this.state.city)) {
                if(!isEmpty(this.state.text)) {
                    return true
                } else {
                    this.setState({errors: {
                        text: "Моля, добавете текст на обявата."
                    }})
                }
            } else {
                this.setState({errors: {
                    city: "Моля, изберете град, за който се отнася обявата."
                }})
            }
          } else {
              this.setState({errors: {
                  title: "Моля, добавете заглавие на обявата."
              }})
          }
      }

    onSubmit = (e) => {
        e.preventDefault()
        if(this.validateForm()) {
            const newPost = {
                owner: this.props.auth.user._id,
                title: this.state.title,
                text: this.state.text,
                city: this.state.city[0].value.toLowerCase()        
            }
            this.props.addPost(newPost, this.props.history)
        } else {
            return this.state.errors
        }
    }

    render() {
        const {cities} = this.props.profile
        const errors = this.state.errors

        let cityOptions = []
        if (cities) {
        cities.forEach(city => {
            cityOptions.push({
            label: city.name,
            value: city.name
            })  
            return cityOptions      
        })
        }

    return (
      <div className="row mt-4">   
      <Helmet>
      <title>Създай Обява в БГШефс</title>
      </Helmet>   
        <div className="col-md-10 m-auto">
            <h3 className="display-4 text-center">Създайте обява</h3> 
            <small className="d-block pb-3">* = задължителни полета</small>
            <form onSubmit={this.onSubmit}> 
            <TextFieldGroup
                name="title"
                placeholder={this.state.title}
                value={this.state.title}
                error={this.state.errors.title}
                info="* Моля добавете заглавие на Вашата обява"
                type="text"
                onChange={this.onChange}                        
                /> 
            <div className="form-group"> 
                <div className={classnames("form-control form-control-lg", {
                "is-invalid": errors.city
                })}> 
                    <Select 
                    placeholder="Град"
                    name="city"
                    value={this.state.city}
                    onChange={this.handleCity}
                    options={cityOptions} 
                    autoFocuse
                    isSearchable
                    isMulti                   
                    />
                    <small className="form-text text-muted">* Изберете Град</small>
                {errors.city && (<div className="invalid-feedback">{errors.city}</div>)}
                </div>
            </div>
            <TextAreaGroup
                name="text"
                placeholder={this.state.text}
                value={this.state.text}
                error={this.state.errors.text}
                info="* Моля, добавете текст на Вашата обява"
                onChange={this.onChange}                        
                /> 
            <input 
                type="submit" 
                value="Създай" 
                className="btn btn-danger btn-block mt-4"
                />  
            </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
    return {
      auth: state.auth,
      profile: state.profile
    }
}

export default connect(mapStateToProps, {addPost, getCities})(CreatePost)
