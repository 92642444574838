import React, { Component } from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import Helmet from 'react-helmet'
import numeral from 'numeral'
import {getSingleClientOrder, updateOrder, deleteOrder} from '../actions/orders'
import Spinner from './common/Spinner'
import isEmpty from '../validation/isEmpty'

class SingleClientOrder extends Component {

    componentDidMount() {
        if(this.props.match.params.id)
    this.props.getSingleClientOrder(this.props.match.params.id)
    }

    onDelete = (id) => {
        this.props.deleteOrder(id, this.props.history)
    }

  render() {
    const {order, loading} = this.props.order

    let content
      if(isEmpty(order) && loading) {
        content=(<div className="col-10 m-auto"><Spinner/></div>)
      } else if(!isEmpty(order)) {
        content=(<div className="col-12 m-auto">
        <Link to="/поръчки" className="btn btn-danger">Назад към поръчки</Link>
                <h6 className="text-center display-4 mt-3">Поръчка от шеф {!isEmpty(order.seller.user) && order.seller.user.name}</h6> 
                <img className="img rounded-circle offset-5" src={order.seller.imageUrl} alt="chefimage" style={{width: '5rem'}}/>
                <hr></hr>                
                {order.gigs.map(gig => <div className="row mt-2" key={gig._id}>
                    <div className="col-4">
                        <img className="img-thumbnail" alt="foodimage" src={gig.gig.imageUrl}/>
                    </div>
                    <div className="col-8">
                        <h6>{gig.gig.title}</h6>
                        <p>Кухня: {!isEmpty(gig.gig.cuisines) && gig.gig.cuisines.join (', ')}</p>
                        <p>Описание: {gig.gig.description}</p>
                        <div className="row">
                            <div className="col-5">
                                <p>Количество в гр: {gig.gig.quantity}</p>
                            </div>                        
                            <div className="col-3">
                                <p>Брой: {gig.quantity}</p>
                            </div>
                            <div className="col-4">
                                <p>Цена: {numeral(gig.gig.price).format('0.00')} лв</p>
                            </div>
                        </div>
                    </div>
                <div className="col-12">
                <hr></hr>
                </div>
            </div>)}
             <div className="row">
                <div className="col-md-6 ml-3">
                    <p className="lead">Условия на доставка:</p>
                    <hr></hr>
                    <p>Ден: <small>{order.day}</small></p>
                    <p>Час: <small>{order.hour}</small></p>
                    <p>Начин: <small>{order.delivery}</small></p>
                </div>
                <div className="col-md-5 ml-3">
                    <p className="lead">Обща сума</p>
                    <hr></hr>
                    <p style={{fontWeight: 'bold'}}>{numeral(order.amount).format('0.00')} лв</p>
                </div>                
             </div> 
            <div className="row">
                {order.status !== "потвърдена" && <div className="offset-3 offset-md-7">
                    <button onClick={() => this.onDelete(order._id)} className="btn btn-danger btn-block">
                    Изтрий поръчка
                    </button>
                </div>}
            </div>       
        </div>
        )
    }
    return (
      <div className="row mt-4">
      <Helmet>
      <title>БГШефс Поръчки</title>
      </Helmet>
        {content}
      </div>
    )
  }
}

const mapStateToProps = state => {
    return {
        order: state.order
    }
}

export default connect(mapStateToProps, 
    {getSingleClientOrder, updateOrder, deleteOrder})(SingleClientOrder)
