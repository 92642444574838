import React from 'react'
import {Helmet} from 'react-helmet'

export default function PrivacyPolicy() {
  return (
    <div className="row mt-4">
    <Helmet>
    <title>БГШефс Политика за поверителност</title>
    </Helmet>
    <div className="col-12 m-auto">
    <h5 className="lead font-weight-bold"><u>Политика на Поверителност</u></h5>
    <br></br>
    <p className="lead">Ефективна от дата: 01/11/2018</p>
    <p className="mt-3 font-weight-bold text-justify">BGChefs.com е уеб-платформа за търсене и наемане на услуги от топ готвачи, сладкари и хлебари в България. Тази платформа предоставя възможност на кулинарните експерти да публикуват своите майсторски умения и талант, да обявяват своите условия на труд и да бъдат наемани от частни лица и фирми.
    </p>
    <br></br>
    <p className="text-justify"><strong>BGChefs.com</strong> е загрижен за запазване на вашата поверителност и е длъжен да защитава вашите лични данни. Основната цел на нашата Политика на Поверителност е да ви помогне да разберете как сайтът събира, използва и съхранява информацията, която вие предоставяте и да ви съдейства да вземете информирани решения относно ползването на услугите на BGChefs.com.
      </p>
      <p className="text-justify">С приемането на нашата Политика на Поверителност, вие давате съгласие на BGChefs.com да събира, съхранява, използва и предоставя вашите персонални данни, както е описано в тази Политика на Поверителност.
      </p>
      <br></br>
      <h6 className="lead font-weight-bold"><u>Промени в Политиката на Поверителност на BGChefs.com
      </u></h6>
      <br></br>
      <p className="text-justify">Нашата Политика на Поверителност може да подлежи на промяна през определен период от време. Ние няма да ограничим вашите права по отношение на тази Политика на Поверителност без вашето съгласие в съответствие със текущото законодателство. Ние ще публикуваме всяка промяна в Политиката на Поверителност на тази страница и в случай на значителни промени, ще предоставим на вашето внимание важно съобщение (включително за определени услуги, ще ви изпратим е-мейл нотификация за промените). Датата в началото на тази страница обозначава датата, на която Политиката на Поверителност е била променяна. Тази Политика обхваща цялата информация, която имаме за вас и за вашият акаунт с нас.
      </p>
      <br></br>
      <p className="text-justify">Ако решим да променим тази Политика, ние ще публикуваме тези промени в текста на настоящата Политика на Поверителност и на други подходящи за целта места, така че да бъдете наясно с информацията, която събираме, как я използваме и при какви обстоятелства можем да я обявим публично.
      </p>
      <p className="text-justify">Ако направим материални промени по тази Политика, ние ще ви уведомим на нашият уебсайт, по е-мейл или чрез съобщение при вход в сайта преди промените да станат ефективни.
      </p>
      <br></br>
      <h6 className="lead font-weight-bold"><u>Поверителност
      </u></h6>
      <br></br>
      <p className="text-justify">Ние разбираме важността на вашата поверителност и вашето право на контрол върху това как се събират, съхраняват и използват вашите лични данни. 
      В случаите, в които не сме получили вашето пълно съгласие относно друго, ние ще събираме и използваме вашите лични данни, както е описано по-долу.
      </p>
      <br></br>
      <h6 className="lead font-weight-bold"><u>Какъв тип информация събираме?
      </u></h6>
      <br></br>
      <ol>
      <li className="font-weight-bold">Данни, осигурени чрез директни взаимодействия.
      </li>
      <p className="font-weight-bold">Регистрация и друга информация относно профила на потребителя
      </p>
      <p className="text-justify">Когато се регистрирате, за да използвате нашите Услуги ние можем да събираме следната информация за Вас:
      </p>
      <ul>
        <li className="text-justify">Ако се регистрирате като използвате Вашият Google профил: Вашето име, фамилия и имейл;</li>
        <li className="text-justify">Ако се регистрирате като използвате Вашият Facebook профил: събираме Вашето име и фамилия, такива, каквито са видими във Facebook профила Ви. В случай, че сте предоставили разрешение на Facebook чрез опцията за поверителност в приложението (която се показва точно преди да се регистрирате на нашата Платформа), ние можем да събираме информация относно Вашия пол, години, имейл адрес, в зависимост от предоставените от Вас разрешения; 
        </li>          
      </ul>
      <p className="text-justify">В зависимост от избора, който сте направили при влизане в нашите Услуги или по време на процеса на участие в нашите Услуги, Вие можете да изберете да предоставите  някои от следните допълнителни лични данни:
      </p>
      <ul>
        <li>Вашето име
        </li>
        <li>Е-мейл адрес</li>
        <li>Мобилен телефон
        </li>
      </ul>
      <p className="text-justify"><strong>Комуникация чрез функцията за чат на нашата Платформа
      </strong></p>
      <p className="text-justify">Когато използвате функцията за чат, за да комуникирате с други потребители на Платформата, ние събираме тази информация, която Вие избирате да предоставите на другите потребители чрез тази функция.
      </p>
      <li className="font-weight-bold">Информация, която събираме автоматично, когато използвате нашите Услуги
      </li>
      <p className="text-justify">Когато осъществявате достъп до нашата Платформа или използвате нашите Услуги ние автоматично събираме следната информация за Вас:
      </p>
      <p className="text-justify mb-0"><strong>Данни за потребител и данни за вписване
      </strong></p>
      <ul><li className="text-justify mt-0">Ще запазим данните ви за вход в Платформата (дата на регистрация, дата на последната промяна на паролата, дата на последното успешно влизане), типа и версията на браузъра ви. 
      </li></ul>
      <p className="text-justify mb-0"><strong>Данни, генерирани от потребителите при преглеждането на уеб страници
      </strong></p>
      <ul><li className="text-justify mt-0">Ние събираме информация за Вашата дейност на нашата Платформа, която включва сайтовете, от които достъпвате до нашата Платформа, дата и час на всяко посещение, извършени от вас търсения и реда, в който посещавате съдържанието на нашата Платформа. 
      </li></ul>
      <p className="text-justify mb-0"><strong>Бисквитки и подобни технологии
      </strong></p>
      <ul><li className="text-justify mt-0">Ние можем да използваме бисквитки (“cookies”) и други идентификационни технологии с цел съхраняване на данни и за да  управляваме сесиите на потребителите, и да съхраняваме подбора на предпочитанията ви на сайта. "Бисквитките" са малки текстови файлове, прехвърлени от уеб сървър на твърдия диск на Вашето устройство. Бисквитките могат да се използват за събиране на датата и часа на посещението на уебсайта, историята на сърфирането в интернет, Вашите предпочитания и потребителското Ви име. Можете да настроите браузъра си да откаже всички или някои "бисквитки" или да ви предупреждава, когато уеб-сайтовете, които посещавате  задават или имат достъп до "бисквитки". Моля, имайте предвид, че в случай на  деактивиране или отказ от  "бисквитките", някои части от нашите услуги / Платформа може да станат недостъпни или да не функционират правилно. 
      </li></ul>
      <li className="font-weight-bold">Данни от трети страни или от публично достъпни източници
      </li>
      <p className="text-justify">Ние получаваме Ваши лични данни от различни трети страни и публични източници, както е посочено по-долу: 
      </p>
      <p className="text-justify">i. Конкретни технически данни и информация за използването  на Услугите от доставчици на анализи като Google Аnalyitics, Facebook, Google и други подобни;</p>
      <p className="text-justify">ii. Данни, събирани от прочит на бисквитки и други подобни;</p>
      <p className="text-justify">iii.  Публично достъпна, специализирана информация от доставчици на информация за търсене като публични онлайн каталози и директории;
      iv. Данни от профили в социални мрежи, когато осъществявате чрез тези  профили достъп до нашите услуги в Платформата.
      </p>
      <li className="font-weight-bold">Не събираме лични данни, които: 
      </li>
      <ul>
        <li className="text-justify">разкриват расов или етнически произход;</li>
        <li className="text-justify">разкриват политически, религиозни или философски убеждения;
        </li>
        <li className="text-justify">разкриват членство в политически партии или организации, сдружения с религиозни, философски, политически или синдикални цели;
        </li>
        <li className="text-justify">се отнасят до здравето, сексуалния живот или до човешкия геном;
        </li>
      </ul>
      </ol>
      <br></br>
        <h6 className="lead font-weight-bold"><u>Събираме ли информация от деца?</u></h6>
        <br></br>
        <p className="text-justify">Нашите Услуги не са предназначени за деца под 18 години и не събираме съзнателно лични данни на  лица под 18-годишна възраст. В случай, че узнаем, че лице под 18 години ни е предоставило лични данни ще ги изтрием незабавно.
        </p>
        <br></br>
        <h6 className="lead font-weight-bold"><u>Защо обработваме Ваша лична информация?</u></h6>
        <br></br>
        <ol>
        <li className="text-justify">Ще използваме Вашата информация и лични данни само тогава, когато законодателството ни позволява. Най-често ще използваме Вашите лични данни при следните обстоятелства:
        </li>
          <ul>
            <li className="text-justify">Когато е нужно за защита на Ваши законни интереси да подобрим нашите услуги и да Ви осигурим безопасна и сигурна Платформа.
            </li>
            <li className="text-justify">Когато сме задължени да спазим законово или регулаторно задължение.</li>
          </ul>
        <p className="text-justify">При определени обстоятелства, може да обработваме вашите лични данни въз основа на Вашето съгласие. Ако го направим, ще ви уведомим за целта и категорията на личните данни, които ще бъдат обработени в момента, в който искаме вашето съгласие. По-долу излагаме описание на начините, по които използваме вашите лични данни и кои са правните основания, на коитo се позоваваме, за да го направим. Също така идентифицираме какви са нашите законни интереси. 
        </p>
        <li className="text-justify">За осигуряване на достъп на потребителите  и предоставяне на Услуги чрез нашата Платформа.
        </li>
          <ul>
            <li className="text-justify">Ако използвате за достъп е-мейл адрес, ние използваме Вашето име, фамилия и имейл адрес, за да ви идентифицираме като потребител и да осигурим достъп до нашата Платформа.
            </li>
            <li className="text-justify">Ако използвате профила си във Facebook за достъп, използваме Вашето име и фамилия от Facebook профила Ви и имейл адреса, който сте използвали във Facebook, за да ви идентифицираме като потребител на нашата Платформа и да ви предоставим достъп до нея.
            </li>
            <li className="text-justify">Горепосочените регистрационни данни се използват  от нас, за да предоставим нашите Услуги в съответствие с нашите Общи условия.</li>
          </ul>
        <p className="text-justify">Ние обработваме горепосочената информация въз основа на нашия законен интерес, за да подобрим Вашия потребителски опит в Платформата и да изпълним напълно съвместния си договор. 
        </p>
        <li className="text-justify">За да ви осигурим безопасна и сигурна Платформа. </li>
        <p className="text-justify">Анализираме комуникациите ви, извършени чрез функцията ни за чат за предотвратяване на измами и за насърчаване на безопасността чрез блокиране на спам или обидни съобщения, които може да са ви били изпратени от всеки друг потребител.
        </p>
        <p className="text-justify">Ние обработваме горепосочената информация за пълното и точно изпълнение на съвместния ни договор, за подобряване на нашите Услуги и на базата на нашия законен интерес, за предотвратяване на измами.
        </p>
        </ol>
        <br></br>
        <h6 className="lead font-weight-bold"><u>Вашите права </u></h6>
        <br></br>
        <p className="text-justify">При определени обстоятелства, имате права съгласно приложимите закони за защита на личните данни и във връзка с вашите лични данни.</p>
        <p className="text-justify">Ако желаете да упражните някое от изброените по-долу права, можете да се свържете с нас чрез  контактната форма на сайта.</p>
        <p className="text-justify"><strong>Право да поискате  достъп</strong> до личните си данни (обикновено наричани "искане за достъп на субекта на данните"). Това ви дава възможност да получите копие от личните си данни, които съхраняваме и да проверите дали са законно  обработвани.
        </p>
        <p className="text-justify"><strong>Право да поискате коригиране</strong> на личните Ви данни, които имаме за вас. Това ви дава възможност  да коригирате всички непълни или неточни данни, които имаме за вас. Въпреки това  може да се наложи да проверим точността на новите данни, които ни предоставяте.</p>
        <p className="text-justify"><strong>Право да поискате ограничаване на обработката</strong> на личните Ви данни. Това ви дава възможност  да се свържете с нас с искане за преустановяване на  обработката на личните Ви данни в следните хипотези: </p>
        <ul>
        <li className="text-justify">ако искате да докажем точността на данните;</li>
        <li className="text-justify">когато смятате, че  използването на данните от нас е незаконно; </li>
        <li className="text-justify">когато изисквате от нас да съхраняваме данните, дори ако вече нямаме нужда от това, тъй като Ви е нужно да установите, за  да упражнявате или да докажете Ваши претенции; или </li>
        <li className="text-justify">когато сте се противопоставили на използването на Вашите данни, но трябва да проверим дали притежаваме законови основания да ги използваме.</li>
        </ul>         
        <p className="text-justify"><strong>Право да поискате заличаване</strong> на личните Ви данни. Това право Ви дава възможност да се свържете с нас с искане за  изтриване или премахване на  личните Ви данни, когато няма основателна причина, за да продължим да ги обработваме. Освен това, имате право да поискате да изтрием или премахнем вашите лични данни, когато имате съмнение, че  е възможно да сме обработили вашата информация не в съответствие със законовите изисквания  или от когато се изисква да изтрием личните Ви данни, за да бъдем в съответствие със законовите изисквания.</p>
        <br></br>
        <p className="text-justify font-weight-bold">Право да оттегляте вашето съгласие за обработването на личните Ви данни имате по всяко време. Това не засяга законосъобразността на обработката, която вече сме извършили на основание  предварително дадено Ваше съгласие.  
        </p>
        <p className="text-justify">Нямате задължение за плащане на  такса за достъп до личните Ви данни (или за упражняване на някое от другите Ви права). 
        </p>
        <h6 className="lead font-weight-bold"><u>Причини за споделяне на вашите данни</u></h6>
      <p className="text-justify">(i)Ние можем да споделим вашите лични данни в случай, че имаме основателни причини да вярваме, че разкриването на вашите лични данни е необходимо за създаването, упражняването или защитата на правни искове, независимо дали в съдебни производства или в административна или извънсъдебна процедура. В тези случаи, не сме задължени да информираме потребителите за разкриването на техните лични данни на публичните органи.</p>
      <p className="text-justify">(ii)Можем да продаваме, прехвърляме или обединяваме части от нашия бизнес или от нашите активи. Има вероятност  да придобием други фирми или да се слеем с тях. Ако настъпят тези промени в нашата дейност, новите собственици могат да използват личните Ви данни по същия начин, както е описан в настоящата декларация за поверителност.</p>
      <p className="text-justify"><strong>BGChefs.com</strong> не продава, търгува или отдава под наем предоставената от Вас лична информация, в каквато и да е форма с цел директен маркетинг.
      </p>
      <p className="text-justify">Ние можем да споделяме информация, когато е необходимо с трети страни - доставчици на услуги. Използваме доставчици на услуги от трети страни за предоставянето на определени части от нашите Услуги, като например устройства за съхранение в “cloud”. Доставчиците на услуги може да се намират в или извън ЕИП.  </p>
      <p className="text-justify">Не позволяваме на доставчиците на услуги от трети страни  да използват Вашите лични данни за собствени цели и единствено  им позволяваме да обработват Вашите лични данни само за определени цели и в съответствие с нашите указания.</p>
      <br></br>
      <h6 className="lead font-weight-bold"><u>Как BGChefs.com съхранява информация? </u></h6>
      <br></br>
      <p className="text-justify">Ние използваме мерки за сигурност, които защитават срещу загуба или злоупотреби с информацията, под наш контрол, въпреки че данните в Интернет пространството не могат да бъдат напълно подсигурени срещу кибератаки. 
      Вашите лични данни са криптирани при процеса на трансмисия. В допълнение, записите, които ние съхраняваме се поддържат и архивират на сигурни и защитени сървъри, хоствани в центрове с високо ниво на защита на достъп.
      </p>
      <p className="text-justify">Достъпът до данни е защитен с многостепенни слоеве на контрол, включително  защитни стени, различни нива на административен достъп, механизъм за автентикация и мониторинг.  Данните се архивират, като архивите се съхраняват на сигурно място.  Вие имате достъп само до съобщенията, в които Вашият профил е ангажиран. </p>
      <br></br>
      <h6 className="lead font-weight-bold">Допълнителна информация относно вход през мобилни устройства</h6>
      <p className="text-justify">В допълнение към останалите условия на тази политика, в случай че използвате нашата мобилна версия или използвате нашия мобилно-оптимизиран уебсайт, ние може да получим информация за Вашето мобилно устройство, включително и до идентификационния код на Вашето устройство.</p>
      <br></br>
      <h6 className="lead font-weight-bold"><u>Публично достъпна информация </u></h6>
      <p className="text-justify">Информацията, която публикувате като обява или оферта чрез услугите на <strong>BGChefs.com</strong> остава видима за други потребители на Платформата. Това може да включва Вашето име, фамилия, имейл адрес, местоположение и номер за контакт. Моля, имайте предвид, че всяка информация, която предоставяте на други потребители, винаги може да бъде споделена от тях с трети лица, така че моля, направете това по своя преценка и на свой риск. </p>
      <br></br>
      <h6 className="lead font-weight-bold"><u>Освобождаване от отговорност </u></h6>
      <p className="text-justify">Ние си запазваме правото да споделяме вашите лични данни според изискванията на законодателството и когато имаме основание да вярваме, че споделянето е необходимо за да защити нашите права в случай на правно производство, съдебни или други правни процеси във връзка с нашата платформа.</p>
      <p className="text-justify">Ние ще предоставим вашите лични данни на трети страни само в случаите, изрично упоменати в нашата Политика на Поверителност. Ние няма да търгуваме с вашите лични данни. </p>
      <br></br>
      <h6 className="lead font-weight-bold"><u>Разни</u></h6>
      <br></br>
      <p className="text-justify">Ние препоръчваме да преглеждате нашата Политика на Поверителност периодично, тъй като могат да настъпят някои промени в бъдеще.  Използването на нашата платформа от Ваша страна, както е приложимо, представлява вашето съгласие за новата политика за поверителност в пълната степен, разрешена от закона.</p>
      <p className="text-justify">Ние не носим отговорност и не осъществяваме контрол върху практиките за поверителност на нито един от нашите клиенти и трети страни. Ние насърчаваме да се запознаете с Политиката на Поверителност на всички други страни и партньори. </p>
      <h6 className="lead font-weight-bold"><u>Как да се свържете с нас?</u></h6>
      <br></br>
      <p className="text-justify mb-0">В случай на въпрос или притеснения от Ваша страна относно сигурността на нашата Платформа или нашата Политика на Поверителност, моля не се колебайте да се свържете с нас на е-мейл: contact@bgchefs.com или на адрес:</p>
      <p className="text-justify mt-0 mb-0">гр. Варна</p>
      <p className="text-justify mt-0">ул. Професор Н. Державин 4</p>
      </div>   
    </div>
  )
}
