import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import numeral from 'numeral'
import {addSchedule} from '../actions/profile'
import Select from 'react-select'
import InputGroup from './common/inputGroup'
import isEmpty from '../validation/isEmpty'
import classnames from 'classnames'

 class PostSchedule extends Component {

    state= {
      from: '', 
      to: '',
      from_am: false,
      from_pm: false,
      to_am: false,
      to_pm: false,
      day: '',
      errors: {} 
    }

    onChange = (event) => {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name
  
      this.setState({
        [name]: value
      });
    }
    
    handleDay = (day) => {
      this.setState({ day })         
    }

    validateForm = () => {
      if (!isEmpty(this.state.from)) {
        if (!isEmpty(this.state.to)) {
          if(!isEmpty(this.state.day)) {
            if(this.state.from_am || this.state.from_pm) {
              if(this.state.to_am || this.state.to_pm) {
                return true
              } else {
                this.setState({
                  errors: {to_am: "Моля, маркирайте преди обяд или следобед", 
                  to_pm: "Моля, маркирайте преди обяд или следобед"}
                })
              }
            } else {
              this.setState({
                errors: {from_am: "Моля, маркирайте преди обяд или следобед", 
                from_pm: "Моля, маркирайте преди обяд или следобед"}
              })
            }
          } else {
            this.setState({
              errors: {day: "Моля, изберете ден от седмицата"}
            })
          }
        } else {
          this.setState({
            errors: {to: "Моля, отбележете час на приключване"}
          })
        }
      } else {
        this.setState({
          errors: {from: "Моля, отбележете час на започване"}
        })
      }
    }

      onSubmit = (e) => {
        e.preventDefault()
        if(this.validateForm()) {
          const schedule = {
            day: this.state.day.map(item => item.value),
            from: this.state.from,
            to: this.state.to,
            from_am: this.state.from_am,
            from_pm: this.state.from_pm,
            to_am: this.state.to_am,
            to_pm: this.state.to_pm
          }
          this.props.addSchedule(schedule, this.props.history)
        }  else {
          return this.state.errors
        }      
      }

  render() {
    const errors = this.state.errors
    const weekOptions= [
      {label: "Понеделник", value: "Понеделник"},
      {label: "Вторник", value: "Вторник"},
      {label: "Сряда", value: "Сряда"},
      {label: "Четвъртък", value: "Четвъртък"},
      {label: "Петък", value: "Петък"},
      {label: "Събота", value: "Събота"},
      {label: "Неделя", value: "Неделя"},
    ]


    return (
      <div className="mt-4">
      <Helmet>
      <title>БГШефс създай график</title>
      </Helmet>
        <Link to="/моят-шеф-профил" className="btn btn-danger">Назад към профил</Link>
        <div className="row">
            <h3 className="display-3 offset-md-3">Създай график</h3>
        </div>          
          <small className="d-block pb-3">* = задължителни полета</small>
        
            <form onSubmit={this.onSubmit}>
            <div className={classnames("form-control", {
              "is-invalid": errors.day
              })}> 
              <Select
                value={this.state.day}
                onChange={this.handleDay}
                options={weekOptions}
                name="day"
                autoFocuse
                isMulti
                placeholder="ден от седмицата"
                className="form-control form-control-lg"                  
                isSearchable
              />
              <small className="form-text text-muted">* Изберете ден от седмицата</small>
              {errors.day && (<div className="invalid-feedback">{errors.day}</div>)}
            </div>   
            <div className="row">  
              <div className="col-6">
                <div className="form-group mt-3">
                <InputGroup 
                  name="from"
                  placeholder={numeral(this.state.from).format('00:00:00')} 
                  value={this.state.from} 
                  error={errors.from} 
                  icon="fas fa-clock"
                  type="text"                      
                  onChange={this.onChange}
                  info="* Добавете час, от който можете да обработвате поръчки - 09:30"
                />
                </div>
              </div>
                <div className="col-6">
                  <div className={classnames("form-check form-check-inline mt-3", {
                    "is-invalid": errors.from_am
                    })}>
                    <input
                      className="form-check-input" 
                      type="radio"
                      name="from_am"                    
                      value={!this.state.from_am}                   
                      onChange={this.onChange}
                    />
                    <label className="form-check-label">Преди обяд</label>
                    {errors.from_am && (<div className="invalid-feedback">{errors.from_am}</div>)}
                  </div>        
                  
                  <div className={classnames("form-check form-check-inline mt-3", {
                    "is-invalid": errors.from_pm
                    })}>
                      <input 
                      className="form-check-input" 
                      type="radio" 
                      name="from_pm" 
                      value={!this.state.from_pm}
                      onChange={this.onChange}/>
                      <label className="form-check-label">Следобяд</label>
                      {errors.from_pm && (<div className="invalid-feedback">{errors.from_pm}</div>)}
                  </div>
                </div>
                </div>
                <div className="row">  
                <div className="col-6">
                  <div className="form-group mt-3">
                  <InputGroup 
                    name="to"
                    placeholder={numeral(this.state.to).format('00:00:00')} 
                    value={this.state.to} 
                    error={errors.to} 
                    icon="fas fa-clock"
                    type="text"                      
                    onChange={this.onChange}
                    info="* Добавете час, до който можете да обработвате поръчки - 18:20"
                  />
                  </div>
                </div>
                  <div className="col-6">
                    <div className={classnames("form-check form-check-inline mt-3", {
                      "is-invalid": errors.to_am
                      })}>
                      <input
                        className="form-check-input" 
                        type="radio"
                        name="to_am"                    
                        value={!this.state.to_am}                   
                        onChange={this.onChange}
                      />
                      <label className="form-check-label">Преди обяд</label>
                      {errors.to_am && (<div className="invalid-feedback">{errors.to_am}</div>)}
                    </div>        
                    
                    <div className={classnames("form-check form-check-inline mt-3", {
                      "is-invalid": errors.to_pm
                      })}>
                        <input 
                        className="form-check-input" 
                        type="radio" 
                        name="to_pm" 
                        value={!this.state.to_pm}
                        onChange={this.onChange}/>
                        <label className="form-check-label">Следобяд</label>
                        {errors.to_pm && (<div className="invalid-feedback">{errors.to_pm}</div>)}
                    </div>
                  </div>
                </div>
                <input 
                type="submit" 
                value="Създай" 
                className="btn btn-danger btn-block mt-4"
                />            
            </form>
          
        
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    profile: state.profile
  }
}

export default connect(mapStateToProps, {addSchedule})(PostSchedule)
