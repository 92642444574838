import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import authReducer from '../reducers/authReducer'
import errorReducer from '../reducers/errorReducer'
import profileReducer from '../reducers/profileReducer'
import gigsReducer from '../reducers/gigsReducer'
import searchReducer from '../reducers/searchReducer'
import orderReducer from '../reducers/orderReducer';
import messageReducer from '../reducers/messageReducer';
import favoritesReducer from '../reducers/favoritesReducer';
import postReducer from '../reducers/postReducer';
import reviewsReducer from '../reducers/reviewsReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default () => {
    const store = createStore(
        combineReducers({
         auth: authReducer, 
         profile: profileReducer, 
         gigs: gigsReducer,
         order: orderReducer,
         search: searchReducer, 
         messages: messageReducer,  
         favorites: favoritesReducer,   
         posts: postReducer,
         reviews: reviewsReducer,
         errors: errorReducer 
        }),        
    composeEnhancers(applyMiddleware(thunk))
    )
    return store
}