import React from 'react'
import {Link} from 'react-router-dom'
import StarRatingComponent from 'react-star-rating-component';
import isEmpty from '../../validation/isEmpty'
import chefhat from '../../images/chefhat.jpg'

export default function ChefCard({chefs}) {
  return (
    chefs.map(chef => (
        <div className="col-md-6 col-lg-4 mr-auto ml-auto mt-3 mb-3" key={chef._id}>
          <div className="card m-auto" style={{width: '18rem'}}>
            <Link to={`/шеф-профил/${chef._id}`}>
            <img 
              className="card-img-top" 
              src={chef.imageUrl ? chef.imageUrl : chefhat} 
              style={{height: "20rem"}} 
              alt="chefimage"/></Link>
            <div className="card-body" style={{height: "20rem", borderTop: "1px solid darkGrey"}}>
          <Link to={`/шеф-профил/${chef._id}`} style={{textDecoration: "none"}}>
            <h5 className="card-title lead text-center" 
            style={{fontWeight: "bold", color: "black"}}>
            {chef.user.name}</h5>
          </Link>
            <div className="card-title lead text-center mb-0">
              <StarRatingComponent 
              name="rating" 
              starColor="#dc3545"
              editing={false}
              renderStarIcon={() => <span><i className="fas fa-star"></i></span>}
              starCount={5}
              value={chef.avgRating}
              />
              </div>   
              <div className="row mt-0 mb-0">                 
                    <p className="col-6 card-body mt-0 mb-0">Ревюта: {chef.reviews.length}</p>                  
                    <p className="col-6 card-body mt-0 mb-0">Оферти: {chef.offers.length}</p>                                
                </div>
                <div className="row mt-0 pb-2">              
                  <ul className="col-6 mt-0">Категория:
                    <li>{chef.categories[0]}</li>
                    {!isEmpty(chef.categories[1]) && (<li>{chef.categories[1]}</li>)}
                    {!isEmpty(chef.categories[2]) && (<li>{chef.categories[2]}</li>)}
                  </ul>
                  <ul className="col-6 mt-0">Кухня:
                    <li>{chef.cuisines[0]}</li>
                    {!isEmpty(chef.cuisines[1]) && (<li>{chef.cuisines[1]}</li>)}
                    {!isEmpty(chef.cuisines[2]) && (<li>{chef.cuisines[2]}</li>)}
                  </ul>
                </div>                  
            </div>         
          </div>
        </div>
    ))
  )

}
