const initialState = {
    profile: null,
    profiles: null,
    cities: null,
    categories: null,
    cuisines: null,
    loading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case "PROFILE_LOADING":
        return {
            ...state,
            loading: true
        }
        case "GET_PROFILE":
        return {
            ...state,
            profile: action.payload,
            loading: false
        }   
        case "CLEAR_CURRENT_PROFILE":
        return {
            ...state,
            profile: null,
            loading: false
        }  
        case 'GET_PROFILES':
        return {
            ...state,
            profiles: action.payload,
            loading: false
        }
        case 'GET_CITIES':
        return {
            ...state,
            cities: action.payload,
            loading: false
        }
        case 'GET_CATEGORIES':
        return {
            ...state,
            categories: action.payload,
            loading: false
        }
        case 'GET_CUISINES':
        return {
            ...state,
            cuisines: action.payload,
            loading: false
        }
        default:
        return state
    }
}