const initialState = {
    favorite: null,
    favorites: [],    
    loading: false
  }
  
  export default (state = initialState, action) => {
      switch(action.type) {
        case "FAVORITES_LOADING": {
          return{
          ...state,
          loading: true
          }
        } 
        case "REMOVE_LOADING": {
          return{
          ...state,
          loading: false
          }
        } 
        case "ADD_FAVORITE": {
          return {
            ...state,
            favorites: [action.payload, ...state.favorites],
            loading: false
          }
        } 
        case "REMOVE_FAVORITE": {
          return {
            ...state,
            favorites: state.favorites.filter(favorite => favorite._id !== action.payload),
            
          }
        }        
        case "GET_FAVORITES": {
          return {
            ...state,
            favorites: action.payload,
            loading: false
          }
        }
        default: {
          return state;
        }
      }
    }