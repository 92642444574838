import React from 'react'

export default function Pagination({onClick, itemsPerPage, activePage, itemsNumber}) {

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(itemsNumber / itemsPerPage); i++) {
      pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map(number => (
          <li
          className="page-item"
            key={number}            
            onClick={onClick}
          >
           <button className="page-link" id={number} style={{color: 'black'}}>{number}</button>
          </li>
        )
    )

  return (
    <div className="pagination justify-content-center">
      <ul className="pagination ">
        {renderPageNumbers}
      </ul>             
    </div>
  )
}


