const initialState = {
  message: {},
  messages: [],    
  loading: false
}

export default (state = initialState, action) => {
    switch(action.type) {
      case "MESSAGE_LOADING": {
        return{
        ...state,
        loading: true
        }
      }
      case "ADD_MESSAGE": {
        return {
          ...state,
          messages: [action.payload, ...state.messages]
        }
      }
      case "CLEAR_MESSAGES": {
        return {
          ...state,
          messages: [],
          loading: true
        }
      }
      case "GET_MESSAGES": {
        return {
          ...state,
          messages: action.payload,
          loading: false
        }
      }
      default: {
        return state;
      }
    }
  }